.performanceassessment{
  width: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  .ant-tabs{
    width: 100%;
    .ant-tabs-bar{
      width: 100%;
      margin: 0 !important;
      height: 36px;
      line-height: 36px;
      background: rgba(0, 0, 0, 0.5) !important;
      border: none;
      .ant-tabs-nav{
        height: 36px;
        line-height: 36px;
        >div{
          .ant-tabs-tab{
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: none;
            border:none;
            border-radius: 0;
            color: #fff;
            font-size: 12px;
            &:nth-child(2){
              border-left: 1px solid #999;
              // border-right:  1px solid #999;
              border-radius: 0;
            }
          }
          .ant-tabs-tab-active{
            border-radius: 0;
            background:#021a27; 
            color: rgb(99, 230, 223) !important;
          }
        }
      }
    }
    .ant-tabs-content{
      color: #fff;
      .list{
        padding: 2px ;
        margin: 0 10px;
        .ant-table-wrapper{
          height: 100%;
          .ant-table{
            border: none;
          }
          .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
              background: none;
              color: #fff !important;
              margin-top:8px;
              table{
                //选中
               .clickRowStyl{
                 background: #556994 !important;
               }
                .ant-table-row{
                  cursor: pointer;
                    border: none !important;
                    &:nth-child(2n){
                        background: rgba(255, 255, 255, 0.2);
                    }
                    td{
                      padding: 0 ;
                        border: none !important;
                        height: 26px !important;
                        text-align: center;
                        line-height: 26px !important;
                        font-size: 12px;
                        .ant-form-item{
                          padding: 0;
                          background: none !important;
                          height: 26px;
                          .ant-form-item-control-wrapper{
                            height: 26px;
                            .ant-form-item-control{
                              height: 26px;
                              .ant-form-item-children{
                                height: 26px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              }
                            }
                          }
                          input{
                            padding: 0 !important;
                            height: 26px;
                            background: none !important;
                            color: #fff;
                            margin:0 0 0 10px !important;
                            width: 90%;
                          }
                        }
                        .ant-input-number{
                          background: none;
                          height: 24px;
                          .ant-input-number-handler-wrap{
                            background: none;
                          }
                          .anticon{
                            color: #fff;
                          }
                        }
                        .editable-cell-value-wrap{
                          background: none !important;
                          height: 26px !important;
                        }
                    }
                }
              }
          }
          .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-header{
              background: none;
              height: 28px !important;
              overflow: hidden;
          }
          .ant-table-thead{
              >tr{
                height: 28px !important;
                  th{
                     background: rgba(0, 0, 0, 0.35) !important;
                      text-align: center;
                      padding: 0px !important;
                      border: 1px solid rgb(219, 212, 212) !important;
                      height: 30px !important;
                      color: #fff !important;  
                      font-size: 12px;
                  }
              }
          }
      }
      }
      .listpage{
        padding: 0 10px;
        width: 100%;
        .ant-pagination{
          position: absolute;
          display: flex;
          width: 100%;
          justify-content: center;
          bottom: 0px;
          right: 2px;
          .anticon{
            color: #fff;
          }
          >li{
            margin: 0 3px;
            height: 26px;
            border: 1px solid #fff !important;
          }
          .ant-pagination-item{
            background: none;
            line-height: 26px;
            a{
              color: #fff;
            }
          }
          .ant-pagination-item-link{
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-pagination-item-ellipsis{
              color: #Fff;
            }
          }
          .ant-pagination-item-active{
            background: #285486;
          }
        }
        .ant-table-small{
          border: none;
        }
        .ant-table-wrapper{
          height: 100%;
          .ant-spin-nested-loading{
            height: 100%;
            .ant-spin-container{
              height: 100%;
            }
          }
          .ant-table{
            .ant-table-body{
              background: none;
              padding: 0 !important;
              margin: 0 !important;
              .ant-table-thead{
                tr{
                  border:1px solid rgb(177, 174, 174) !important;
                  th{
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    padding: 0 !important;
                    color: #fff !important;
                    border-right: #ccc solid 1px;
                    border-bottom: none !important;
                    font-size: 12px;
                    background: rgba(0, 0, 0, 0.3) !important;
                  }
                }
              }
              .ant-table-tbody{   
                //选中
               .clickRowStyl{
                background: #556994 !important;
              }
                tr{
                  background: none;
                  &:nth-child(2n+1){
                    background: rgba(255,255,255,0.2);
                  }
                  &:hover{
                    background: #556994;
                    cursor: pointer;
                  } 
                  td{
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;
                    padding: 0px !important;
                    border-bottom: none !important;
                    color: #fff ;
                    background: none;
                  }
                }
              }
            }
          }
          .ant-table{
            .ant-table-content{
              .ant-table-scroll{
                .ant-table-body{
                  background: none;
                  padding: 0 !important;
                  margin: 0 !important;
                  .ant-table-thead{
                    tr{
                      border:1px solid rgb(177, 174, 174) !important;
                      th{
                        height: 26px;
                        line-height: 26px;
                        text-align: center;
                        padding: 0 !important;
                        color: #fff !important;
                        border-right: #ccc solid 1px;
                        border-bottom: none !important;
                        background: rgba(0, 0, 0, 0.3) !important;
                      }
                    }
                  }
                  .ant-table-tbody{   
                    //选中
                   .clickRowStyl{
                     background: #00b7ee !important;
                   }
                    tr{
                      background: none;
                      &:nth-child(2n+1){
                        background: rgba(255,255,255,0.2) ;
                      }
                      &:hover{
                        background: #00b7ee;
                        cursor: pointer;
                      } 
                      td{
                        font-size: 10px !important;
                        text-align: left;
                        padding:0 0 0 20px !important;
                        height: 26px;
                        line-height: 26px;
                        border-bottom: none !important;
                        color: #fff ;
                        background: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      //标题
      .examtitle{
        background: rgba(0, 0, 0, 0.3) !important;
        color: #fff;
        height: 34px;
        font-size: 12px;
        line-height: 34px;
        padding-left:16px;
        .action{
          float: right;
          button{
            height: 22px;
            background: #00b7ee;
            margin-right: 10px;
            font-size: 12px;
            color: #fff;
            border: none !important;
          }
        }
      }
      .examaction{
        height: 40px;
        display: flex;
        align-items: center;
        button{
          margin-left: 10px;
          height: 22px;
          background: none;
          >span{
            font-size: 10px !important;
          }
          color: #fff;
          &:hover{
            background: #00b7ee;
          }
          &:nth-child(1){
            background: #00b7ee;
          }
        }
      }
      //内容
      .examconfig{
        display: flex;
        .examconfigleft{
          width: 26%;
        }
        .examconfigcenter{
          width: 37%;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc; 
        }
        .examconfigright{
          width: 37%;
        }
      }
      .classmanage{
        display: flex;
        .ant-select{
          width: 100%;
          .anticon{
            color: #fff;
          }
          .ant-select-selection{
            width: 100%;
            height:26px !important;
            background:none !important;
            line-height: 26px;
          }
        }
        .classmanageleft{
          width: 40%;
          border-right: 1px solid #ccc;
        }
        .classmanageright{
          width: 60%;
          .classgroupdetail{
            padding: 0 15px;
            height: 40px;
            display: flex;
            align-items: center;
            >span{
              padding: 0 5px;
            }
            button{
              border: #00b7ee solid 1px;
              background: #00b7ee;
              margin-left: 10px;
              height: 28px;
              font-size: 12px;
              color: #fff;
            }
            .ant-calendar-picker {
              margin-left: 10px;
              height: 24px;
              .ant-calendar-picker-input{
                background: none ;
                color: #fff;
                height: 24px;
              }
              .ant-calendar-picker-clock-icon{
                color: #fff !important;
              }
              .anticon{
                color:#fff;
              }
            }

            .ant-select{
              width: 100%;
              height: 24px;
              line-height: 24px;
              .anticon{
                color: #fff;
              }
              .ant-select-selection{
                width: 100%;
                height: 24px;
                line-height: 24px;
                background: none;
                color: #fff;
                .ant-select-selection__rendered{
                  height: 24px;
                  line-height: 24px;
                  ul{
                    li{
                      margin: 0 !important;
                      height: 24px;
                      line-height: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//日历
.ant-calendar-date{
  &:hover{
    color: #000 !important;
  }
}
.ant-calendar-disabled-cell .ant-calendar-date{
  color: #000 !important;
}
