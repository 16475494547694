
    //智慧诊断
    .wisdomdiagnosiscedian{
        width: 100% !important;
        height: 100%;
        position: relative;
        background:url('../../assets/img/background2.jpg') no-repeat center !important;
        background-size: 100% 100% !important;
        .wisdomdiagnosiscediantotal{
            width: 30%;
            display: none;
            left: 70%;
            top: 10px;
            position: absolute;
            cursor: move;
            z-index:5000;
            h2{
              height: 34px;
              line-height: 34px;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              color: #fff;
              padding: 0 5px 0 20px; 
              background: #002b3b;
              // background: rgba(0, 0, 0,0.7);
              span{
                height: 34px;
                font-size: 16px;
                text-align: center;
                display: block;
                width: 40px;
                line-height: 34px;
                cursor: pointer;
              }
            }
            .calcucatedata{
              width: 100%;
              background: rgba(0, 0, 0, 0.3);
              h3{
                height: 40px;
                font-weight: 900;
                font-size: 14px;
                padding:0 20px 0 24px;
                color: #fff !important;
                display: flex;
                align-items: center;
                .ant-select{
                  width: 260px;
                  height: 32px;
                  .ant-select-selection{
                    color: #fff !important;
                    background: none;
                    .anticon{
                      color: #fff;
                    }
                  }
                }
              }
              >span{
                display: block;
                width: 100%;
                display: flex;
                justify-content: center;
                height: 40px;
                margin-top: 20px;
                button{
                  width: 80px;
                  height: 32px;
                  background-color: #00a0e9;
                  border-radius: 5px;
                  color: #fff;
                }
              }
            }
          }
        .ant-table-placeholder{
          border: none !important;
          color: #Fff;
        }
        .ant-table-small{
          border: none !important;
        }
        .treeshoufang{
          display: flex;
          position: absolute;
          top: 20px ;
          left: 20px;
          span{
            cursor: pointer;
            display: none;
            font-size: 14px;
            width:80px;
            text-align: center;
            line-height: 36px;
            height: 36px;
            background: rgba(0,0,0,0.5);
            border-radius: 5px;
            color: #fff;
            margin-right: 10px;
          }
        }
        .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before, 
        .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before{
          background: none;
        }
        .ant-tree.ant-tree-directory .ant-tree-child-tree > li{
          &:hover:after{
            color: #000 !important; 
            content: attr(labeltooltip) !important;
          }
          
        }
        .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
         .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected{
          border: none !important;
        }
        .loadprogress{
          display: none;
          position: absolute;
          left: 50%;
          top: 40%;
          width: 40%;
          z-index: 900;
          .ant-progress-text{
            color: #fff;
          }
        } 
        .wisdomdiagnosiscedianleft{
          padding: 0 20px 20px 20px;
          z-index: 2000;
          position: absolute;
          top: 0;
          left: 0;
          width: 15% !important;
          height: 100%;
          background: rgba(0,0,0,0.5);
          >h2{
            color:#fff;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 900;
            .zhuangpeishushousuo{
              cursor: pointer;
              width: 6px;
              height: 12px;
              display: block;
              background: url('../../assets/img/线箭头.png') no-repeat center;
              background-size: 100% 100%;
            }
          }
          .wisdomdiagnosislefttree{
            .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
              .ant-tree-title{
                color: #fff !important;
                // background: #00a0e9;
              }
            }
            .ant-tree-treenode-switcher-close{
              padding-left:5px !important;
              background: url('../../assets/image/个人工作台.png') no-repeat 10px center !important;
              background-size: 10px 10px !important;
            }
          }
        }
        .wisdomdiagnosiscediancenter{
          z-index: 2000;
          padding:0 20px;
          position: absolute;
          top: 0;
          left: 15%;
          width: 16% !important;
          height: 100%;
          background: rgba(0,0,0,0.3);
          >h2{
            color:#fff;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 900;
            .kksshousuo{
              cursor: pointer;
              margin-left: 10px;
              width: 6px;
              height: 12px;
              display: block;
              background: url('../../assets/img/线箭头.png') no-repeat center;
              background-size: 100% 100%;
            }
          }
          
          .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
            .ant-tree-title{
              color: #fff !important;
              // background: #00a0e9;
            }
          }
        }
   
        .datakanban{
          display: flex;
          position: absolute;
          justify-content: space-around;
          left: 31.5%;
          top: 0px;
          height: 100%;
          width: 68%;
          h2{
            height: 30px;
            display: flex;
            align-items: center;
            background: rgba(0, 0, 0, 0.5) !important;
            color: #fff;
            font-size: 12px;
            padding: 0 10px;
            justify-content: space-between;
            span{
                button{
                font-size: 12px;
                background: none;
                color: #ffff;
                height: 20px;
                width: 50px;
                padding: 0 !important;
                margin-right: 10px;
                &:hover{
                    background: #53b9ec;
                    border: 1px solid #53b9ec;
                }
                &:nth-child(1){
                    background: #53b9ec;
                    border: 1px solid #53b9ec;
                }
                &:last-child{
                  margin: 0 !important;
                }
                >span{
                    line-height: 18px;
                    display: block;
                    height: 20px;
                    width: 50px;
                }
                }
            }
          }
          .listleft{
            width:30%;
          }
          .listcenter{
            width: 35%;
          }
          .listright{
            width: 35%;
          }
        }
        .wisdomdiagnosiscon{
          position: relative;
          
          .ant-tabs-bottom-bar{
            width: 42% !important;
            position: absolute;
            bottom: 0px;
            margin: 0 !important;
            height: 80px;
            left: 31%;
            border: none !important;
            .ant-tabs-nav-container{
              height: 80px;
              .ant-tabs-nav-wrap{
                height: 80px;
                display: flex;
                justify-content: center;
                .ant-tabs-nav{
                  // width: 100%;
                  background: rgba(255,255,255,0.3);
                  box-shadow: 6px 4px 3px 0px 
                    rgba(0, 0, 0, 0.32);
                  border-radius: 5px 5px 0px 0px;
                  height: 80px;
                  >div{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    .ant-tabs-tab{
                      padding: 10px 20px !important;
                      margin: 0 !important;
                      img{
                        width: 50px !important;
                        height: 50px !important;
                      }
                    }
                  }
                  .ant-tabs-ink-bar{
                    display: none !important;
                  }
                }
              }
            }
            .wisdomdiagnosiscontent{
              display: flex;
              flex-direction: column;
              font-size: 12px;
              align-items: center;
              color: #555;
            }
            .ant-tabs-nav .ant-tabs-tab-active .wisdomdiagnosiscontent{
              // color: #6ea0e2;
              font-weight: 900;
            }
          }
          .ant-tabs-bottom-content{
            .Principledata{
              position: relative;
              margin-left: 31.5%;
              width: 68%;
              display: flex;
              flex-direction: column;
              align-items: center;
              color: #fff;
              background: #075578;
              .datacasetable{
                width: 80%;
                margin: 20px 0;
                border: #fff solid 1px;
                tr{
                  td{
                    border: #fff solid 1px;
                    width:20%;
                    text-align: center;
                    height: 30px !important;
                    line-height: 30px !important;
                    font-size: 12px;
                    color: #fff;
                  }
                }
              }
            }
            .moduleload{
              height: 100%;
              width: 100%;
              .DataKanban{
                display: none;
                position: absolute;
                z-index: 15;
                right:0.2%;
                top: 0;
                width: 42%;
                border: solid 1px #00a0e9;
                .DataKanbansearch{
                  font-size: 14px;
                  height: 40px;
                  background: rgba(0, 0, 0, 0.6);
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 15px;
                  button{
                    background: none;
                    color: #fff;
                    height: 30px;
                    margin-left: 10px;
                    &:hover{
                      background: #0075a9;
                    }
                  }
                  .ant-select-selection{
                    height: 28px;
                    background:none;
                  }
                  .ant-select-arrow{
                    color: #fff;
                  }
                  .ant-select-selection-selected-value{
                    color: #fff;
                  }
                  .ant-input{
                    background: rgba(255, 255, 255, 0.1);
                    color: #fff;
                    height: 28px;
                  }
                  .ant-input-search-icon{
                    color: #fff;
                  }
                  .ant-calendar-picker-icon{
                    color: #fff;
                  }
                }
                .list{
                  margin: 0 !important;
                  padding:2px;
                }
              }
              .equipmentevaluation{
                display: none;
                position: absolute;
                z-index: 15;
                right:0.2%;
                top: 0;
                width: 42%;
                border: solid 1px #00a0e9;
                .equipmentevaluationdata{
                  width: 100%;
                  height: 180px;
                  li{
                    color: #fff;
                    margin-bottom: 20px;
                    >h2{
                      line-height: 42px;
                      text-align: center;
                      background: rgba(0, 0, 0, 0.6);
                      height: 42px;
                      font-size: 14px;
                      font-weight: 900;
                      color: #fff;
                    }
                    >div{
                      padding: 20px 20px 40px 20px;
                      font-size: 12px;
                      background: rgba(0, 0, 0, 0.3);
                      span{
                        color: #03d8db;
                        font-size: 13px;
                        line-height: 28px;
                        margin-right: 10px;
                      }
                    }
                  }
                }
                .ant-carousel{
                  .slick-list{
                    .evaluationall{
                      h3{
                        height: 100% !important;
                        color: #fff;
                        >span{
                          display: block;
                          line-height: 40px;
                          text-align: center;
                          background: rgba(0, 0, 0, 0.6);
                          height: 40px;
                          font-size: 14px;
                          font-weight: 900;
                          color: #fff;
                        }
                        div{
                          p{
                            height: 100% !important;
                            overflow: auto;
                            padding: 20px 20px 40px 20px;
                            font-size: 12px;
                            background: rgba(0, 0, 0, 0.3);
                            span{
                              color: #03d8db;
                              font-size: 13px;
                              line-height: 28px;
                              margin-right: 10px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .slick-dots{
                    bottom: 24px !important;
                    li{
                      height: 10px;
                      button{
                        height: 10px;
                      }
                    }
                  }
                }
              }
            }
            .gongkuang{
              display: flex;
              position: absolute;
              justify-content: space-around;
              left: 31.5%;
              width: 68%;
              h2{
                height: 30px;
                display: flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.5) !important;
                color: #fff;
                font-size: 12px;
                padding: 0 10px;
                justify-content: space-between;
                span{
                    button{
                    font-size: 12px;
                    background: none;
                    color: #ffff;
                    height: 20px;
                    width: 50px;
                    padding: 0 !important;
                    margin-right: 10px;
                    &:hover{
                        background: #53b9ec;
                        border: 1px solid #53b9ec;
                    }
                    &:nth-child(1){
                        background: #53b9ec;
                        border: 1px solid #53b9ec;
                    }
                    &:last-child{
                      margin: 0 !important;
                    }
                    >span{
                        line-height: 18px;
                        display: block;
                        height: 20px;
                        width: 50px;
                    }
                    }
                }
              }
              .listleft{
                width: 40.5%;
              }
              .listright{
                width: 59%;
              }
            }
            .datakanban{
              display: flex;
              position: absolute;
              justify-content: space-around;
              left: 31.5%;
              width: 68%;
              h2{
                height: 30px;
                display: flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.5) !important;
                color: #fff;
                font-size: 12px;
                padding: 0 10px;
                justify-content: space-between;
                span{
                    button{
                    font-size: 12px;
                    background: none;
                    color: #ffff;
                    height: 20px;
                    width: 50px;
                    padding: 0 !important;
                    margin-right: 10px;
                    &:hover{
                        background: #53b9ec;
                        border: 1px solid #53b9ec;
                    }
                    &:nth-child(1){
                        background: #53b9ec;
                        border: 1px solid #53b9ec;
                    }
                    &:last-child{
                      margin: 0 !important;
                    }
                    >span{
                        line-height: 18px;
                        display: block;
                        height: 20px;
                        width: 50px;
                    }
                    }
                }
              }
              .listleft{
                width:30%;
              }
              .listcenter{
                width: 35%;
              }
              .listright{
                width: 35%;
              }
            }
          }
        }
      }