.usermanage{
    height: 100%;
    display: flex;
    flex-direction: column;
    .listsearch{
        height: 40px;
        background-color: #004986;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .listsearchl{
            .ant-input-search{
                height: 26px;
                width: 156px;
                .ant-input{
                    background: none;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    width: 156px;
                }
                .anticon {
                    color: #fff;
                }
            }
        }
        .listactionr{
            button{
                font-size: 12px;
                height: 26px;
                line-height: 24px;
                background-color: #1aa7f9 !important;
                border-radius: 5px;
                color: #fff;
                border: none;
            }
        }
    }
    .listpage{
      
        .ant-pagination {
          padding: 0 20px;
          position: absolute;
          height: 35px;
          bottom: 0px;
          margin: 0px !important;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: rgba(0,0,0,0.6);

          .ant-pagination-simple-pager{
            border: none !important;
          }
          .ant-pagination-item-active {
            background: none;
            a{
              color: #1890ff !important;
            }
          }

          .ant-pagination-total-text{
            margin-right: 10px !important;
          } 
          .ant-pagination-options{
            margin-left: 10px !important;
          }
          li {
            &:nth-child(1), &:last-child{
              border: none !important;
            }
            .ant-pagination-item-ellipsis {
              color: #Fff;
            }

            color: #FFF;

            a {
              color: #FFF;
            }
          }

          input {
            background: none;
            color: #Fff;
          }
        }
        margin: 10px 20px 20px 20px !important;
        padding: 0px !important;
        background: rgba(0, 0, 0, 0.1);
        .listoperationa{
            a{
              color: #60c8fd !important;
                margin-left: 5px;
            }
        }
        .ant-table-wrapper{
            height: 100%;
            .ant-spin-nested-loading{
              height: 100%;
              .ant-spin-container{
                height: 100%;
                .ant-table{
                  height: 100%;
                }
              }
            }
            .ant-table{
              border: none ;
                .ant-table-content{
                    .ant-table-body{
                        margin: 0px;
                        .ant-table-thead{
                          height: 30px;
                          tr{
                            border: 1px solid #ccc;
                              th{
                                padding: 0;
                                margin: 0;
                                  height: 30px;
                                  line-height: 30px;
                                  color: #fff !important;  
                                  font-size: 13px;
                                  text-align: center;
                                  font-weight: 900;
                                  border-left: 1px solid #ccc;
                                  background: rgba(0, 0, 0, 0.3) !important;
                              }
                          }
                        }
                        .ant-table-tbody{
                            background: none;
                            color: #fff !important;
                            margin-bottom: 0px !important;
                            table{
                              height: 100%;
                              .ant-table-tbody{
                                height: 100%;
                                .ant-table-row-expand-icon{
                                  background: none !important;
                                }
                                .clickRowStyl{
                                  background: #449bab !important;
                                } 
                                tr{
                                  &:hover{
                                    background: #449bab !important;
                                    cursor: pointer;
                                  }
                                }
                                .ant-table-row{
                                    border: none;
                                    background: none;
                                    &:nth-child(2n+1){
                                        background: rgba(255, 255, 255, 0.3);
                                    }
                                    td{
                                      padding: 0;
                                      margin: 0;
                                      height:28px;
                                      line-height: 28px;
                                      background: none;
                                        border: none !important;
                                        font-size: 12px;
                                        &:nth-child(1){
                                          text-align: left !important;
                                          padding-left: 20px;
                                        }
                                    }
                                }
                              }
                            }
                        }
                    }
                }
            }
          }
    }
}
// .ant-modal-mask{
//   background: none;
// }
// .ant-modal {
//   position: absolute !important;
//   padding: 0px !important;
//   top: 0px !important;
//   left: 0px !important;
//   bottom: 0px !important;
//   right: 0px !important;
//   margin: auto !important;

//   label{
//     padding-left: 12px;
//   }
//   .ant-form-item-required{
//     padding: 0px !important;
//   }
//   .ant-modal-content {
//     border-radius: 0px;
//     background: #203248; 
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     .modalactionbutton{
//       position: absolute;
//       top: 10px;
//       right: 15px;
//       height: 26px;
//       button{
//         height: 26px;
//         padding: 0 10px !important;
//         margin-left: 6px;
//         font-size: 12px;
//         color: #fff;
//         background: none;
//         &:hover, &:nth-child(1){
//           background: #1890ff;
//           color: #Fff;
//           border: 1px solid #1890ff;
//         }
//       }
//     }
//     .ant-modal-close {
//       right: 10px;
//       display: none;
//       .ant-modal-close-x {
//         height: 36px;
//         width: 36px;
//         line-height: 36px;
//       }
//     }


//     .ant-modal-header {
//       height: 44px;
//       padding: 0 20px;
//       border: none;
//       background:rgba(0, 0, 0, 0.8);
//       margin: 0px;

//       .ant-modal-title {
//         height: 100%;
//         color: #fff;
//         font-size: 14px;
//         line-height: 44px;
//         font-weight: bolder;
//       }
//     }

//     .ant-modal-body {
//       padding: 20px !important;
//       flex: 1;
//       .modalboxcon {
//         overflow-y: auto;
//         height: 100%;
//         overflow-x: hidden;
//         .modalboxcontitle {
//           display: flex;
//           height: 40px !important;
//           align-items: center;
//           font-size: 16px;
//           font-weight: 900;
//           margin: 0 0 10px 0 !important;
//           padding: 0px 0 0 20px !important;

//           .title {
//             padding-left: 10px;
//             height: 18px;
//             line-height: 18px;
//             border-left: 2px solid #0066ea;

//             .codenum {
//               color: #1aa7f9;
//               padding-left: 10px;
//             }
//           }
//         }
//         .ant-form-explain{
//           // display: none !important;
//         }
//         .formneccessary {
//           // background: url('../../../assets/require.png') no-repeat 8px 4px;
//         }
//         .ant-form-item-required::before{
//           float: left;
//         }
//         .usermanageform{
//           margin:  0 auto;
//           width: 630px;
//           height: 100%;
//         .avatarpic{
//           width: 134px;
//           height: 152px;
//             position: absolute;
//             right:50px !important;
//             top: 66px;
//             z-index: 1000;
//             .avatar-uploader {
//             height: 100%;
    
//             .ant-upload {
//                 padding: 0px !important;
//                 margin: 0px !important;
//                 height: 134px !important;
//                 width: 152px !important;
    
//                 .clickhereupload {
//                   width: 100% !important;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 align-items: center;
//                 }
//             }
//             }
//         }
//         .twokindcol{
//           .ant-col-24{
//             width: 420px !important;
//             .ant-row{
//               display: flex;
//               width: 100% !important;
//               .ant-form-item-label{
//                 width: 80px !important;
//                 label{
//                   color:  #fff;
//                 }
//               }
//               .ant-input{
//                 width: 320px !important;
//                 background: none;
//                 color: #fff;
//                 font-size: 12px !important;
//                 height: 24px;
//                 line-height: 24px;
//               }
//             }
//           }
//         }
//           >.ant-row{
//             height: 36px;
//             margin: 0px;
//             display: flex;
//             width: 630px;
//             .ant-col-36{
//               width: 210px;
//               &:nth-child(1){
//                 margin-right: 20px;
//               }
//               .ant-form-item{
//                 width: 240px;
//                 display: flex;
//                 .ant-form-item-label{
//                   width: 80px;
//                   label{
//                     color: #fff;
//                     font-size: 12px;
//                   }
//                 }
//                 .ant-form-item-control-wrapper{
//                   flex: 1;
//                   .ant-input{
//                     width: 140px;
//                     background: none;
//                     color: #fff;
//                     height: 24px;
//                     line-height: 24px;
//                     font-size: 12px;
//                   }
                  
//                   .ant-select{
//                     width: 140px;
//                     background: none;
//                     height: 24px;
//                     line-height: 24px;
//                     .ant-select-selection{
//                       height: 24px;
//                       line-height: 24px;
//                       width: 100%;
//                       background: none;
//                       color: #fff;
//                       .anticon{
//                         color: #fff;
//                       }
//                       .ant-select-selection__rendered{
//                         height: 24px;
//                         line-height: 20px;
//                       }
//                     }
//                   }
//                   .ant-input-number{
//                     width: 140px;
//                     height: 24px;
//                     line-height: 24px;
//                     background: none;
//                     color: #fff;
//                     .ant-input-number-handler-wrap {
//                       background: none;
//                       .anticon{
//                         color: #fff;
//                       }
//                     }
//                     .ant-input-number-input-wrap{
//                       height: 24px !important;
//                       .ant-input-number-input{
//                         height: 22px !important;
//                         line-height: 20px !important;
//                         font-size: 12px !important;
//                       }
//                     }
//                   }
//                   .ant-radio-group{
//                     display: flex;
//                     .ant-radio-wrapper{
//                       .ant-radio{
//                       }
//                       span{
//                         color: #fff;
//                         font-size: 12px;
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .ant-modal-footer {
//       height: 54px;
//       text-align: center;

//       button {
//         margin: 0 12px;
//         font-size: 12px;
//       }
//     }
//   }
// }
.usermanage{
    height: 100%;
    display: flex;
    flex-direction: column;
    .listsearch{
        height: 40px;
        background-color: #004986;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .listsearchl{
            .ant-input-search{
                height: 26px;
                width: 156px;
                .ant-input{
                    background: none;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    width: 156px;
                }
                .anticon {
                    color: #fff;
                }
            }
        }
        .listactionr{
            button{
                font-size: 12px;
                height: 26px;
                line-height: 24px;
                background-color: #1aa7f9 !important;
                border-radius: 5px;
                color: #fff;
                border: none;
            }
        }
    }
    .listscroll{
        margin: 10px 20px 20px 20px !important;
        padding: 0px !important;
        background: rgba(0, 0, 0, 0.1);
        .listoperationa{
            a{
                color: #fff;
                margin-left: 5px;
            }
        }
        .ant-table-wrapper{
            height: 100%;
            .ant-spin-nested-loading{
              height: 100%;
              .ant-spin-container{
                height: 100%;
                .ant-table{
                  height: 100%;
                }
              }
            }
            .ant-table{
              border: none ;
                .ant-table-content{
                    .ant-table-scroll{
                        .ant-table-header{
                            background:none;
                            overflow: hidden;
                            height: 30px;
                            margin: 0 !important;
                            .ant-table-thead{
                              height: 30px;
                              tr{
                                border: 1px solid #ccc;
                                  th{
                                    padding: 0;
                                    margin: 0;
                                      height: 30px;
                                      line-height: 30px;
                                      color: #fff !important;  
                                      font-size: 13px;
                                      text-align: center;
                                      font-weight: 900;
                                      border-left: 1px solid #ccc;
                                      background: rgba(0, 0, 0, 0.3) !important;
                                  }
                              }
                            }
                        }
                        .ant-table-body{
                            background: none;
                            color: #fff !important;
                            margin-bottom: 0px !important;
                            table{
                              height: 100%;
                              .ant-table-tbody{
                                height: 100%;
                                .ant-table-row-expand-icon{
                                  background: none !important;
                                }
                                .clickRowStyl{
                                  background: #449bab !important;
                                } 
                                tr{
                                  &:hover{
                                    background: #449bab !important;
                                    cursor: pointer;
                                  }
                                }
                                .ant-table-row{
                                    border: none;
                                    background: none;
                                    &:nth-child(2n+1){
                                        background: rgba(255, 255, 255, 0.3);
                                    }
                                    td{
                                      padding: 0;
                                      margin: 0;
                                      height:28px;
                                      line-height: 28px;
                                      background: none;
                                        border: none !important;
                                        font-size: 12px;
                                        &:nth-child(1){
                                          text-align: left !important;
                                          padding-left: 20px;
                                        }
                                    }
                                }
                              }
                            }
                        }
                    }
                }
            }
          }
    }
}
// .ant-modal-mask{
//   background: none;
// }
// .ant-modal {
//   position: absolute !important;
//   padding: 0px !important;
//   top: 0px !important;
//   left: 0px !important;
//   bottom: 0px !important;
//   right: 0px !important;
//   margin: auto !important;

//   .ant-modal-content {
//     border-radius: 0px;
//     background: #203248; 
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     .modalactionbutton{
//       position: absolute;
//       top: 10px;
//       right: 15px;
//       height: 26px;
//       button{
//         height: 26px;
//         padding: 0 10px !important;
//         margin-left: 6px;
//         font-size: 12px;
//         color: #fff;
//         background: none;
//         &:hover, &:nth-child(1){
//           background: #1890ff;
//           color: #Fff;
//           border: 1px solid #1890ff;
//         }
//       }
//     }
//     .ant-modal-close {
//       right: 10px;
//       display: none;
//       .ant-modal-close-x {
//         height: 36px;
//         width: 36px;
//         line-height: 36px;
//       }
//     }


//     .ant-modal-header {
//       height: 44px;
//       padding: 0 20px;
//       border: none;
//       background:rgba(0, 0, 0, 0.8);
//       margin: 0px;

//       .ant-modal-title {
//         height: 100%;
//         color: #fff;
//         font-size: 14px;
//         line-height: 44px;
//         font-weight: bolder;
//       }
//     }

//     .ant-modal-body {
//       padding: 20px !important;
//       flex: 1;
//       .modalboxcon {
//         overflow-y: auto;
//         height: 100%;
//         overflow-x: hidden;
//         .modalboxcontitle {
//           display: flex;
//           height: 40px !important;
//           align-items: center;
//           font-size: 16px;
//           font-weight: 900;
//           margin: 0 0 10px 0 !important;
//           padding: 0px 0 0 20px !important;

//           .title {
//             padding-left: 10px;
//             height: 18px;
//             line-height: 18px;
//             border-left: 2px solid #0066ea;

//             .codenum {
//               color: #1aa7f9;
//               padding-left: 10px;
//             }
//           }
//         }
//         .ant-form-explain{
//           // display: none !important;
//         }
//         .formneccessary {
//           // background: url('../../../assets/require.png') no-repeat 8px 4px;
//         }
//         .ant-form-item-required::before{
//           float: left;
//         }
//         .datadictionaryform{
//           margin:  0 auto;
//           width: 490px;
//           height: 100%;
//           >.ant-row{
//             height: 50px;
//             margin: 0px;
//             display: flex;
//             width: 490px;
//             .ant-col-24{
//               width: 240px;
//               &:nth-child(1){
//                 margin-right: 10px;
//               }
//               .ant-form-item{
//                 width: 240px;
//                 display: flex;
//                 .ant-form-item-label{
//                   width: 80px;
//                   label{
//                     color: #fff;
//                     font-size: 12px;
//                   }
//                 }
//                 .ant-form-item-control-wrapper{
//                   flex: 1;
//                   .ant-input{
//                     width: 140px;
//                     background: none;
//                     color: #fff;
//                     height: 24px;
//                     line-height: 24px;
//                     font-size: 12px;
//                   }
//                   .ant-radio-group{
//                     display: flex;
//                     .ant-radio-wrapper{
//                       .ant-radio{
//                       }
//                       span{
//                         color: #fff;
//                         font-size: 12px;
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .ant-modal-footer {
//       height: 54px;
//       text-align: center;

//       button {
//         margin: 0 12px;
//         font-size: 12px;
//       }
//     }
//   }
// }

.DrawerUser{
  .ant-drawer-content-wrapper{
    width: 500px !important;
    
          //上传文件
          .avatarpic{
            position: absolute;
            right:20px;
            z-index: 10;
            width: 180px;
            height: 180px;
            top:25px;
            .avatar-uploader , .ant-upload{
              background: none;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .clickhereupload{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #fff;
              }
            }
          }
    .ant-form{
      height: 100%;
      color: #fff;
      .ant-row{
       height:36px;
       display: flex;
        .ant-col-36{
          width: 100%;
          .ant-form-item{
            display: flex;
            font-size: 14px;
            margin: 0 !important;
            width: 100%;
            .ant-form-item-label{
              width: 80px;
              label{
                color: #fff;
                font-size: 12px;
              }
            }
            .ant-form-item-control-wrapper{
              
              .ant-form-item-control{
                width: 100%;
                .ant-form-item-children{
                  width: 100% !important;
                  display: block;
                  .ant-input-number{
                    width: 100% !important; 
                    background: none;
                    color: #fff;
                    .ant-input-number-handler-wrap{
                      background: none;
                    }
                  }
                  //时间选择
                  .ant-time-picker {
                    width: 140px;
                    height: 26px;
                    .ant-time-picker-input{
                      padding: 0 4px;
                      height: 26px;
                      line-height: 26px;
                      background: none ;
                      color: #fff;
                    }
                    .ant-time-picker-clock-icon{
                      color: #fff !important;
                    }
                  }
                  .ant-calendar-picker{
                    width: 140px;
                    height: 26px;
                    line-height: 26px;
                    >div{
                      width: 100% !important;
                    }
                    .ant-calendar-picker-icon{
                      color: #fff;
                    }
                  }
                  .ant-select{
                    width: 140px;
                    height: 26px;
                    line-height: 26px;
                    .anticon{
                      color: #fff;
                    }
                    .ant-select-search__field{
                      width: 0;
                      height: 0;
                    }
                    .ant-select-selection--multiple{
                      width: 100%;
                      min-height:26px !important;
                      line-height: 26px;
                    }
                    .ant-select-selection--multiple .ant-select-selection__choice{
                      color: #fff;
                      background:none !important;
                      border:none;
                    }
                    .ant-select-selection{
                      width: 100%;
                      height: 26px;
                      line-height: 26px;
                      background: none;
                      color: #fff;
                      .ant-select-selection__rendered{
                        height: 26px;
                        line-height: 26px;
                        margin-left: 4px;
                      }
                    }
                  }
                  input {
                    height: 26px;
                    line-height: 26px;
                    width: 140px;
                    background: none;
                    font-size: 12px;
                    padding: 0 4px;
                    color: #fff !important;
                  }
                  textarea{
                    padding: 0 4px;
                    line-height: 26px;
                    width: 100%;
                    margin: 5px 0;
                    background: none;
                    color: #fff !important;
                  }
                  .ant-radio-group{
                    .ant-radio-wrapper{
                      color: #fff;
                      >span{
                        &:nth-child(2){
                          padding: 0 3px;
                        }
                      }
                    }
                  }
                  button{
                    background: #53b9ec;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .ant-col-24{
          .ant-form-item{
            .ant-form-item-label{
              width: 80px;
              label{
                color: #fff;
              }
            }
            .ant-form-item-control-wrapper{
              textarea{
                background: none !important;
                color: #fff;
                width: 380px;
                font-size: 12px;
              }
              .ant-input{
                background: none !important;
                color: #fff;
                font-size: 12px;
                height: 26px;
                line-height: 26px;
                width: 382px;
              }
              .ant-input-disabled{
                background: #517492 !important;
              }
            }
          }
        }
      }
    }
  }
}