.configmanagement1{
  height: 100%;
  display: flex;
  .h2title{
    width: 100%;
    height: 34px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 34px;
    padding-left: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .listsearch{
      width: 60%;
      height: 40px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      .listsearchr{
        button{
          height: 28px;
          font-size: 12px;
          margin-left: 10px;
          color: #fff;
          background: none;
          border: #fff solid 1px;
          &:hover{
            background: #00b7ee;
            border: #00b7ee solid 1px;
          }
          &:nth-child(1){
            background: #00b7ee;
            border: #00b7ee solid 1px;
          }
        }
      }
      .listsearchl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        .listsearchls{
          display: flex;
          width: 100%;
          align-items: center;
          .listsearchcondition{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 5px;
          }
          .searchcondition{
            display: block;
            width: 100%;
          }
        }
        .anticon{
          color: #fff;
        }
        .ant-calendar-picker-input{
          background: none;
          width: 100%;
        }
        .ant-input-search{
          width: 100%;
          height: 28px;
          .ant-input{
            height: 28px;
            line-height: 28px;
            background: none;
            color: #fff !important;
          }
        }
        .ant-select{
          width: 100%;
          background: none;
          height: 28px;
          line-height: 28px;
          .ant-select-selection{
            height: 28px;
            line-height: 28px;
            width: 100%;
            background: none;
            color: #fff;
            .ant-select-selection__rendered{
              min-height: 28px;
              ul{
                li{
                  margin: 0 !important;
                  height: 25px;
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }
  }

  .paralist{
    width: 59.8%;
    border-right: 1px solid #fff;
  }
  .paradetail{
    width: 40%;
  }
}