.configmanagement{
  height: 100%;
  background: linear-gradient(to bottom, rgb(65, 112, 170) 0%,rgb(161, 173, 184) 100%) !important;
  .ant-tabs{
    .ant-tabs-bar{
      width: 100%;
      margin: 0 !important;
      height: 36px;
      line-height: 36px;
      background: rgba(0, 0, 0, 0.5) !important;
      border: none;
      .ant-tabs-nav{
        height: 36px;
        line-height: 36px;
        >div{
          .ant-tabs-tab{
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: none;
            margin: 0 !important;
            border:none;
            border-radius: 0;
            color: #fff;
            font-size: 12px;
            &:nth-child(2),&:nth-child(4){
              border-left: 1px solid #999;
              border-right:  1px solid #999;
              border-radius: 0;
            }
          }
          .ant-tabs-tab-active{
            border-radius: 0;
            background:#021a27; 
            color: rgb(99, 230, 223) !important;
          }
        }
      }
    }
    .ant-tabs-content{
      
      .project{
        .projecttree{
          padding: 10px 40%;
          overflow: auto;
          ._1bN5VNfsvzZZJH0jR4Rqg9{
            height: 100%;
            overflow: auto;
            //标题
            >div{
              ._3JqwPIKwM0hNDz6o5_RWbI{
                background: rgba(0, 0, 0, 0.3) !important;
                height: 34px !important;
                .ChiPpnWH94RQa9VngHrWM{
                  color: #fff;
                  line-height: 34px !important;
                }
              }
            }
            //内容
            ._1pNDgKGxjyeTtZSt9n0ZAV{
              height: 100%;
              padding: 0 0 0 10px !important;
              height: 26px;
              line-height: 26px;
              background: rgba(255,255,255,0.2);
              .UbpLT6or4D8IiVzdN8tgR{
                color: #fff;
              }
              &:hover{
                background: #556994;
                cursor: pointer;
              } 
            }
          }
        }
      }
      .h2title{
        width: 100%;
        height: 40px;
        background: rgba(0, 0, 0, 0.3);
        font-size: 12px;
        line-height: 40px;
        padding-left: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .action{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button{
            height: 28px;
            font-size: 12px;
            margin-left: 10px;
            color: #fff;
            background: none;
            border: #fff solid 1px;
            &:hover{
              background: #00b7ee;
              border: #00b7ee solid 1px;
            }
            &:nth-child(1){
              background: #00b7ee;
              border: #00b7ee solid 1px;
            }
          }
  
        }
      }
      .listsearch{
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        .listsearchr{
          button{
            height: 28px;
            font-size: 12px;
            margin-left: 10px;
            color: #fff;
            background: none;
            border: #fff solid 1px;
            &:hover{
              background: #00b7ee;
              border: #00b7ee solid 1px;
            }
            &:nth-child(1){
              background: #00b7ee;
              border: #00b7ee solid 1px;
            }
          }
        }
        .listsearchl {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .listsearchls{
            display: flex;
            width: 60%;
            align-items: center;
            .listsearchcondition{
              display: block;
              width: 100px;
              text-align: center;
              margin: 0 5px;
            }
            .searchcondition{
              display: block;
              width: 80%;
            }
          }
          .anticon{
            color: #fff;
          }
          .ant-calendar-picker-input{
            background: none;
            width: 100%;
          }
          .ant-input-search{
            width: 100%;
            height: 28px;
            .ant-input{
              height: 28px;
              line-height: 28px;
              background: none;
              color: #fff !important;
            }
          }
          .ant-select{
            width: 100%;
            background: none;
            height: 28px;
            line-height: 28px;
            .ant-select-selection{
              height: 28px;
              line-height: 28px;
              width: 100%;
              background: none;
              color: #fff;
              .ant-select-selection__rendered{
                min-height: 28px;
                ul{
                  li{
                    margin: 0 !important;
                    height: 25px;
                    line-height: 28px;
                  }
                }
              }
            }
          }
        }
      }
      .area{
        .areatree{
          padding: 10px 40%;
          overflow: auto;
        }
      }
      //测点列表
      .cedianlist{
        display: flex;
        .cediantree{
          width: 17%;
          border-right: 1px solid #fff;
          .cediantreedata{
            padding: 10px;
            overflow: auto;
          }
        }
        .cediandetail{
          width: 83%;
        }
      }
      //设备清单
      .equipmentlist{
        display: flex;
        .equipmenttree{
          width: 17%;
          border-right: 1px solid #fff;
          .equipmenttreedata{
            padding: 10px;
            overflow: auto;
          }
        }
        .equipmentdetail{
          width: 83%;
        }
      }
      //工厂信息
      .factoryinfo{
        padding: 10px 0 10px 40px;
        overflow: auto;
        h1,h2,h3,h4,h5,h6{
          color: #fff;
        }
        h1{
          text-align: center;
          font-size: 16px !important;
        }
        h2{
          font-size: 14px !important;
        }
        h3 ,h4{
          font-size: 12px !important;
          line-height: 24px;
          text-indent: 2em;
        }
      }
    }
  }
}
//重新分配组
.cediantreeseledata{
  position: absolute;
  left: 35%;
  top: 20%;
  width: 500px;
  height:400px;
  overflow: auto;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1001;
  >h2{
    background: rgba(0, 0, 0, 0.9);
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding-left:10px;
    cursor: move;
  }
  .ant-tree-directory{
    margin: 10px;
    height: 280px;
    overflow: auto;
    padding-left: 20px;
  }
  .action{
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      height: 28px;
      font-size: 12px;
      margin-left: 10px;
      color: #fff;
      background: none;
      border: #fff solid 1px;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
    }
  }
}
.configmanage{
  position: relative;
  padding: 10px 40px;
  overflow: auto;
  .imageupload {
      position: absolute;
      right: 40px;
      top: 80px;
      .avatar-uploader{
        display: block;
          width: 100% !important;
          height: 100% !important;
          .ant-upload, .ant-upload-select-picture-card{
              margin: 0;
              padding: 0;
              width: 100% !important;
              height: 100% !important;
              background: none;
              color: #fff;
              img{
                  max-width: 400px;
                  max-height: 300px;
                  width: auto;
                  height: auto;
              }
          }
      }
  }
  h1,h2,h3,h4,h5,h6{
      color: #fff;
      width: 100%;
      font-family: serif;
  }
  h1{
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
  }
  h2{
      font-size: 16px;
      height: 36px;
      line-height: 36px;
  }
  h3{
      min-height: 30px;
      line-height: 30px;
      font-size: 14px;
      text-indent: 2rem;
  }
  h4{
      font-size: 14px;
      min-height: 30px;
      line-height: 30px;
      text-indent: 4rem;
  }
}

//专业树
.projecttree{
  height: 100%;

  .projecttreetitle{
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    padding:0 20px 0 30px;
    display: flex;
    justify-content: space-between;
  }
}
.configmanagementlist{
  margin: 0 10px;
  .ant-table-wrapper{
    height: 100%;
    .ant-spin-nested-loading{
      height: 100%;
      .ant-spin-container{
        height: 100%;
        .ant-table{
          height: 100%;
        }
      }
    }
    .ant-table{
      border: none ;
        .ant-table-content{
            .ant-table-scroll{
                .ant-table-header{
                    background:none;
                    overflow: hidden;
                    height: 30px;
                    margin: 0 !important;
                    .ant-table-thead{
                      height: 30px;
                      tr{
                        border: 1px solid #ccc;
                          th{
                            padding: 0;
                            margin: 0;
                              height: 30px;
                              line-height: 30px;
                              color: #fff !important;  
                              font-size: 13px;
                              text-align: center;
                              font-weight: 900;
                              border-left: 1px solid #ccc;
                              background: rgba(0, 0, 0, 0.3) !important;
                          }
                      }
                    }
                }
                .ant-table-body{
                    background: none;
                    color: #fff !important;
                    margin-bottom: 0px !important;
                    table{
                      height: 100%;
                      .ant-table-tbody{
                        height: 100%;
                        .ant-table-row-expand-icon{
                          background: none !important;
                        }
                        .clickRowStyl{
                          background: #449bab !important;
                        } 
                        tr{
                          &:hover{
                            background: #449bab !important;
                            cursor: pointer;
                          }
                        }
                        .ant-table-row{
                            border: none;
                            background: none;
                            &:nth-child(2n+1){
                                background: rgba(255, 255, 255, 0.3);
                            }
                            td{
                              padding: 0;
                              margin: 0;
                              height:28px;
                              line-height: 28px;
                              background: none;
                                border: none !important;
                                font-size: 12px;
                                &:nth-child(1){
                                  text-align: left !important;
                                  padding-left: 20px;
                                }
                            }
                        }
                      }
                    }
                }
            }
        }
    }
  }
}
.actionbutton{
  button{
    height: 24px;
    line-height: 20px;
    font-size: 12px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    margin-left: 10px;
    &:hover{
      background: #00b7ee;
      color: #fff;
      border: 1px solid #00b7ee;
    }
  }
}
//测点配置
.cedianconfig{
  width: 100%;
  .cediancontent{
    .cedianlist{
      display: flex;
      height: 100%;
      .cediantree{
        height: 100%;
        width: 260px;
        border-right: 1px solid #ccc;
        .cediantreedata{
          overflow: auto;
          padding: 10px;
        }
      }
      .cediandetail{
        flex: 1;
        .listsearch{
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .listsearchl{
            height: 40px;
            .listsearchls{
              height: 100%;
              display: flex;
              align-items: center;
              span{
                font-size: 14px;
                color: #fff;
              }
              .ant-input-search {
                margin-left: 10px;
              }
              .ant-input{
                background: none;
                height: 28px;
                line-height: 28px;
                color: #fff;
                padding:0 10px !important;
              }
              .anticon {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}