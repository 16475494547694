//必填符号
input::-webkit-input-placeholder {
  /* placeholder颜色 */
  color: #aab2bd;
  /* placeholder字体大小 */
  font-size: 12px;
}
.ant-input , input{
  font-size: 12px !important;
}

.site-tree-search-value{
  color: #f00 !important;
}
label{
  padding-left: 12px !important;
}
.ant-form-item-required{
  padding-left: 0px !important;
}
.ant-form-item-required::before{
  float: left !important;
  line-height: 1.8 !important;
}

.react-resizable {
  position: relative !important;
  background-clip: padding-box !important;
}

.react-resizable .react-resizable-handle {
  position: absolute !important;
  width: 10px !important;
  height: 100% !important;
  bottom: 0 !important;
  right: -5px !important;
  cursor: col-resize !important;
  z-index: 1 !important;
  opacity: 0;
}

.ant-modal-confirm-confirm{
	max-width: 300px !important;
  width: 300px !important;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  margin: auto !important;
  height: 142px !important;
  .ant-modal-content{
    background: #2f5d91;
    .ant-modal-confirm-title{
      color: #fff;
    }
    .ant-modal-confirm-btns{
      button{
        &:nth-child(1){
          background: none !important;
          color:  #ffffff;
        }
      }
    }
  }
}
//加载loading
.ant-spin-spinning{
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index:10000 ;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background: rgba(0, 0, 0, 0.5);
}
// @import  '../../lib/reset.scss'
  /* 设置滚动条的样式 */
::-webkit-scrollbar {
  width:8px;
  height: 8px;
  background-color: rgba(0,0,0,0.5);
}
.ant-dropdown .menu{
  background: rgba(0, 0, 0, 0.5);
  padding: 0 2px;
}
.ant-dropdown .menu .ant-dropdown-menu-item{
  border-bottom: 1px solid #ccc;
}
.ant-dropdown .menu .ant-dropdown-menu-item-active{
  background: #00a0e9 !important
}
.ant-dropdown .menu .ant-dropdown-menu-item span{
  font-size: 14px !important;
  color: #fff;
}
.ant-dropdown .menu .ant-dropdown-menu-item:last-child{
  border: none;
}
*{
  margin: 0;
  padding: 0;
}
img[src=""],img:not([src]){
  opacity:0;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius:10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius:2px;
  background-size: 9px 10px !important;
  font-size: 12px;
  width: 9px;
  height: 20px !important;
  background:#4170a1;
}

input::-webkit-input-placeholder { /* WebKit browsers */
  color: #ccc !important;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #ccc !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #ccc !important;
}

.changepwd{
  max-width: 380px !important;
  height: 340px !important;
  left: 0% !important;
  top: 0% !important;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  .ant-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  section{
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    span{
      display: block;
      width: 120px;
    }
    input{
      width: 150px !important;
    }
  }
}

html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

//内容区
.smartproshou{
  width: 100% !important;
  height: 100%;
  overflow: hidden;
  position: relative;
  //头部
  .home1top{
    background:#002b3b;
    padding: 0 16px;
    align-items: center;
    color: #fff;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .home1top-left{
      height: 100%;
      display: flex;
      height: 50px;
      align-items: center;
      .homepage{
        display: block;
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        cursor: pointer;
        // border-right: 2px  solid #c7c4c4;
        img{
          margin-left: 5px;
        }
      }
      .fivemodules{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        padding: 0 5px;
        // border-right: 2px  solid #c7c4c4;
        >span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 18px;
          width: 44px;
          img{
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
      .closealltabs{
        height: 34px;
        display: block;
        width: 30px;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        background:#203b5a !important;
        img {
          width: 12px;
          height: 14px;
        }
      }
      .apptabs{
        height: 34px;
        margin-left: 5px;
        .ant-tabs-bar{
          margin: 0;
          height: 100%;
          border: none;
          padding:0;
          height: 100%;
          .ant-tabs-extra-content{
            display: none;
          }
          .ant-tabs-nav-container{
            // padding: 0 20px;
            height: 100%;
          }
          .ant-tabs-tab-prev,.ant-tabs-tab-next{
            height:34px;
            // width: 20px;
            background:#203b5a !important;
            color: #fff;
            .ant-tabs-tab-prev-icon,.ant-tabs-tab-next-icon{
              top: 52%;
            }
            .anticon{
              font-size: 16px;
            }
          }
          .ant-tabs-nav-wrap{
            .ant-tabs-nav-scroll{
              .ant-tabs-tab-active{
                background:#00a0e9 !important;
              }
              .ant-tabs-tab{
                padding: 0px;
                margin: 0 4px;
                // min-width: 130px;
                width: auto;
                padding: 0 14px 0 4px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background-color:#2b5179 ;
                border-radius: 2px;
                border: none;
                color: #fff;
                >div{
                  padding-left: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background: url('../../assets/homepagetu/圆.png') no-repeat 5px center;
                }
                .anticon-close{
                  color: #fff;
                  margin-top: 2px;
                }
                &:hover{
                  background:#00a0e9 !important;
                }
              }
            }
          }
        }
      }
    
      // .closeframe{
      //   float: left;
      //   width: 130px;
      //   cursor: pointer;
      //   display: flex;
      //   height: 34px;
      //   align-items: center;
      //   background-color:#2b5179 ;
      //   border-radius: 2px;
      //   justify-content: space-around;
      //   &:hover{
      //     background:#00a0e9 !important;
      //   }
      //   >span{
      //     &:nth-child(1){
      //       display: block;
      //       width: 8px;
      //       height: 8px;
      //       background: #fff;
      //       border-radius: 50%;
      //     }
      //     &:nth-child(2){
      //       display: block;
      //       text-align: left;
      //       height: 14px;
      //       padding: 0 5px;
      //       font-family: MicrosoftYaHei;
      //       font-size: 12px;
      //       font-weight: normal;
      //       font-stretch: normal;
      //       letter-spacing: 0px;
      //       color: #ffffff;
      //     }
      //     &:nth-child(3){
      //       display: block;
      //       width: 12px;
      //       height: 12px;
      //       font-size: 16px;
      //       line-height: 8px;
      //       cursor: pointer;
      //     }
      //   }
      // }

    }
    .home1top-right {
      height: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    .anticon-arrows-alt{
      margin: 4px 10px 0 0 ;
      width: 16px;
      height: 16px;
    }
    .anticon-shrink{
      margin: 4px 10px 0 0 ;
      width: 16px;
      height: 16px;
    }
    .useravatar{
        display: flex; 
        align-items: center;
        .avatartouxiang{
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #fff;
        color: #fff;
        margin: 0 10px;
        }
        img{
        display: block; 
        width: 36px;
        height: 36px;
        border-radius: 50%;
        }
    }
    }
  }
  //首页
  .viewshou{
    background: url('../../assets/img/homebg.png') no-repeat ;
    background-size: 100% 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    h2{
      font-family: MicrosoftYaHei;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #51effe;
      // margin-bottom: 4px;
    }
    .montheledata{
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #51effe;
    }
    .viewshowleft{
      height: 100%;
      display: flex;
      flex-direction: column;
      .monthelecount{
        margin-bottom: 5px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        >div{
          &:nth-child(1){
            h2{
              margin-top:3px;
            }
          }
        }
        .monthelecompare{
          display: flex;
          align-items: center;
          >span{
            display: block;
            &:nth-child(1){
              font-family: MicrosoftYaHei;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #e8e8e8;
              margin-right: 4px;
            }
            &:nth-child(2){
              width: 60px;
              height: 22px;
              background: rgba(60, 236, 252, 0.3);
              border-radius: 11px;
              text-align: center;
              line-height: 20px;
              >span{
                font-family: MicrosoftYaHei;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #e8e8e8;
              }
            }
          }
        }
        .viewshowseletwo{
          min-width: 160px;
        }
        .viewshowsele ,.viewshowseletwo{
          height: 28px;
          .ant-select{
            height: 22px;
            line-height: 22px;
            .anticon{
              color: #fff;
            }
            .ant-select-selection{
              height: 22px;
              width: 100%;
              line-height: 22px;
              background: none;
              color: #fff;
              .ant-select-selection__rendered{
                height: 22px;
                line-height: 20px;
                margin: 0 5px !important;
                font-size: 12px;
                .ant-select-selection-selected-value{
                  padding-right:16px; 
                }
              }
              .ant-select-arrow{
                right: 5px;
              }
            }
          }
        }
      }
      .viewshowleft1{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 12px;
        margin-bottom: 10px;
        // height: 14.5%;
        background: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        .viewshowleft1condata{
          font-family: MicrosoftYaHei-Bold;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #51effe;
        }
        .viewshowleft1contitle{
          font-family: MicrosoftYaHei-Bold;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #e8e8e8;
          margin-top: 2px;
        }
        .viewshowleft1con{
          display: flex;
          padding: 0 5%;
          justify-content: space-between;
          .viewshowleft1con1 , .viewshowleft1con2 , .viewshowleft1con3{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
      .viewshowleft2{
        width: 100%;
        margin-bottom: 10px;
        padding: 5px 10px;
        // height: 21.4%;
        background: rgba(0, 0, 0, 0.3);
      }
      .viewshowleft3{
        width: 100%;
        margin-bottom: 10px;
        // height: 21.4%;
        padding: 5px 10px;
        background: rgba(0, 0, 0, 0.5);
      }
      .viewshowleft4{
        width: 100%;
        margin-bottom: 10px;
        // height: 21.4%;
        padding: 5px 10px;
        background: rgba(0, 0, 0, 0.5);
      }
      .viewshowleft5{
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        height: 16.5%;
        padding: 5px 10px;
        display: flex;
        justify-content: space-around;
        .ant-progress{
          width: 35%;
          height: 48%;
          margin-right: 15px;
          .ant-progress-inner{
            width: 100% !important;
            height: 100% !important;
            .ant-progress-circle {
              width: 100% !important;
              height: 100% !important;
            }
          }
          .ant-progress-text{
            font-size: 12px;
            display: none;
            color: #fff;
          }
        }
        .viewshowleft5con{
          width: 50%;
          display: flex;
          flex-direction: column;
          color: #fff;
          // align-items: center;
          font-size: 12px;
          >span{
            display: block;
            margin-top: 4px;
          }
        }
        .viewshowleft51{
          width: 50% !important;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .viewshowleft52{
          width: 50% !important;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .viewshowright{
      height: 100%;
      .viewshowright1{
        // height: 27%;
        margin-bottom: 10px;
        background: rgba(0, 0, 0, 0.5);
        h2{
          display: flex;
          padding: 0 2%;
          justify-content: space-between;
          height: 22px;
          align-items: center;
          background: #21364a;
          >span{
            display: block;
            &:nth-child(2){
              height: 100%;
              line-height: 28px;
              width: 160px;
              text-align: center;
              background: #2f4e70;
            }
          }
        }
      }
      .viewshowright2{
        // height: 26%;
        margin-bottom: 10px;
        background: rgba(0, 0, 0, 0.5);
        h2{
          display: flex;
          padding: 0 2%;
          justify-content: space-between;
          height: 22px;
          align-items: center;
          background: #21364a;
          >span{
            display: block;
          }
        }
        .viewshowright2tabs{
          display: flex;
          color: #fff;
          font-size: 12px;
          justify-content: space-around;
          height: 40px;
          .viewshowright2tab{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: center;
            >span{
              &:nth-child(1){
                font-family: MicrosoftYaHei;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #3cecfc;
              }
              &:nth-child(2){
                font-size: 12px;
                margin-top: 4px;
              }
            }
          }
        }
        .viewshowright2con{
          padding: 0 2%;
        }
      }
      .viewshowright3{
        // height: 18%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .viewshowright3con{
          padding: 5px 0 0 8px;
          width: 49%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          // align-items: center;
          color: #fff;
          // justify-content: center;
          .progressname{
            height: 18%;
            h3{
              font-family: MicrosoftYaHei;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #51effe !important;
              // margin-bottom: 4px;
            }
          }
          .progresscon{
            height:82%;
            display: flex;
            align-items: center;
            flex-direction: column;
            .ant-progress{
              margin-bottom: 5px;
              width: 100%;
              height: 68%;
              .ant-progress-inner{
                width: 100% !important;
                height: 100% !important;
                .ant-progress-circle {
                  width: 100% !important;
                  height: 100% !important;
                }
              }
              .ant-progress-text{
                font-size: 12px;
                display: none;
                color: rgb(97, 149, 199);
              }
            }
          }
          // >span{
          //   font-family: MicrosoftYaHei;
          //   font-size: 12px;
          //   font-weight: normal;
          //   font-stretch: normal;
          //   letter-spacing: 0px;
          //   color: #3cecfc;
          //   margin-top: 8px;
          // }
          &:nth-child(1){
            .ant-progress{
              background: url('../../assets/homepagetu/7fu.png') no-repeat center center;
              background-size: 12% 40%;
            }
          }
          &:nth-child(2){
            .ant-progress{
              background: url('../../assets/homepagetu/6fu.png') no-repeat center center;
              background-size: 12% 40%;
            }
          }
        }
      }
      .viewshowright4{
        // height: 26.5%;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        h2{
          display: flex;
          padding: 0 2%;
          justify-content: space-between;
          height: 22px;
          align-items: center;
          background: #21364a;
          >span{
            display: block;
          }
        }
        .swiperdata{
          .ant-carousel{
            height: 100%;
            .slick-slider{
              height: 100%;
              .slick-list{
                height:100% !important;
                .slick-track{
                  height: 100% !important;
                  .slick-slide{
                    >div{
                      padding:10px;
                      >div{
                        h3{
                          padding: 1% 0 1% 10%;
                          display: flex;
                          flex-direction: column;
                          background-size: 8% 80%;
                          font-family: MicrosoftYaHei;
                          font-weight: normal;
                          font-stretch: normal;
                          letter-spacing: 0px;
                          color: #fff;
                        }
                        .jinggao{
                          background: url('../../assets/homepagetu/8.png') no-repeat left center;
                          background-size: 28px 28px;
                        }
                        .finished{
                          background: url('../../assets/homepagetu/9.png') no-repeat left center;
                          background-size: 28px 28px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .slick-dots{
              display: none !important;
            }
          }
        }
        ul{
          width: 100%;
          height: 86%;
          color: #fff;
          padding-left: 2%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .jinggao{
            background: url('../../assets/homepagetu/8.png') no-repeat left center;
            background-size: 28px 28px;
          }
          .finished{
            background: url('../../assets/homepagetu/9.png') no-repeat left center;
            background-size: 28px 28px;
          }
          li{
            padding: 1% 0 1% 10%;
            display: flex;
            flex-direction: column;
            background-size: 8% 80%;
            font-family: MicrosoftYaHei;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #fff;
            >span{
              &:nth-child(1){
                color: #3adeed;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
  //五个模块
  .home1{
    display: none;
    background:url('../../assets/img/background.jpg') no-repeat;
    background-size: 100% 100%;
    .home1bot{
      display: flex;
      background: linear-gradient(to bottom, #2b5179 0%,#bcc5cd 100%);
      .home1bot-left{
        width: 180px;
        height: 100%;
        font-size: 12px;
        background: rgba(0, 0, 0,0.3);
        position: relative;
        .contents{
          width: 176px;
          color: #fff;
          h2{
            height: 45px;
            line-height: 45px;
            width: 100%;
            border-bottom: 1px solid #fff;
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-weight: 800;
            margin: 0 auto;
          }
          .assort{
            padding-top: 5px;
            overflow: auto;
            .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
              background: none;
            }
            .ant-menu-dark .ant-menu-submenu-title{
              padding-left: 16px !important;
            }
            .ant-menu-dark >.ant-menu-item{
              padding-left: 16px !important;
            }
            .ant-menu-dark  ul .ant-menu-item{
              padding-left: 28px !important;
            }
          }
        }
      }
      .home1bot-right{
        width: 100% !important;
        position: relative;
        height: 100% !important;
        .conshow{
          width: 100%;
          z-index: 400;
          position: absolute;
          padding: 10px;
          >div{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  //细分各个模块
  .home1aside{
    background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
    background-size: cover;
    display: none;
    width: 100% !important;
    position: relative;
    >div{
      height: 100% !important;
      height: 100% !important;
    }
    //树
    .ant-tree{
      font-size: 12px !important; 
      color: #fff !important;
      .anticon{
        margin-top:6px !important;
      }
      >li{
        padding: 0 !important;
        .ant-tree-node-content-wrapper{
          padding: 0 !important;
          color: #fff !important;
        }
        .ant-tree-switcher{
          width: 20px;
        }
        >.ant-tree-child-tree{
          >li{
            padding: 0 !important;
            >.ant-tree-child-tree{
              >li{
                padding: 0 !important;
                >.ant-tree-child-tree{
                  >li{
                    padding: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-tree li .ant-tree-node-content-wrapper:hover {
      color: #03d8db !important;
      background: none !important;
    }
    .ant-tree-directory,.ant-tree{
      font-size: 12px !important; 
      color: #fff !important;
      .anticon{
        margin-top:6px !important;
      }
      >li{
        padding: 0 !important;
        .ant-tree-node-content-wrapper{
          padding: 0 !important;
          color: #fff !important;
        }
        .ant-tree-title{
          padding:0 6px;
        }
        .ant-tree-switcher{
          width: 20px;
        }
        >.ant-tree-child-tree{
          >li{
            padding: 0 !important;
            >.ant-tree-child-tree{
              >li{
                padding: 0 !important;
                >.ant-tree-child-tree{
                  >li{
                    padding: 0 !important;
                    >.ant-tree-child-tree{
                      >li{
                        padding: 0 !important;
                        >.ant-tree-child-tree{
                          >li{
                            padding: 0 !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected, 
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected{
      border: none !important;
      background: #4db8b9 !important;
    }
    
    .ant-tree li .ant-tree-node-content-wrapper:hover {
      background-color: #00b7ee;
    }
    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: #00b7ee;
    }
    .ant-tree-node-selected .ant-tree-title{
      padding: 2px 6px !important;
      // background: #4db8b9 !important;
      background: none !important;
      color: #fff;
    }
    //加载进度条
    .loadprogress{
      display: none;
      position: absolute;
      left: 50%;
      top: 40%;
      width: 40%;
      .ant-progress-text{
        color: #fff;
      }
    } 
    #frame{
      border: none !important;
      width: 100% !important;
    }
    .h2title{
      height: 40px;
      color: #fff;
      font-size: 14px;
      line-height: 40px;
      background: rgba(0, 0, 0, 0.3);
      padding: 0 10px;
      .h2action{
        height: 40px;
        float: right;
        display: flex;
        align-items: center;
        button{
          height: 28px;
          font-size: 12px;
          margin-left: 4px;
          color: #fff;
          background: none;
          border: #fff solid 1px;
          &:hover{
            background: #00b7ee;
            border: #00b7ee solid 1px;
          }
          &:nth-child(1){
            background: #00b7ee;
            border: #00b7ee solid 1px;
          }
        }
      }
    }
  }
}

//计算
.tocalcucate{
  width: 800px;
  display: none;
  left:32%;
  top: 10%;
  position: absolute;
  height: 500px;
  z-index:5000;
  h2{
    cursor: move;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;
    padding: 0 5px 0 20px; 
    background: #002b3b;
    // background: rgba(0, 0, 0,0.7);
    span{
      height: 40px;
      font-size: 16px;
      text-align: center;
      display: block;
      width: 40px;
      line-height: 40px;
      cursor: pointer;
    }
  }
  //
  .calcucatedata{
    width: 800px;
    height:460px;
    // height: 100%;
    padding: 0px 20px;
    background: rgba(23, 43, 71,0.8);
    h3{
      height: 40px;
      font-weight: 900;
      font-size: 14px;
      padding:0 20px 0 24px;
      color: #fff !important;
      display: flex;
      align-items: center;
      .ant-select{
        width: 260px;
        height: 28px;
        z-index: 100000;
        .ant-select-selection{
          height: 28px;
          color: #fff !important;
          background: none;
          .anticon{
            color: #fff;
          }
        }
      }
    }
    .tocalcucatelist{
      height: 370px;
    }
    >span{
      display: block;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      button{
        width: 80px;
        height: 32px;
        background-color: #00a0e9;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
//echarts曲线图
.cepointechartstuzhu{
  display: none;
  width: 800px;
  position: absolute;
  left:32%;
  top: 10%;
  height: 380px;
  cursor: move;
  z-index: 5000;
  h2{
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;
    padding: 0 5px 0 20px;
    background: #002b3b;
    >span{
      display: block;
      width:120px;
      height: 40px;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      
  .ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active{
    background: none !important;
  }
      button{
        width: 76px;
        height: 24px !important;
        background: none;
        color: #fff;
        padding: 0px !important;
        text-align: center;
        >span{
          height: 24px !important;
          display: block;
          width: 100%;
          height: 100%;
          line-height: 22px !important;
          font-size: 12px !important;
        }
        &:hover{
          color: #53b9ec;
          background: none !important;
        }
      }
    }
  }
  .leftbutton{
    position: absolute;
    top: 45%;
    left: 15px;
    color: #fff;
    font-size: 24px;
    &:hover{
      color: #53b9ec;
    }
    cursor: pointer;
  }
  .rightbutton{
    position: absolute;
    top: 45%;
    right: 15px;
    color: #fff;
    font-size: 24px;
    &:hover{
      color: #53b9ec;
    }
    cursor: pointer;
  }
  #echartstuzhucedian {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 340px !important;
  }
  #echartstuzhucedianfu {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 340px !important;
  }
}
//测点历史曲线
.cepointechartstuhistory{
  display: none;
  width: 800px;
  position: absolute;
  left:32%;
  top: 6%;
  height: 380px;
  cursor: move;
  z-index: 6000;
  h2{
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;
    padding: 0 5px 0 20px;
    background: #002b3b;
    span{
      display: block;
      width:40px;
      height: 40px;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      button{
        width: 76px;
        height: 24px !important;
        background: none;
        color: #fff;
        padding: 0px !important;
        text-align: center;
        >span{
          height: 24px !important;
          display: block;
          width: 100%;
          height: 100%;
          line-height: 22px !important;
          font-size: 12px !important;
        }
        &:hover{
          color: #53b9ec;
          background: none !important;
        }
      }
    }
  }
  #echartstuhistory {
    padding: 10px 20px 10px 30px; 
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 340px !important;
  }
  .leftbuttonhis{
    position: absolute;
    top: 45%;
    left: 15px;
    color: #fff;
    font-size: 24px;
    &:hover{
      color: #53b9ec;
    }
    cursor: pointer;
  }
  .rightbuttonhis{
    position: absolute;
    top: 45%;
    right: 15px;
    color: #fff;
    font-size: 24px;
    &:hover{
      color: #53b9ec;
    }
    cursor: pointer;
  }
}


.selected{
  background: #0075a9;
}
.selecting{
  background: none;
}

//去掉树hover 点击背景
.ant-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before, 
.ant-tree .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before{
  background:none !important;
}
.ant-tree > li span.ant-tree-node-content-wrapper:hover::before,
 .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before{
  background: none !important;
}


.ant-table-placeholder{
  background: none !important;
  border: none !important;
}


.ant-tooltip{
  z-index: 1000111;
  max-width: 420px !important;
  .ant-tooltip-inner{
    background: rgba(0, 0, 0, 0.3);
  }
}

//配置hover
.ant-tooltip{
  position: absolute;
  z-index: 10000000 !important;
}
.ant-popover-placement-right{
  left: 110px !important;
  .ant-popover-arrow{
    display: none;
  }
  .ant-popover-inner{
    background: none;
    .ant-popover-inner-content{
      color: #fff;
      padding: 0 !important;
      .peizhipopover{
        padding: 8px 14px;
        background: rgba(24, 73, 88, 0.7);
        border: rgb(30, 101, 114) solid 1px;
        p{
          margin:0;
          &:hover{
            color: #03d8db;
            cursor: pointer;
          }
        }
      }

    }
  }
}

//诊断树 flag="1"
.flageq1{
  >span{
    >.ant-tree-title{
      background: #002b3b;
    }
  }
}

//table表格数据居中
.tabledatacenter{
  display: block;
  width: 100%;
  text-align: center !important;
}



//拖拽框
.dragwindow{
  position:absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: none;
  .h2title{
    height: 40px !important;
    cursor: move;
    color: #fff;
    font-size: 14px;
    line-height: 40px !important;
    // padding: 0 10px 0 20px !important;
    .h2action{
      height: 40px;
      display: flex;
      align-items: center;
      button{
        padding: 0 10px;
        cursor: pointer;
        height: 28px;
        font-size: 12px;
        margin-left: 4px;
        color: #fff;
        background: none;
        border: #fff solid 1px;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
      }
    }
  }
  .dragwindowcon{
    display: flex !important;
  }
  //分页列表
  .listpage{
    // width: 100%;
    padding:2px 5px 0 5px; 
    .ant-pagination{
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      bottom: 5px;
      right: 2px;
      margin: 0 !important;
      .anticon{
        color: #fff;
      }
      >li{
        margin: 0 2px !important;
        height: 26px;
        border: 1px solid #fff !important;
      }
      .ant-pagination-item{
        background: none;
        line-height: 26px;
        a{
          color: #fff;
        }
      }
      .ant-pagination-item-link{
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-pagination-item-ellipsis{
          color: #Fff;
        }
      }
      .ant-pagination-item-ellipsis{
        display: flex !important;
        justify-content: center !important; 
        width: 100%;
      }
      .ant-pagination-item-active{
        background: #285486;
      }
    }
    .ant-table-small{
      border: none;
    }
    .ant-table-wrapper{
      height: 100%;
      .ant-spin-nested-loading{
        height: 100%;
        .ant-spin-container{
          height: 100%;
        }
      }
      .ant-table{
        .ant-table-body{
          .ant-table-thead{
            border:1px solid rgb(177, 174, 174) !important;
            background: none !important;
            tr{
              th{
                height: 26px;
                line-height: 26px;
                text-align: center;
                padding: 0 !important;
                color: #fff !important;
                border-left: #ccc solid 1px;
                font-size: 12px;
                background: rgba(0, 0, 0, 0.3) !important;
                &:last-child{
                  border-right: 1px solid #ccc;
                }
              }
            }
          }
          .ant-table-tbody{   
            //选中
            .clickRowStyl{
              background: #556994 !important;
            }
            tr{
              background: none;
              &:nth-child(2n+1){
                background: rgba(255,255,255,0.2);
              }
              &:hover{
                background: #556994;
                cursor: pointer;
              } 
              td{
                height: 26px;
                line-height: 26px;
                font-size: 12px;
                padding: 0px !important;
                border-bottom: none !important;
                color: #fff !important;
                background: none;
                position: relative;
                img{
                  position: absolute;
                  top: 4px;
                  left: 30%;
                  max-width: 16px !important;
                  max-height: 16px !important;
                }
              }
            }
          }
        }
      }
    }
    .ant-table-row .ant-table-selection-column>span{
      display: flex !important;
      height: 26px !important;
      justify-content: center;
      align-items: center;
    }
  }

  //滚动复选框列表
  .listscrollcheckbox{
    padding: 2px 5px;
    .ant-table-wrapper{
      .ant-spin-container{
        .ant-table-small{
          border: none;
        }
        .ant-table{
          border-top: 1px solid #ccc !important;
          border-bottom: none !important;
          .ant-table-content{
            .ant-table-scroll{
              .ant-table-header{
                margin: 0px !important;
                overflow: hidden;
                background: none !important;
                height: 30px;
                .ant-table-thead{
                  th{
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    padding: 0px;
                    height: 30px;
                    line-height: 30px;
                    border-left: 1px solid #ccc;
                    background: rgba(0, 0, 0, 0.3) !important;
                  }
                }
              }
              .ant-table-body{
                background: none !important;
                .ant-table-tbody{
                  //选中
                  .clickRowStyl{
                    background: #556994 !important;
                  }
                  tr{
                    cursor: pointer;
                    &:hover{
                      background:#556994 !important;
                    }
                    &:nth-child(2n){
                      background:rgba(255, 255, 255, 0.2);
                    }
                    td{
                      background: none;
                      color: #fff;
                      font-size: 12px;
                      height: 28px;
                      line-height: 28px;
                      padding: 0px;
                      border: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 滚动列表
  .listscroll{
    // border: #e8e8e8 solid 1px;
    margin: 2px;
    .ant-table-wrapper{
      height: 100%;
      .ant-spin-nested-loading{
        height: 100%;
        .ant-spin-container{
          height: 100%;
          .ant-table{
            height: 100%;
          }
        }
      }
      .ant-table{
        border: none !important;
          .ant-table-content{
              .ant-table-scroll{
                  .ant-table-header{
                    background: none !important;
                    overflow: hidden;
                      height: 30px;
                      margin: 0 !important;
                      .ant-table-thead{
                        height: 30px;
                        tr{
                            th{
                              padding: 0;
                              margin: 0;
                                height: 30px;
                                line-height: 30px;
                                border: none !important;
                                color: #fff !important;  
                                font-size: 12px;
                                text-align: center;
                                font-weight: 900;
                                background: rgba(0, 0, 0, 0.3) !important;
                            }
                        }
                      }
                  }
                  .ant-table-body{
                    background: none;
                      table{
                        height: 100%;
                        .ant-table-tbody{
                          height: 100%;
                          .clickRowStyl{
                            background: #449bab !important;
                          } 
                          tr{
                            &:hover{
                              background: #449bab !important;
                              cursor: pointer;
                              // td{
                              //   background: none;
                              // }
                            }
                          }
                          .ant-table-row{
                              border: none;
                              background: none;
                              &:nth-child(2n+1){
                                  background: rgba(255, 255, 255, 0.3);
                              }
                              td{
                                padding: 0;
                                margin: 0;
                                height:28px;
                                line-height: 28px;
                                background: none;
                                border: none !important;
                                text-align: center;
                                font-size: 12px;
                                color: #fff !important;
                                position: relative;
                                img{
                                  position: absolute;
                                  top: 4px;
                                  left: 30%;
                                  max-width: 16px !important;
                                  max-height: 16px !important;
                                }
                              }
                          }
                        }
                      }
                  }
              }
          }
      }
    }
    .ant-table-row .ant-table-selection-column>span{
      display: flex !important;
      height: 26px !important;
      justify-content: center;
      align-items: center;
    }
  }
  //可编辑单元格
  .listcanedit{
    .ant-table-wrapper{
      .ant-table{
        .ant-table-content{
          .ant-table-scroll{
            .ant-table-header{
              overflow: hidden;
              background: none;
              table{
                tr{
                  th{
                    padding:0 0 0 6px !important;
                    font-size: 12px;
                    background: rgb(36, 116, 163) !important;
                    height: 30px;
                    line-height: 30px;
                    color: #fff;
                  }
                }
              }
            }
            .ant-table-body{
              .editable-row{
                height: 28px;
                td{
                  height: 28px;
                  .ant-form-item{
                    height: 28px;
                    font-size: 12px;
                    .ant-form-item-control-wrapper{
                      height: 28px;
                      .ant-form-item-control{
                        height: 28px;
                        line-height: 28px;
                        .ant-input{
                          background: none !important;
                          color: #fff;
                          height: 28px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dragaction{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      height: 28px;
      font-size: 12px;
      color: cornsilk;
      background: none;
      margin: 0 4px;
      &:nth-child(1){
        background: cornflowerblue;
      }
      &:hover{
        background: cornflowerblue;
      }
    }
  }
}

//分页列表
.listpage{
  // width: 100%;
  padding:2px 5px 0 5px; 
  .ant-pagination{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 5px;
    right: 2px;
    margin-bottom:5px !important;
    //跳转
    .ant-pagination-options{
      border: none !important;
      .ant-pagination-options-quick-jumper{
        color: #fff;
        margin-left: 10px;
        input{
          background: none;
          color: #fff;
        }
      }
    }
    .anticon{
      color: #fff;
    }
    >li{
      margin: 0 2px !important;
      height: 26px;
      border: 1px solid #fff !important;
    }
    .ant-pagination-item{
      background: none;
      line-height: 26px;
      a{
        color: #fff;
      }
    }
    .ant-pagination-item-link{
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-pagination-item-ellipsis{
        color: #Fff;
      }
    }
    .ant-pagination-item-ellipsis{
      display: flex !important;
      justify-content: center !important; 
      width: 100%;
    }
    .ant-pagination-item-active{
      background: #285486;
    }
  }
  .ant-table-small{
    border: none;
  }
  .ant-table-wrapper{
    height: 100%;
    .ant-spin-nested-loading{
      height: 100%;
      .ant-spin-container{
        height: 100%;
      }
    }
    .ant-table{
      border: none !important;
      .ant-table-body{
        .ant-table-thead{
          background: none !important;
          border:1px solid rgb(177, 174, 174) !important;
          tr{
            th{
              height: 26px;
              line-height: 26px;
              text-align: center;
              padding: 0 !important;
              color: #fff !important;
              border-left: 1px solid #ccc;
              font-size: 12px;
              background: rgba(0, 0, 0, 0.3) !important;
              &:last-child{
                border-right: 1px solid #ccc;
              }
            }
          }
        }
        .ant-table-tbody{   
          //选中
          .clickRowStyl{
            background: #556994 !important;
          }
          tr{
            background: none;
            &:nth-child(2n+1){
              background: rgba(255,255,255,0.2);
            }
            &:hover{
              background: #556994;
              cursor: pointer;
            } 
            td{
              height: 26px;
              line-height: 26px;
              font-size: 12px;
              padding: 0px !important;
              border-bottom: none !important;
              color: #fff !important;
              background: none;
              position: relative;
              img{
                position: absolute;
                top: 4px;
                left: 30%;
                max-width: 16px !important;
                max-height: 16px !important;
              }
            }
          }
        }
      }
    }
  }
  .ant-table-row .ant-table-selection-column>span{
    display: flex !important;
    height: 26px !important;
    justify-content: center;
    align-items: center;
  }
}

//滚动复选框列表
.listscrollcheckbox{
  padding: 2px 5px;
  .ant-table-wrapper{
    .ant-spin-container{
      .ant-table-small{
        border: none;
      }
      .ant-table{
        border-top: 1px solid #ccc !important;
        border-bottom: none !important;
        .ant-table-content{
          .ant-table-scroll{
            .ant-table-header{
              margin: 0px !important;
              overflow:hidden !important;
              background: none !important;
              .ant-table-thead{
                th{
                  text-align: center;
                  color: #fff;
                  font-size: 12px;
                  padding: 0px;
                  height: 30px;
                  line-height: 30px;
                  border-left: 1px solid #ccc;
                  background: rgba(0, 0, 0, 0.3) !important;
                }
              }
            }
            .ant-table-body{
              background: none !important;
              .ant-table-tbody{
                //选中
                .patrolbindequiptype1{
                  background: rgb(51, 130, 150) !important;
                }
                .clickRowStyl{
                  background: #556994 !important;
                }
                tr{
                  cursor: pointer;
                  &:hover{
                    background:#556994 !important;
                  }
                  &:nth-child(2n){
                    background:rgba(255, 255, 255, 0.2);
                  }
                  td{
                    background: none;
                    color: #fff;
                    font-size: 12px;
                    height: 28px;
                    line-height: 28px;
                    padding: 0px;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// 滚动列表
.listscroll{
  // border: #e8e8e8 solid 1px;
  margin: 2px;
  .ant-table-wrapper{
    height: 100%;
    .ant-spin-nested-loading{
      height: 100%;
      .ant-spin-container{
        height: 100%;
        .ant-table{
          height: 100%;
        }
      }
    }
    .ant-table{
      border: none ;
        .ant-table-content{
            .ant-table-scroll{
                .ant-table-header{
                    height: 30px;
                    background: none !important;
                    overflow: hidden;
                    margin: 0px  !important;
                    .ant-table-thead{
                      tr{
                        // border: 1px solid #ccc;
                          th{
                            padding: 0;
                            margin: 0;
                              height: 30px;
                              line-height: 30px;
                              color: #fff !important;  
                              font-size: 12px;
                              text-align: center;
                              font-weight: 900;
                              border-left: 1px solid #ccc;
                              background: rgba(0, 0, 0, 0.3) !important;
                          }
                      }
                    }
                }
                .ant-table-body{
                  background: none;
                    table{
                      height: 100%;
                      .ant-table-tbody{
                        height: 100%;
                        .clickRowStyl{
                          background: #449bab !important;
                        } 
                        tr{
                          &:hover{
                            background: #449bab !important;
                            cursor: pointer;
                            // td{
                            //   background: none;
                            // }
                          }
                        }
                        .ant-table-row{
                            border: none;
                            background: none;
                            &:nth-child(2n+1){
                                background: rgba(255, 255, 255, 0.3);
                            }
                            td{
                              padding: 0;
                              margin: 0;
                              height:28px;
                              line-height: 28px;
                              background: none;
                                border: none !important;
                                text-align: center;
                                font-size: 12px;
                                position: relative;
                                color: #fff !important;
                                img{
                                  position: absolute;
                                  top: 4px;
                                  left: 30%;
                                  max-width: 16px !important;
                                  max-height: 16px !important;
                                }
                            }
                        }
                      }
                    }
                }
            }
        }
    }
  }
  .ant-table-row .ant-table-selection-column>span{
    display: flex !important;
    height: 26px !important;
    justify-content: center;
    align-items: center;
  }
}

//可编辑单元格
.listcanedit{
  .ant-table-wrapper{
    .ant-table{
      .ant-table-content{
        .ant-table-scroll{
          .ant-table-header{
            background: none;
            table{
              tr{
                th{
                  padding:0 0 0 6px !important;
                  font-size: 12px;
                }
              }
            }
          }
          .ant-table-body{
            .editable-row{
              height: 28px;
              td{
                height: 28px;
                .ant-form-item{
                  height: 28px;
                  font-size: 12px !important;
                  .ant-form-item-control-wrapper{
                    height: 28px;
                    .ant-form-item-control{
                      height: 28px;
                      line-height: 28px;
                      .ant-input{
                        background: none !important;
                        color: #fff;
                        height: 28px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//关于
.aboutusinfo{
  position: absolute; 
  top:0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto !important;
  width: 60% !important;
  height: 80% !important;
} 
.aboutusinfo .ant-modal-content{
  height: 100%;
}
.aboutusinfo .ant-modal-content .ant-modal-body {
  height: 100%;
}
.aboutusinfo .ant-modal-content .ant-modal-body .ant-modal-confirm-body{
  height: 90%;
}
.aboutusinfo .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content{
  height: 100%;
  margin: 0px;
}
.aboutusinfo .ant-modal-close-x{
  display: none;
}
.aboutusinfo .about{
  height: 100%;
  overflow: auto;
  padding: 0 20px;
}
.aboutusinfo .about h2{
  margin: 0;
  height: 40px;
  font-size: 16px;
}
.aboutusinfo .about img{
  width: 300px !important;
  height: 100px !important;
  margin:2px 30% 8px 27%;
}
.aboutusinfo .about .connact{
  margin-top: 20px;
  margin-left: 10px;
}
.aboutusinfo .about .connact >p{
  display: flex;
}
.aboutusinfo .about .connact span{
  color: #3ba5b8;
} 
.aboutusinfo .about .connact a{
  display: block;
  color: #3ba5b8;
} 


//控件通用样式
//日历
.ant-time-picker-panel{
  background: #1c2f4a !important;
  .ant-time-picker-panel-inner{
    color: #fff;
    background: #1c2f4a !important;
    .ant-time-picker-panel-input  {
      color: #fff;
      background: none;
    }
  }
  .ant-time-picker-panel-select-option-selected {
    color: #000;
  }
  .ant-time-picker-panel-select-active li:hover{
    color: #000;
  }
}
.ant-calendar{
  background: #1c2f4a !important;
  color: #fff;
  .ant-calendar-date{
    color: #fff !important;
  }
  
  .ant-calendar-selected-day ,.ant-calendar-in-range-cell{
    .ant-calendar-date{
      color: #000 !important;
    }
  }
  .ant-calendar-time-picker-btn{
    color: cornsilk !important;
  }
  .ant-calendar-selected-day .ant-calendar-date{
    background: none;
  }
  .ant-calendar-ok-btn{
    background: #00a0e9 !important;
    color: #fff !important;
  }
  .ant-calendar-ok-btn-disabled{
    background: #00a0e9 !important;
    color: #fff !important;

  }
  .ant-calendar-input {
    background: none !important;
    color: #FFf;
  }
  .ant-calendar-header{
    background: #285486 !important;
    >div{
      background: #285486 !important;
      >span{
        display: block;
        background: #285486 !important;
        a{
          background: #285486 !important;
        }
      }
    }
    a{
      color: #fff;
    }
    .ant-calendar-year-panel{
      background: #002b3b;
    }
    .ant-calendar-month-panel{
      background: #002b3b;
    }
  }
  .ant-calendar-time-picker-inner{
    .ant-calendar-time-picker-input-wrap{
      background: #285486 !important;
      .ant-calendar-time-picker-input  {
        background: #285486 !important;
        text-align: center;
      }
    }
    li.ant-calendar-time-picker-select-option-selected{
      background: #002b3b;
    }
    .ant-calendar-time-picker-select-active ul li:hover{
      background: #0075a9;
    }
    .ant-calendar-time-picker-select{
      background: #285486 !important;
    }
  }
}
//下拉框背景颜色
.ant-select-dropdown{
  position: absolute;
  background: #002b3b !important;
  color: #fff !important;
  z-index: 100000 !important;
  .ant-select-dropdown-menu-item{
    color: #fff !important;
  }
  .ant-select-dropdown-menu-item-selected{
    background: #00a0e9 !important;
  }
  .ant-select-dropdown-menu-item-active{
    background: #00a0e9 !important;
  }
}

//列表滚动条
.listscroll{
  .ant-table-body {
    overflow: auto !important;
    overflow-x: hidden !important;
  }
}

//disabled 背景颜色
.ant-input-disabled{
  background: #517492 !important;
}
.ant-input-number-disabled .ant-input-number-input{
  background: #517492 !important;
}
.ant-input-disabled:hover{
  border: 1px solid #fff;
}
.ant-select-disabled {
  .ant-select-selection{
    background: #517492 !important;
  }
}

.ant-drawer{ 
  top: 50px !important;
}
//drawer弹出框
.Drawercommon{
  .ant-drawer-content-wrapper{
    width: 366px !important;
    .ant-drawer-content{
      background:rgba(32, 50, 72, 0.95) !important;
      box-shadow: -3px 0px 9px 0px 
        rgba(0, 0, 0, 0.5);
      .ant-drawer-header{
        background: none;
        height: 55px;
        .ant-drawer-title{
          color: #fff;
          font-size: 14px;
        }
        .ant-drawer-close{
          color: #fff;
        }
      }
      .ant-drawer-body{
        padding: 0px !important;
        .Drawercommonaddedit{
          padding: 20px 10px 0 20px;
          width: 100%;
          position: relative;
          //可视化基本信息 
          .twocodeupload{
            width:380px;
            height: 400px;
            .image{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .avatar-uploader{
                width: 100%;
                height: 100%;
                .ant-upload{
                  width: 100%;
                  height: 100%;
                  background: none;
                  color: #fff;
                }
              }
            }
          }
          .formbutton{
            position: absolute;
            height:50px !important;
            bottom: 0px !important;
            left: 45%;
          }
          //排班记录
          .assignclassrecord{
            .ant-row{
              height: 35px !important;
              .ant-col-12{
                width: 100%;
                .ant-form-item{
                  display: flex;
                  font-size: 14px;
                  margin: 0 !important;
                  width: 100%;
                  .ant-form-item-label{
                    width: 30%;
                    label{
                      color: #fff;
                    }
                  }
                  .ant-form-item-control-wrapper{
                    width: 60%;
                    .ant-form-item-control{
                      width: 100%;
                      .ant-form-item-children{
                        width: 100% !important;
                        display: block;
                        .ant-input-number{
                          width: 100% !important; 
                          background: none;
                          color: #fff;
                          .ant-input-number-handler-wrap{
                            background: none;
                          }
                        }
                        //时间选择
                        .ant-time-picker {
                          width: 100%;
                          .ant-time-picker-input{
                            background: none ;
                            color: #fff;
                          }
                          .ant-time-picker-clock-icon{
                            color: #fff !important;
                          }
                        }
                        .ant-calendar-picker{
                          width: 100% !important;
                          >div{
                            width: 100% !important;
                          }
                          .ant-calendar-picker-icon{
                            color: #fff;
                          }
                        }
                        .ant-select{
                          width: 100%;
                          .anticon{
                            color: #fff;
                          }
                          .ant-select-selection--multiple{
                            width: 100%;
                            min-height:28px !important;
                            line-height: 28px;
                          }
                          .ant-select-selection--multiple .ant-select-selection__choice{
                            color: #fff;
                            background:none !important;
                            border:none;
                          }
                          .ant-select-selection{
                            width: 100%;
                            background: none;
                            color: #fff;
                            .ant-select-selection__rendered{
                              min-height: 28px;
                              ul{
                                li{
                                  margin: 0 !important;
                                  height: 25px;
                                  line-height: 28px;
                                }
                              }
                            }
                          }
                        }
                        input {
                          height: 28px;
                          width: 100%;
                          background: none;
                          color: #fff !important;
                        }
                        textarea{
                          width: 100%;
                          background: none;
                          color: #fff !important;
                        }
                        .ant-radio-wrapper{
                          color: #fff;
                        }
                        button{
                          background: #53b9ec;
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
            .classconfig{
              overflow:auto;
              .dayselectnum{
                text-align: center;
                height: 30px;
              }
              .ant-row{
                height:50px !important;
                display: flex;
                .ant-col-14{
                  width: 100%;
                  .ant-row{
                    min-height: 30px;
                    margin: 0 !important;
                    display: flex;
                    .ant-form-item-label{
                      width: 120px;
                      label{
                        color: #fff;
                        padding-left: 12px ;
                      }
                      
                      .ant-form-item-required{
                        padding: 0px !important;
                      }
                    }
                    .ant-form-item-control-wrapper{
                      width: 310px;
                    }
                    .ant-radio-group{
                      span{
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
          //上传文件
          .uploaddocument{
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            .selectpicture{
              display: flex;
              flex-direction: column;
              align-items: center;
              .ant-upload{
                .ant-btn{
                  background: #53b9ec;
                  color: #fff;
                }
              }
              .ant-upload-list-item-name{
                color: #fff;
              }
              .anticon {
                color: #fff;
              }
              .ant-upload-list-item {
                &:hover{
                  .ant-upload-list-item-name{
                    color: #000;
                  }
                  .anticon {
                    color: #000;
                  }
                }
              }
            }
            .uploadpicture{
              margin-top: 20px;
            }
          }
          .onlyonecolform{
            padding-left: 20px !important;
          }
          .ant-form{
            height: 100%;
            color: #fff;
            .ant-row{
              min-height:40px;
              .ant-col-12{
                width: 100%;
                .ant-form-item{
                  display: flex;
                  font-size: 14px;
                  margin: 0 !important;
                  width: 100%;
                  .ant-form-item-label{
                    width: 30%;
                    label{
                      color: #fff;
                    }
                  }
                  .ant-form-item-control-wrapper{
                    width: 60%;
                    .ant-form-item-control{
                      width: 100%;
                      .ant-form-item-children{
                        width: 100% !important;
                        display: block;
                        .ant-input-number{
                          width: 180px !important; 
                          background: none;
                          color: #fff;
                          .ant-input-number-handler-wrap{
                            background: none;
                          }
                        }
                        //时间选择
                        .ant-time-picker {
                          width: 180px;
                          .ant-time-picker-input{
                            background: none ;
                            color: #fff;
                          }
                          .ant-time-picker-clock-icon{
                            color: #fff !important;
                          }
                        }
                        .ant-calendar-picker{
                          width: 180px !important;
                          >div{
                            width: 100% !important;
                          }
                          .ant-calendar-picker-icon{
                            color: #fff;
                          }
                        }
                        .ant-select{
                          width: 180px;
                          .anticon{
                            color: #fff;
                          }
                          .ant-select-search__field{
                            width: 0;
                            height: 0;
                          }
                          .ant-select-selection--multiple{
                            width: 100%;
                            min-height:28px !important;
                            line-height: 28px;
                          }
                          .ant-select-selection--multiple .ant-select-selection__choice{
                            color: #fff;
                            background:none !important;
                            border:none;
                          }
                          .ant-select-selection{
                            width: 100%;
                            background: none;
                            color: #fff;
                            .ant-select-selection__rendered{
                              min-height: 28px;
                              ul{
                                li{
                                  margin: 0 !important;
                                  height: 25px;
                                  line-height: 28px;
                                }
                              }
                            }
                          }
                        }
                        input {
                          height: 28px;
                          width: 180px;
                          background: none;
                          color: #fff !important;
                        }
                        textarea{
                          width: 180px;
                          margin: 5px 0;
                          background: none;
                          color: #fff !important;
                        }
                        .ant-radio-group{
                          .ant-radio-wrapper{
                            color: #fff;
                            >span{
                              &:nth-child(2){
                                padding: 0 3px;
                              }
                            }
                          }
                        }
                        button{
                          background: #53b9ec;
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.ant-table .ant-table-placeholder{
  border: none !important;
  color: #fff;
}

.searchconditionm{
  width:100%;
  padding:5px;
  table{
    width:100%;
    color: #fff;
    tr{
      th{
        height: 30px;
        line-height: 30px;
        padding-left:4px;
        font-size: 14px;
        &:nth-child(1){
          width: 24%;
        }
        &:nth-child(2){
          width: 17%;
        }
        &:nth-child(3){
          width: 30%;
        }
        &:nth-child(4){
          width: 13%;
        }
        &:nth-child(5){
          width:  12%;
        }
      }
      td{
        padding-left:4px;
        height: 30px;
        &:nth-child(1){
          width: 24%;
        }
        &:nth-child(2){
          width: 17%;
        }
        &:nth-child(3){
          width: 30%;
        }
        &:nth-child(4){
          width: 13%;
        }
        &:nth-child(5){
          width:  12%;
        }
        .anticon{
          color: #fff;
        }
        .ant-select{
          width: 92%;
          height: 28px;
          line-height: 28px;
          .ant-select-selection{
            background: none;
            height: 28px;
            line-height: 28px;
            color: #fff;
          }
        }
        .ant-input{
          width: 92%;
          height: 28px;
          line-height: 28px;
          color: #fff;
          background: none;
        }
      }
    }
  }
  .searchconditionmcon{
    display: block;
    overflow: auto;
    max-height: 210px;
    table{
      height: 100%;
    }
  }
}


.ant-select{
  .ant-select-selection{
    .ant-select-selection__rendered{
      font-size: 12px ;
    }
  }
}
.ant-input , .ant-input-number-input{
  font-size: 12px ;
}
input{
  font-size: 12px ;
}