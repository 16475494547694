.constructionpage{
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  #media{
    margin: 0 auto !important;
  }
  >h2{
    height: 40px;
    line-height: 30px;
    padding-left: 10px;
    color: #fff;
    margin: 0px;
    font-size: 14px;
  }
  .toolbar{
    height: 8%;
    line-height: 100%;

    .zoom-bar {
      background: #ededed;
      height: 40px;
      line-height: 40px;
      padding: 5px 10px;
    }
    
    .radio-label {
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      margin-left: 10px;
      text-align: center;
      width: 70px;
    
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      background: #fff;
      color: rgba(0,0,0,0.7);
    }
    
    .radio-label > input[type=radio] {
      height: 0;
      margin: 0;
      visibility: hidden;
      width: 0;
    }
    
    .radio-label-active {
      background: #a0a0a0;
      color: white;
      border-color: #707070;
    }
  }
  .constructionprogress{
    flex: 1;
    #gantt{
      width: 100% !important;
      height: 100%;
      .gantt_folder_open ,.gantt_file,.gantt_folder_closed{
        display: none;
      }
      .gantt_task_progress{
        background:#628c7a ;
      }
      .taskcolor{
        background: rgba(127,185,155,0.4);
        border: none !important;
      }
      .gantt_layout_cell{
        background: none;
        .grid_cell{
          .gantt_grid_scale{
            background: none;
            .gantt_grid_head_cell{
              color: #fff;
              border-right:1px solid #ccc !important;
            }
          }
          .gantt_grid_data{
            background:none;
            .gantt_selected{
              background: #628c7a !important;
              .gantt_tree_content{
                color: #fff;
              }
            }
            .gantt_row_task{
              background: none;
              border-bottom: 1px solid #ccc;
              .gantt_tree_content{
                font-size: 12px !important;
                color: #fff;
              }
              >div{
                border-right: 1px solid #ccc;
              }
            }
          }
        }

        .timeline_cell{
          .gantt_task_scale{
            background: none;
            .gantt_scale_cell{
              color: #fff;
            }
          }
          .gantt_data_area{
            background: none;
            .gantt_task_row{
              background: none;
            }
            .gantt_task_cell{
              border: none;
            }
            .gantt_selected .gantt_task_cell{
              border: 1px solid #fff !important;
              border-right-color: #bbb !important;
            }
            .gantt_bar_task{
              .gantt_task_content{
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  
}
.gantt_tree_indent{
  width: 4px !important;
}
.gantt_blank{
  width: 14px !important;
}
.nested_task .gantt_add{
  display: none !important;
}
.updColor{
  background-color: #ffeb8a !important;
}
.bgColor{
  background: #ffeb8a !important;
}