.Visualizationofrelative{
  display: flex;
  .tree{
    margin: 2px 10px;
    overflow: auto;
  }
  .Visualizationofrelativeleftcenter{
    width: 440px;
    display: flex;
    .react-resizable-handle{
      top: 0 !important;
      background-size:15px 15px !important;
      background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
    }
  }
  .Visualizationofrelativeleft{
    // width: 15%;
    background: rgba(0,0,0,0.5);
    .actionall{
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      >span{
        display: block;
        color: #fff;
        font-size: 13px;
        &:nth-child(2){
          margin-left: 15px;
          .ant-select{
            width: 120px;
            height:28px !important;
            line-height: 28px;
            .anticon{
              color: #fff;
            }
            .ant-select-selection{
              width: 100%;
              height:28px !important;
              line-height: 28px;
              background: none;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .Visualizationofrelativecenter{
    // width: 16%;
    border-right:1px solid #ccc;
  }
  .Visualizationofrelativeright{
    // width: 68.9%;
    display: flex;
    .actionall{
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      button{
        height: 28px;
        margin: 0 4px;
        line-height: 28px;
        font-size: 12px;
        background: none;
        color: #fff;
      }
      .actionleft ,.actionright{
        button{
          &:nth-child(1){
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
          &:hover{
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
        }
      }
    }
    .listpage{
      padding: 0px !important;
    }
   
    .Visualizationofrelativerightconl{
      width: 40%;
      border-right:1px solid #ccc;
      .actionall{
        padding: 0 5px;
        button{
          margin: 0 1px;
        }
      }
      .listpage{
        padding: 0 5px !important;
        .ant-table-body{
          margin: 0px ;
        }
      }
    }
    .Visualizationofrelativerightconr{
      width: 60%;
      .ant-tabs{
        .ant-tabs-bar{
          .anticon{
            color: #fff;
          }
          width: 100%;
          margin: 0 !important;
          height: 40px;
          line-height: 40px;
          background: rgba(0, 0, 0, 0.5) !important;
          border: none;
          .ant-tabs-nav{
            height: 40px;
            line-height: 40px;
            >div{
              .ant-tabs-tab{
                padding: 0 16px;
                height: 40px;
                line-height: 40px;
                background: none;
                margin: 0 !important;
                border:none;
                border-radius: 0;
                color: #fff;
                font-size: 12px;
                &:nth-child(2){
                  border-left: 1px solid #999;
                  border-right:  1px solid #999;
                  border-radius: 0;
                }
                &:nth-child(3){
                  border-right:  1px solid #999;
                  border-radius: 0;
                }
              }
              .ant-tabs-tab-active{
                border-radius: 0;
                background:#021a27; 
                color: rgb(99, 230, 223) !important;
              }
            }
          }
        }
        .ant-tabs-content{
          // padding: 0 20px 0 10px;
          .ant-form{
            button{
              height: 28px;
              background: #53b9ec;
              font-size: 12px;
              margin-left: 10px;
            }
            >.ant-row{
              width: 100%;
              display: flex;
              height: 42px;
              span{
                color: #fff;
              }
              >div{
                width: 50%;
                .ant-row{
                  min-height: 30px;
                  margin: 0 !important;
                  display: flex;
                  .ant-form-item-required::before{
                    margin-top: 8px;
                  }
                  .ant-form-item-label{
                    width: 36%;
                    label{
                      color: #fff;
                    }
                  }
                  .ant-form-item-control-wrapper{
                    width:60%;
                  }
                  .ant-input-number{
                    width: 100% !important; 
                    background: none;
                    color: #fff;
                    .ant-input-number-handler-wrap{
                      background: none;
                      .anticon{
                        color: #fff;
                      }
                    }
                    .ant-input-number-input{
                      font-size: 12px !important;
                    }
                  }
                  //时间选择
                  .ant-time-picker {
                    width: 100%;
                    .ant-time-picker-input{
                      background: none ;
                      color: #fff;
                    }
                    .ant-time-picker-clock-icon{
                      color: #fff !important;
                    }
                  }
                  .ant-calendar-picker{
                    width: 100% !important;
                    >div{
                      width: 100% !important;
                    }
                    .ant-calendar-picker-icon{
                      color: #fff;
                    }
                  }
                  .ant-select{
                    width: 100%;
                    height: 28px;
                    line-height: 28px;
                    .anticon{
                      color: #fff;
                    }
                    .ant-select-search__field{
                      width: 0;
                      height: 0;
                    }
                    .ant-select-selection--multiple{
                      width: 100%;
                      min-height:28px !important;
                      line-height: 28px;
                    }
                    .ant-select-selection--multiple .ant-select-selection__choice{
                      color: #fff;
                      background:none !important;
                      border:none;
                    }
                    .ant-select-selection{
                      width: 100%;
                      height: 28px;
                      line-height: 28px;
                      background: none;
                      color: #fff;
                      .ant-select-selection__rendered{
                        height: 28px;
                        line-height: 28px;
                        ul{
                          li{
                            margin: 0 !important;
                            height: 25px;
                            line-height: 28px;
                          }
                        }
                      }
                    }
                  }
                  input {
                    height: 28px;
                    width: 100%;
                    font-size: 12px;
                    background: none;
                    color: #fff !important;
                  }
                  .textarea{
                    width: 100%;
                    background: none;
                    color: #fff !important;
                  }
                }
              } 
            }
            >.particular{
              margin-top: 20px;
            }
            .address{
              width: 100%;
              height: 60px !important;
              .ant-row {
                width: 100% !important;
                display: flex;
                height: 60px !important;
                .ant-form-item-label{
                  width: 18%;
                  label{
                    color: #fff;
                  }
                }
                .ant-form-item-control-wrapper{
                  width: 80%;
                  .textarea{
                    width: 100%;
                    background: none;
                    color: #fff !important;
                  }
                }
              }
            }
          }
          .modeldoc{
            
        .tableheader{
          height: 30px;
          width: 98%;
          margin: 0 5px;
          tr{
            th{
              text-align: center;
              color: #fff;
              border: 1px solid #fff;
              line-height: 29px;
              background: rgba(0, 0, 0, 0.3) !important;
              &:nth-child(1){
                width: 30%
              }
              &:nth-child(2){
                width: 50%
              }
              &:nth-child(3){
                width: 20%
              }
            }
          }
        }
        .listscroll{
          .ant-table-body{
            margin-top:0px !important;
            .ant-table-tbody{
              tr{
                td{
                  color: #fff !important;
                  font-size: 12px !important;
                }
              }
            }
          }
        }
        .ant-collapse{
          margin: 4px 5px 0 5px;
          background: none;
          border: none;
          width: 98%;
          .ant-collapse-item-active{
            .ant-collapse-header{
              color: #fff;
              border: 1px solid #ccc !important;
              .anticon{
                margin: 0 !important;
              }
            }
          }
          .ant-collapse-item{
            border: none;
            margin: 2px 0;
            .ant-collapse-header{
              // border: 1px solid rgb(192, 190, 190);
              color: #fff;
              height:30px;
              line-height: 30px;
              font-size: 12px;
              padding:0px 40px;
              border: 1px solid #888;
              .anticon{
                margin: 0 !important;
              }
            }
            .ant-collapse-content{
              border: none !important;
              background: none;
              .ant-collapse-content-box{
                padding: 0px;
              }
            }
          }
        }
          }
        }
      }
    }
  }
  
    //文档预览
    .toviewfile{
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      z-index: 15;
      background: rgba(0, 0, 0, 0.5);
      overflow: auto;
      .pg-viewer-wrapper{
        height: 100%;
        width: 100%;
        .pg-viewer{
          >div{
            width: 100% !important;
            padding-right: 4%;
            height: 100% !important;
            overflow: auto;
          }
          .document-container{
            width: 98% !important;
            margin: 0% 1%;
          }
          .photo-viewer-container{
            img{
              max-width: 98%;
              max-height: 98%;
              margin: 1%;
              min-width: 80%;
              min-height: 80%;
              width: auto;
              height: auto;
            }
          }
          .pdf-viewer-container{
            width: 98% !important;
            margin: 0% 1%;
            .pdf-canvas{
              display: flex;
              justify-content: center;
            }
          }
          .spreadsheet-viewer{
            .react-grid-Container{
              width: 100% !important;
            }
          }
        }
        .unsupported-message{
          background: none !important;
          p{
            color: #fff;
          }
        }
      }
      .tocloseviewfile{
        position: fixed;
        right: 20px;
        top: 70px;
        display: block;
        img{
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      #swiplun{
        cursor: move;
      }
    }
}
.relativedoc , .techpara{
  position: absolute;
  // display: none;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
  background:rgba(56, 66, 72, 0.8);
  border-radius: 5px 5px 0px 0px;
	border: solid 1px #00a0e9;
  .searchlist{
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 40px;
    color: #fff;
    font-size: 14px;
    .ant-input-search{
      height: 28px;
      width: 220px;
      margin-left:10px;
      .ant-input{
        height: 28px;
        background: none;
        color: #fff;
      }
      .anticon{
        color: #fff;
      }
    }
  }
  h2{
    height: 40px;
    padding: 0 20px;
    cursor: move;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    span{
      font-size: 16px;
      cursor: pointer;
    }
    button{
      float: right;
      background: #00b7ee;
      color: #fff;
      height: 28px;
      font-size: 12px;
      &:hover{
        background: skyblue;
        color: #fff;
      }
    }
  }
  .listscroll{
    padding: 0 5px;
  }
}