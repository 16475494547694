.inspectionspec{
  height: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  display: flex;
  .actionall{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    border-bottom: 1px solid #fff;
    .actionleft{
      display: flex;
      color: #fff;
      align-items: center;
      .listsearchcondition{
        width: 80px;
        display: block;
        font-size: 13px;
      }
      .anticon{
        color: #fff;
      }
      .ant-input{
        height: 28px;
        line-height: 28px;
        background: none;
        color: #fff !important;
      }
    }
    .actionright{
      // margin-left: 20px;
      button{
        margin-left: 10px;
        height: 26px;
        font-size: 12px;
        color: #fff;
        background: none;
        border: #fff solid 1px;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          border: #00b7ee solid 1px;
          background: #00b7ee;
        }
      }
    }
  }
  .tree{
    width: 200px;
    height: 100%;
    background: rgba(0,0,0,0.5);
    .h2title{
      background: rgba(0,0,0,0.5);
    }
    .treedata{
      padding:10px;
      overflow: auto;
    }
  }
  .inspectionspeclist{
    height: 100%;
    border-left: 1px solid #fff;
    .inspectionspeclistcon{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .react-resizable-handle{
        top: 0 !important;
        background-size:15px 15px !important;
        background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
      }
      .kkstree{
        background: rgba(0,0,0,0.4);
        .kkstreedata{
          padding:10px;
          overflow: auto;
        }
      }
      .inspectionspecdata{
        flex: 1;
        .action{
          height: 40px;
          display: flex;
          align-items: center;
          button{
            margin-left: 10px;
            height: 28px;
            background: none;
            >span{
              font-size: 10px !important;
            }
            color: #fff;
            &:hover{
              background: #00b7ee;
              border: #00b7ee solid 1px;
            }
            &:nth-child(1){
              border: #00b7ee solid 1px;
              background: #00b7ee;
            }
          }
        }
        .listpage{
          padding: 2px !important;
        }
      }
    }
  }
}
.inspectionspecdrag{
  z-index: 1000;
  width: 800px;
  height: 460px;
  background: rgba(32, 50, 72,0.95);;
  .htitle{
    height: 40px;
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .ant-input-search {
      width: 200px;
      margin-left:10px;
      .ant-input{
        background: none;
        line-height: 28px;
        height: 28px;
        color: #fff;
      }
      .anticon{
        color: #fff;
      }
    }
  }
  .inspectionspecdraglist{
    margin: 2px 5px 0 5px;
  }
  .listscroll{
    height: 328px;
  }
  .action{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      margin-left: 10px;
      height: 28px;
      background: none;
      >span{
        font-size: 10px !important;
      }
      color: #fff;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        border: #00b7ee solid 1px;
        background: #00b7ee;
      }
    }
  }
}