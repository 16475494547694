.datacenter{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .listscroll{
        .ant-table-wrapper{
            height: 100%;
            .ant-spin-nested-loading{
              height: 100%;
              .ant-spin-container{
                height: 100%;
                .ant-table{
                  height: 100%;
                }
              }
            }
            .ant-table{
              border: none !important;
                .ant-table-content{
                    .ant-table-scroll{
                        .ant-table-header{
                           overflow: hidden;
                            line-height: 30px;
                            height: 30px;
                            margin: 0 !important;
                            .ant-table-thead{
                              height: 30px;
                              border: 1px solid #d2d2d2 !important;
                              tr{
                                  border: none;
                                    th{
                                        padding: 0;
                                        margin: 0;
                                        height: 30px;
                                        line-height: 30px;
                                        border: none;
                                        color: #fff !important;  
                                        font-size: 14px !important;
                                        text-align: center;
                                        font-weight: 100;
                                        background: rgba(255, 255, 255, 0.2) !important;
                                    }
                              }
                            }
                        }
                        .ant-table-body{
                            border-bottom: 1px solid #d2d2d2 !important;
                            background: none;
                            margin: 0 !important;
                            color: #fff !important;
                            table{
                              height: 100%;
                              .ant-table-tbody{
                                height: 99% !important;
                                .clickRowStyl{
                                  background: #449bab !important;
                                } 
                                tr{
                                    background: none !important;
                                    td{
                                        border:1px solid #ccc  !important;
                                        padding: 0;
                                        margin: 0;
                                        height:28px;
                                        line-height: 28px;
                                        background: none;
                                        text-align: center;
                                        font-size: 12px !important;
                                    }
                                  &:hover{
                                    background: #449bab !important;
                                    cursor: pointer;
                                  }
                                }
                              }
                            }
                        }
                    }
                }
            }
          }
    }
    .datatitlename{
        height: 18px;
        margin-bottom:10px;
        line-height: 18px;
        padding-left: 10px;
        width: 100%;
        font-weight: 900;
        border-left: 3px solid #32c5d2;
        color: #fff;
        font-size: 16px;
    }
    .datacentertop{
        height: 48%;
        display: flex;
        justify-content: space-around;
        .proimg{
          width:  49%;
          height: 100%;
          padding: 10px;
            background: rgba(0,0,0,0.3);
          img{
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
        .uploadmodel{
            padding: 10px;
            width:  49%;
            background: rgba(0,0,0,0.3);
        }
    }
    .datacenterbot{
        height: 49%;
        display: flex;
        justify-content: space-around;
        .docmodel{
            padding: 10px;
            width:  49%;
            background: rgba(0,0,0,0.3);
        }
        .piechart{
            padding: 10px;
            width:  49%;
            display: flex;
            flex-direction: column;
            background: rgba(0,0,0,0.3);
            .titlesele{
              height:40px;  
              margin-top: 5px;
              display: flex;
              .ant-select {
                  min-width: 112px;
                  height: 26px;
                  line-height: 26px;
                  .ant-select-selection{
                      background: none;
                      height: 26px;
                      line-height: 26px;
                      .ant-select-selection__rendered{
                          color: #fff;
                          height: 26px;
                          line-height: 26px;
                      }
                      .anticon{
                          color: #fff;
                      }
                  }
              } 
              .ant-input{
                  background: none;
                  color: #fff;
                  margin-left: 10px;
                  min-width: 200px;
                  max-width: 240px;
                  height: 26px;
                  line-height: 26px;
              }
            }
            #container{
                width: 100%;
                flex: 1;
            }
        }
    }
}