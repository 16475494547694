.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 0;
    margin: 0;
    text-align: center;
}
.firstpage{
    height: 100% !important;
    .ant-table-tbody>tr>td, .ant-table-thead>tr>th{
        color: #fff !important; 
    }
}
.firstpage .firsttop{
    width: 100%;
    height: 240px;
}
.firstpage .firsttop .alldata{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
.firstpage .firsttop .data{
    width: 32.6%;
    height: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    justify-content: center;
    position: relative;
}
.firstpage .firsttop .data .datatitlename{
    position: absolute;
    height: 18px;
    line-height: 18px;
    text-align: center;
    width: 80px;
    border-left: 3px solid #32c5d2;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 14px;
}
.firstpage .firsttop .data .dataleft {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    color: #fff;
    .ant-progress-text{
        color: #fff;
    }
    .finishrate{
        display: block;
        height: 30px;
        line-height: 32px;
        font-size: 14px;
    }
    .wait{
        font-size: 14px;
    }
}

  .firstpage .firstdown{
      width: 100%;
      margin-top:10px;
      display: flex;
      justify-content: space-between;
  }
  .firstpage .firstdown .userrecord{
    background: rgba(0, 0, 0, 0.4);
      position: relative;
      width: 40%;
  }
  .firstpage .firstdown .filerecord{
    background: rgba(0, 0, 0, 0.4);
    position: relative;
    width: 59%;
  }
  .firstpage .firstdown .datatitlename{
    display: block;
    margin: 11px 0 11px 10px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    width: 80px;
    border-left: 3px solid #32c5d2;
    color: #fff;
    font-size: 14px;
}
  
  .firstpage .listscroll{
      margin: 0 10px;
    .ant-table-wrapper{
        height: 100%;
        .ant-spin-nested-loading{
          height: 100%;
          .ant-spin-container{
            height: 100%;
            .ant-table{
              height: 100%;
            }
          }
        }
        .ant-table{
          border: none !important;
            .ant-table-content{
                .ant-table-scroll{
                    .ant-table-header{
                       overflow: hidden;
                        line-height: 30px;
                        height: 30px;
                        margin: 0 !important;
                        .ant-table-thead{
                          height: 30px;
                          border: 1px solid #d2d2d2 !important;
                          tr{
                              border: none;
                                th{
                                    padding: 0;
                                    margin: 0;
                                    height: 30px;
                                    line-height: 30px;
                                    border: none;
                                    color: #fff !important;  
                                    font-size: 14px !important;
                                    text-align: center;
                                    font-weight: 100;
                                    background: rgba(255, 255, 255, 0.2) !important;
                                }
                          }
                        }
                    }
                    .ant-table-body{
                        border-bottom: 1px solid #d2d2d2 !important;
                        background: none;
                        margin: 0 !important;
                        color: #fff !important;
                        table{
                          height: 100%;
                          .ant-table-tbody{
                            height: 99% !important;
                            .clickRowStyl{
                              background: #449bab !important;
                            } 
                            tr{
                                background: none !important;
                                td{
                                    border:1px solid #ccc  !important;
                                    padding: 0;
                                    margin: 0;
                                    height:28px;
                                    line-height: 28px;
                                    background: none;
                                    text-align: center;
                                    font-size: 12px !important;
                                }
                              &:hover{
                                background: #449bab !important;
                                cursor: pointer;
                              }
                            }
                          }
                        }
                    }
                }
            }
        }
      }
}