
    //资产可视化
    .assetsview{
        background:url('../../assets/img/background2.jpg') no-repeat center !important;
        width: 100% !important;
        height: 100%;
        overflow: hidden;
        position: relative;
        .checkyn{
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          .ant-checkbox-wrapper{
            span{
              color: #fff;
            }
          }
        }
        .treeshoufang{
          display: flex;
          position: absolute;
          top: 20px ;
          left: 20px;
          span{
            cursor: pointer;
            display: none;
            font-size: 14px;
            width: 60px;
            text-align: center;
            line-height: 36px;
            height: 36px;
            background: rgba(0,0,0,0.5);
            border-radius: 5px;
            color: #fff;
            margin-right: 10px;
          }
        }
        .assetviewleftcenter{
          max-width: 420px;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          .react-resizable-handle{
            top: 0 !important;
            background-size:15px 15px !important;
            background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
          }
        }
        .assetsviewleft{
          padding: 20px 12px;
          background: rgba(0,0,0,0.5);
          .zhuangpeishu{
            height: 30px;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: #fff;
            font-weight: 800;
            .zhuangpeishushousuo{
              cursor: pointer;
              width: 8px;
              height: 15px;
              display: block;
              background: url('../../assets/img/线箭头.png') no-repeat center;
              background-size: 100% 100%;
              margin-top:4px;
            }
          }
          .zhedieban{
            .ant-collapse{
              background: none;
              .ant-collapse-item{
                margin-bottom: 10px !important;
                .ant-collapse-header{
                  position: relative;
                  font-family: MicrosoftYaHei-Bold;
                  height: 30px;
                  padding: 0;
                  line-height: 30px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  color: #fff !important;
                  background-color: rgba(255,255,255,0.1) !important;
                  &:hover{
                    background: #0075a9 !important;
                  }
                  .anticon-caret-right{
                    display: none;
                  }
                  .ant-collapse-extra{
                    position: absolute;
                    top:22%;
                    left: 10px;
                    height: 19px !important;
                    display: flex;
                    align-items: center;
                  }
                }
                .ant-collapse-content{
                  .ant-collapse-content-box{
                    padding: 10px 0 0 5px !important;
                  }
                }
              }
              .ant-collapse-item-active{
                .ant-collapse-header{
                  height: 38px;
                  padding: 0;
                  line-height: 38px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  color: #fff;
                  background-color: #0075a9 !important;
                  .anticon-caret-right{
                    display: none;
                  }
                }
                .ant-collapse-content{
                  .ant-collapse-content-box{
                    //
                  }
                }
              }
            }
          }
        }
        .assetsviewcenter{
          display: none;
          padding:0 12px;
          background: rgba(0,0,0,0.3);
          >h2{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            height: 58px;
            line-height: 58px;
            color: #fff;
            font-weight: 700;
            font-size: 15px;
            margin: 0 !important;
            .kksshousuo{
              display: block;
              height: 16px;
              width: 8px !important;
              // padding-right: 60px !important;
              cursor: pointer;
              background: url('../../assets/img/线箭头.png') no-repeat right center;
              background-size: 100% 100%;
            }
          }
          .KKSsearch{
            width: 100%;
            height: 76px !important;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .ant-input-search{
              height: 28px !important;
              .ant-input{
                height: 28px !important;
                background: none !important;
                color: #fff;
              }
            }
            .ant-input-suffix{
              .ant-input-search-icon{
                color: #fff !important;
              }
            }
            .kkssele{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .ant-select{
                height: 28px !important;
                line-height: 28px !important;
                .ant-select-selection{
                  height: 28px !important;
                  line-height: 28px !important;
                  background: none;
                }
                .ant-select-arrow{
                  color: #fff;
                }
                .ant-select-selection-selected-value{
                  color: #fff;
                }
              }
              .btnkks{
                cursor: pointer;
                width: 28px;
                height: 28px;
                background-size: cover;
                background: url('../../assets/image/1.png') no-repeat center;
              }
            }
          }
        }
        .assetsviewright{
          display: none;
          width: 246px !important;
          position: absolute;
          min-height: 80px;
          top: 5px;
          right: 5px !important;
          background: none;
          .ant-tabs {
            width: 100% !important;
            .ant-tabs-bar{
              bottom: 0;
              border:1px solid #fff;
              border-radius: 5px;
              width: 100% !important;
              height: 80px;
              margin: 0 !important;
              .ant-tabs-nav-container{
                padding: 0 !important;
              }
              .ant-tabs-ink-bar{
                display: none !important;
                background: none !important;
              }
              .ant-tabs-nav-wrap{
                width: 100%;
                height: 80px;
                .ant-tabs-nav-scroll{
                  width: 100%;
                  height: 80px;
                  .ant-tabs-nav{
                    height: 80px;
                    width: 100%;
                    >div{
                      height: 80px;
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                    }
                  }
                }
              }
              .ant-tabs-tab{
                width: 44px !important;
                height: 52px !important;
                padding: 0 !important;
                margin: 0 !important;
                .assetsviewright1{
                  display: block;
                  margin: 7px 0;
                  width: 40px !important;
                  height: 40px !important;
                  img{
                    width: 40px !important;
                    height: 40px !important;
                  }
                  // background: url('../../assets/img/基本信息.png') no-repeat center;
                  // background-size: 38px 38px; 
                }
                .assetsviewright2{
                  display: block;
                  margin: 7px 0;
                  width: 40px !important;
                  height: 40px !important;
                  img{
                    width: 40px !important;
                    height: 40px !important;
                  }
                  // background: url('../../assets/img/供应商.png') no-repeat center;
                  // background-size: 38px 38px; 
                }
                .assetsviewright3{
                  display: block;
                  margin: 7px 0;
                  width: 40px !important;
                  height: 40px !important;
                  img{
                    width: 40px !important;
                    height: 40px !important;
                  }
                  // background: url('../../assets/img/责任相关.png') no-repeat center;
                  // background-size: 38px 38px; 
                }
                .assetsviewright4{
                  display: block;
                  margin: 7px 0;
                  width: 40px !important;
                  height: 40px !important;
                  img{
                    width: 40px !important;
                    height: 40px !important;
                  }
                  // background: url('../../assets/img/技术参数.png') no-repeat center;
                  // background-size: 38px 38px; 
                }
                .assetsviewright5{
                  display: block;
                  margin: 7px 0;
                  width: 40px !important;
                  height: 40px !important;
                  img{
                    width: 40px !important;
                    height: 40px !important;
                  }
                  // background: url('../../assets/img/文档关联.png') no-repeat center;
                  // background-size:38px 38px !important; 
                }
              }
            }
            .ant-tabs-content{
              width: 100% !important;
              height: 100% !important;
              .shuxing2,.shuxing3,.shuxing5,.shuxing1{
                border: solid #fff 0.5px !important; 
                border-radius: 5px;
                overflow: hidden;
                .ant-table-wrapper{
                  background: rgba(0,0,0,0.2) !important;
                  height: 100%;
                  .ant-table-small{
                    border:none !important;
                  }
                  .ant-table-content{
                    width: 100%;
                    overflow: hidden;
                    .ant-table-scroll{
                      width: 100%;
                      margin: 0;
                      .ant-table-header{
                        background: none;
                        height: 36px;
                        margin: 0 !important;
                        overflow: hidden;
                        tr{
                          width: 100% !important;
                          border:  none !important;
                          th{
                            border:  none !important;
                            color: #fff !important;
                            font-weight: 900;
                            height: 36px !important;
                            padding: 0;
                            margin: 0;
                            line-height: 36px !important;
                            &:nth-child(1){
                              width: 40% !important;
                              border-right: 1px solid #fff !important;
                            }
                            &:nth-child(2){
                              width: 60% !important;
                            }
                            >div{
                              width: 100%;
                              height: 36px !important;
                              line-height: 36px !important;
                              background: rgba(255,255,255,0.4) !important;
                              text-align: left;
                              padding-left: 20px !important;
                            }
                          }
                        }
                      }
                      .ant-table-body{ 
                        background: none;
                        .ant-table-row {
                          &:hover{
                            td{
                              background-color:rgb(32, 125, 201) !important;
                              color: #fff !important;
                            }
                          }
                        }  
                        .clickRowStyl{
                          background: #0075a9 !important;
                        } 
                        tr{
                          border:  none !important;
                          cursor: pointer;
                          height: 36px !important;
                          line-height: 36px !important;
                          &:nth-child(2n+1){
                            background: rgba(255,255,255,0.1) ;
                          }
                          &:nth-child(2n){
                            background:  none ;
                          }
                          td{
                            border:  none !important;
                            color: #fff !important;
                            height: 36px !important;
                            padding: 0;
                            margin: 0;
                            text-align: left !important;
                            line-height: 36px !important;
                            >span{
                              padding-left: 10px;
                            }
                          }
                        }
                      } 
                    }
                    .ant-table-placeholder{
                      height: 100%;
                      background: none;
                    }
                  } 
                }
                .twoweima{
                  height: 120px;
                }
              }
              .shuxing4{
                border: solid #fff 0.5px !important; 
                border-radius: 5px;
                overflow: hidden;
                .ant-table-wrapper{
                  background: rgba(0,0,0,0.3) !important;
                  height: 100%;
                  .ant-table-small{
                    border:none !important;
                  }
                  .ant-table-content{
                    width: 100%;
                    overflow: hidden;
                    .ant-table-scroll{
                      width: 100%;
                      margin: 0;
                      .ant-table-header{
                        background: none;
                        height: 36px;
                        margin: 0 !important;
                        overflow: hidden;
                        tr{
                          width: 100% !important;
                          border:  none !important;
                          th{
                            border:  none !important;
                            color: #fff !important;
                            font-weight: 900;
                            height: 36px !important;
                            padding: 0;
                            margin: 0;
                            text-align: left !important;
                            line-height: 36px !important;
                            &:nth-child(1){
                              width: 32% !important;
                              border-right: 1px solid #fff !important;
                            }
                            &:nth-child(2){
                              border-right: 1px solid #fff !important;
                              width: 33% !important;
                            }
                            &:nth-child(3){
                              width: 30% !important;
                            }
                            >div{
                              width: 100%;
                              height: 36px !important;
                              line-height: 36px !important;
                              background: rgba(255,255,255,0.4) !important;
                              text-align: left;
                              padding-left: 10px !important;
                            }
                          }
                        }
                      }
                      .ant-table-body{ 
                        background: none;
                        .ant-table-row {
                          &:hover{
                            td{
                              background-color: rgba(255,255,255,0.1) !important;
                              color: #002b3b !important;
                            }
                          }
                        }   
                        tr{
                          border:  none !important;
                          cursor: pointer;
                          height: 36px !important;
                          line-height: 36px !important;
                          &:nth-child(2n+1){
                            background: rgba(255,255,255,0.1) ;
                          }
                          &:nth-child(2n){
                            background:  none ;
                          }
                          td{
                            border:  none !important;
                            color: #fff !important;
                            height: 36px !important;
                            padding: 0;
                            margin: 0;
                            text-align: left !important;
                            line-height: 36px !important;
                            &:nth-child(1){
                              width: 32%;
                            }
                            &:nth-child(2){
                              width: 34%;
                            }
                            &:nth-child(3){
                              width: 30%;
                            }
                            >span{
                              padding-left: 10px;
                            }
                          }
                        }
                      } 
                    }
                    .ant-table-placeholder{
                      height: 100%;
                      background: none;
                    }
                  } 
                }
                .twoweima{
                  height: 120px;
                }
              }
              .shuxing5{
                .tableheader{
                  height: 34px;
                  width: 100%;
                  tr{
                    th{
                      text-align: center;
                      color: #fff;
                      border: 1px solid #fff;
                      line-height: 32px;
                      background: rgba(0, 0, 0, 0.3) !important;
                      &:nth-child(1){
                        width:40%
                      }
                      &:nth-child(2){
                        width: 60%
                      }
                    }
                  }
                }
                .listscroll{
                  .ant-table-body{
                    margin-top:0px !important;
                    .ant-table-tbody{
                      tr{
                        td{
                          color: #fff !important;
                          font-size: 12px !important;
                        }
                      }
                    }
                  }
                }
                .ant-collapse{
                  width: 99%;
                  margin-top: 0px;
                  background: none;
                  border: none;
                  .ant-collapse-item-active{
                    .ant-collapse-header{
                      color: #fff;
                      border: 1px solid #ccc !important;
                      .anticon{
                        margin: 0 !important;
                      }
                    }
                  }
                  .ant-collapse-item{
                    border: none;
                    margin: 2px 0;
                    .ant-collapse-header{
                      // border: 1px solid rgb(192, 190, 190);
                      color: #fff;
                      height:30px;
                      line-height: 30px;
                      font-size: 12px;
                      padding:0px 40px;
                      border: 1px solid #888;
                      .anticon{
                        margin: 0 !important;
                      }
                    }
                    .ant-collapse-content{
                      border: none !important;
                      background: none;
                      .ant-collapse-content-box{
                        padding: 0px;
                      }
                    }
                  }
                }
              }
            } 
          }
        }
      } 
      #assetsviewframe{
        border: none !important;
        width: 100% !important;
        body{
          margin: 0 !important;
          padding: 0 !important;
          width: 100%;
          height: 100%;
        }
      }