.dictionarymanage{
    
    .listsearch{
        height: 40px;
        // background-color: #004986;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        .listsearchl{
            color: #fff;
            font-size: 14px;
            .ant-input-search{
                height: 26px;
                width: 156px;
                .ant-input{
                    background: none;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    width: 156px;
                }
                .anticon {
                    color: #fff;
                }
            }
        }
        .listactionr{
            button{
                font-size: 12px;
                height: 26px;
                line-height: 24px;
                background-color: #1aa7f9 !important;
                border-radius: 5px;
                color: #fff;
                border: none;
            }
        }
    }
    .dictionarymanagecon{
        padding: 0px 20px;
        display: flex;
        .dictionarymanagel{
            .ant-tree{
                height: 100%;
                overflow: auto;
            }
            padding: 10px;
            width: 22%;
            background: rgba(0,0,0,0.2);
            border-right: 1px solid #ccc;
        }
        
        .listscroll{
            width: 78%;
            margin:0px !important;
            padding: 0px !important;
            background: rgba(0, 0, 0, 0.1);
            .listoperationa{
                display: flex;
                padding-left: 10px;
                a{
                    color: #60c8fd !important;
                    margin-left: 5px;
                }
            }
            .ant-table-wrapper{
                height: 100%;
                .ant-spin-nested-loading{
                height: 100%;
                .ant-spin-container{
                    height: 100%;
                    .ant-table{
                    height: 100%;
                    }
                }
                }
                .ant-table{
                border: none ;
                    .ant-table-content{
                        .ant-table-scroll{
                            .ant-table-header{
                                background:none;
                                overflow: hidden;
                                height: 30px;
                                margin: 0 !important;
                                .ant-table-thead{
                                height: 30px;
                                tr{
                                    border: 1px solid #ccc;
                                    th{
                                        padding: 0;
                                        margin: 0;
                                        height: 30px;
                                        line-height: 30px;
                                        color: #fff !important;  
                                        font-size: 13px;
                                        text-align: center;
                                        font-weight: 900;
                                        border-left: 1px solid #ccc;
                                        background: rgba(0, 0, 0, 0.3) !important;
                                    }
                                }
                                }
                            }
                            .ant-table-body{
                                background: none;
                                color: #fff !important;
                                margin-bottom: 0px !important;
                                table{
                                height: 100%;
                                .ant-table-tbody{
                                    height: 100%;
                                    .ant-table-row-expand-icon{
                                    background: none !important;
                                    }
                                    .clickRowStyl{
                                    background: #449bab !important;
                                    } 
                                    tr{
                                    &:hover{
                                        background: #449bab !important;
                                        cursor: pointer;
                                    }
                                    }
                                    .ant-table-row{
                                        border: none;
                                        background: none;
                                        &:nth-child(2n+1){
                                            background: rgba(255, 255, 255, 0.3);
                                        }
                                        td{
                                        padding: 0;
                                        margin: 0;
                                        height:28px;
                                        line-height: 28px;
                                        background: none;
                                            border: none !important;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// .ant-modal {
//     position: absolute !important;
//     padding: 0px !important;
//     top: 0px !important;
//     left: 0px !important;
//     bottom: 0px !important;
//     right: 0px !important;
//     margin: auto !important;
  
//     .ant-modal-content {
//       border-radius: 0px;
//       background: #203248; 
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       .modalactionbutton{
//         position: absolute;
//         top: 10px;
//         right: 15px;
//         height: 26px;
//         button{
//           height: 26px;
//           padding: 0 10px !important;
//           margin-left: 6px;
//           font-size: 12px;
//           color: #fff;
//           background: none;
//           &:hover, &:nth-child(1){
//             background: #1890ff;
//             color: #Fff;
//             border: 1px solid #1890ff;
//           }
//         }
//       }
//       .ant-modal-close {
//         right: 10px;
//         display: none;
//         .ant-modal-close-x {
//           height: 36px;
//           width: 36px;
//           line-height: 36px;
//         }
//       }
  
  
//       .ant-modal-header {
//         height: 44px;
//         padding: 0 20px;
//         border: none;
//         background:rgba(0, 0, 0, 0.8);
//         margin: 0px;
  
//         .ant-modal-title {
//           height: 100%;
//           color: #fff;
//           font-size: 14px;
//           line-height: 44px;
//           font-weight: bolder;
//         }
//       }
  
//       .ant-modal-body {
//         padding: 20px !important;
//         flex: 1;
//         .modalboxcon {
//           overflow-y: auto;
//           height: 100%;
//           overflow-x: hidden;
//           .modalboxcontitle {
//             display: flex;
//             height: 40px !important;
//             align-items: center;
//             font-size: 16px;
//             font-weight: 900;
//             margin: 0 0 10px 0 !important;
//             padding: 0px 0 0 20px !important;
  
//             .title {
//               padding-left: 10px;
//               height: 18px;
//               line-height: 18px;
//               border-left: 2px solid #0066ea;
  
//               .codenum {
//                 color: #1aa7f9;
//                 padding-left: 10px;
//               }
//             }
//           }
//           .ant-form-explain{
//             // display: none !important;
//           }
//           .formneccessary {
//             // background: url('../../../assets/require.png') no-repeat 8px 4px;
//           }
//           .ant-form-item-required::before{
//             float: left;
//           }
//           .datadictionaryform{
//             margin:  0 auto;
//             width: 490px;
//             height: 100%;
//             >.ant-row{
//               height: 50px;
//               margin: 0px;
//               display: flex;
//               width: 490px;
//               .ant-col-24{
//                 width: 240px;
//                 &:nth-child(1){
//                   margin-right: 10px;
//                 }
//                 .ant-form-item{
//                   width: 240px;
//                   display: flex;
//                   .ant-form-item-label{
//                     width: 80px;
//                     label{
//                       color: #fff;
//                       font-size: 12px;
//                     }
//                   }
//                   .ant-form-item-control-wrapper{
//                     flex: 1;
//                     .ant-input{
//                       width: 140px;
//                       background: none;
//                       color: #fff;
//                       height: 24px;
//                       line-height: 24px;
//                       font-size: 12px;
//                     }
//                     .ant-radio-group{
//                       display: flex;
//                       .ant-radio-wrapper{
//                         .ant-radio{
//                         }
//                         span{
//                           color: #fff;
//                           font-size: 12px;
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//               .ant-col-12{
//                 .ant-form-item{
//                   width: 480px;
//                   display: flex;
//                   .ant-form-item-label{
//                     width: 80px;
//                     label{
//                       color: #fff;
//                       font-size: 12px;
//                     }
//                   }
//                   .ant-form-item-control-wrapper{
//                     flex: 1;
//                     textarea{
//                       width:390px;
//                       background: none;
//                       color: #fff;
//                       line-height: 24px;
//                       font-size: 12px;
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
  
//       .ant-modal-footer {
//         height: 54px;
//         text-align: center;
  
//         button {
//           margin: 0 12px;
//           font-size: 12px;
//         }
//       }
//     }
//   }