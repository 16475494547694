.Inspectionroute{
  width: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  //标题
  .h2title{
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff;
    height: 34px;
    font-size: 12px;
    line-height: 34px;
    padding-left:16px;
    .action{
      float: right;
      button{
        height: 22px;
        background: #00b7ee;
        margin-right: 10px;
        font-size: 12px;
        color: #fff;
        border: none !important;
      }
    }
  }
  .action{
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      color: #fff;
      font-size: 13px;
    }
    .ant-input-search{
      width: 180px;
      .anticon{
        color: #fff;
      }
      .ant-input{
        color: #fff;
        background: none;
        width: 180px;
        height: 30px;
      }
    }
    button{
      margin-left: 10px;
      height: 28px;
      background: none;
      >span{
        font-size: 10px !important;
      }
      color: #fff;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        border: #00b7ee solid 1px;
        background: #00b7ee;
      }
    }
  }
  .Inspectionroutecon{
    display: flex;
    .Inspectionrouteconleft{
      width: 34%;
      border-right: 1px solid #fff;
    }
    .Inspectionrouteconright{
      width: 66%;
      .Inspectionrouteconrightcon{
        display: flex;
        .listscrollcheckbox{
          padding: 0 5px ;
        }
        .left{
          width: 100%;
          border-right: 1px solid #fff;
        }
        .right{
          // width: 50%;
          .anticon{
            color: #fff;
          }
          .ant-select{
            margin-left:5px;
            height: 28px;
            line-height: 28px;
            .ant-select-selection{
              background: none;
              height: 28px;
              line-height: 28px;
              width: 180px;
              color: #fff;
              .ant-select-selection__rendered{
                height: 28px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}

.inspectionmoduleload{
  position: absolute;
  display: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.8);
  #inspectionmodule{
    position: absolute;
    z-index:900;
    width: 100% !important;
  }
  h2{
    height: 40px;
    padding: 0 20px;
    cursor: move;
    background: rgba(0, 0, 0, 0.5);
    button{
      float: right;
      margin-top: 10px;
      background: #00b7ee;
      color: #fff;
      height: 28px;
      font-size: 12px;
      &:hover{
        background: skyblue;
        color: #fff;
      }
    }
  }
}
.InspectionVer{
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  .Inspectionroutecon{
    border: solid 1px #00b7ee;
    width: 100%;
    height: 100%;
    .h2title{
      cursor: move;
      background: rgba(0, 0, 0, 0.3) !important;
      color: #fff;
      height: 40px;
      font-size: 13px;
      line-height: 40px;
      padding-left:16px;
      .action{
        height: 40px;
        line-height: 40px;
        float: right;
        button{
          height: 26px;
          background: #00b7ee;
          margin-right: 10px;
          font-size: 12px;
          color: #fff;
          border: none !important;
        }
      }
    }
    .Inspectionaction{
      width: 100% !important;
      height: 36px;
      padding:0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 13px;
      }
      
    .ant-input-search{
      width: 180px;
      .anticon{
        color: #fff;
      }
      .ant-input{
        color: #fff;
        background: none;
        width: 180px;
        height: 30px;
      }
    }
      button{
        margin-left: 10px;
        height: 28px;
        background: none;
        >span{
          font-size: 10px !important;
        }
        color: #fff;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          border: #00b7ee solid 1px;
          background: #00b7ee;
        }
      }
    }
  }
}
