.wisdomdiagnosismodel{
  display: flex;
  background:linear-gradient(to bottom, #2d76a7 0%, rgb(153, 166, 175) 100%) !important;
  .h2title{
    height: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    padding-left: 20px;
    font-weight: 900;
  }
  .wisdomdiagnosismodelleft{
    width: 15%;
    .h2title{
      background: rgba(0, 0, 0, 0.5);
    }
    .wisdomdiagnosismodeltree{
      background: rgba(0, 0, 0, 0.3);
      .treeaction{
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        button{
          font-size: 12px;
          background: none;
          color: #ffff;
          height: 20px;
          width: 50px;
          padding: 0 !important;
          margin-right: 10px;
          &:hover{
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          &:nth-child(1){
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          >span{
            line-height: 18px;
            display: block;
            height: 20px;
            width: 50px;
          }
        }
      }
      .list{
        padding: 0 10px;
      }
    }
  }
  .wisdomdiagnosismodelright{
    width: 85%;
    position: relative;
    display: flex;
    .h2title{
      background: rgba(0, 0, 0, 0.3);
    }
    .con{
      background: rgba(0, 0, 0, 0.1);
      >span{
        display: flex;
        height: 40px;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        .ant-input{
          background: none;
          height: 24px;
          line-height: 24px;
          width: 180px;
          color: #fff;
        }
        .anticon{
          color: #fff;
        }
        .ant-select{
          height: 24px;
          line-height: 24px;
          .ant-select-selection{
            background: none;
            height: 24px;
            line-height: 24px;
            width: 180px;
            color: #fff;
            .ant-select-selection__rendered{
              height: 24px;
              line-height: 24px;
            }
          }
        }
        button{
          background: none;
          color: #fff;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          &:hover{
            background: #53b9ec;
          }
        }
      }
    }
    .wisdomdiagnosismodelright1{
      width: 50%;
    }
    .wisdomdiagnosismodelright2{
      width:50%;
      margin-left:10px;
    }
  }
}