.workplace1{
  height: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  
  .h2title{
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 13px;
    line-height: 40px;
    padding-left: 15px;
    color: #fff;
    display:flex;
    justify-content: space-between;
    .action{
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px 0 15px;
      button{
        height: 28px;
        font-size: 12px;
        margin-left: 10px;
        color: #fff;
        background: none;
        border: #fff solid 1px;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
      }

    }
  }
  .listsearch{
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    .listsearchr{
      button{
        height: 30px;
        font-size: 12px;
        margin-left: 10px;
        color: #fff;
        background: none;
        height: 28px;
        border: #fff solid 1px;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
      }
    }
    .listsearchl {
      width: 1030px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .listsearchls{
        display: flex;
        font-size: 12px;
        width: 28%;
        align-items: center;
        &:nth-child(1),&:nth-child(2){
          width: 22%;
        }
        .listsearchcondition{
          display: block;
          width: 100px;
          text-align: center;
          margin: 0 5px;
        }
        .searchcondition{
          display: block;
          width: 80%;
        }
      }
      .anticon{
        color: #fff;
      }
      .ant-calendar-picker{
        width: 100% !important;
        height: 28px;
        .ant-calendar-picker-input{
          background: none;
          height: 28px;
          width: 100%;
          color: #fff;
        }
      }
      .ant-input-search{
        width: 100%;
        height: 28px;
        .ant-input{
          height: 28px;
          line-height: 28px;
          background: none;
          color: #fff !important;
        }
      }
      .ant-select{
        width: 100%;
        background: none;
        height: 28px;
        line-height: 28px;
        .ant-select-selection{
          height: 28px;
          line-height: 26px;
          width: 100%;
          background: none;
          color: #fff;
          .ant-select-selection__rendered{
            height: 28px;
            line-height: 26px;
            ul{
              li{
                margin: 0 !important;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
  .ant-tabs{
    .ant-tabs-bar{
      width: 100%;
      margin: 0 !important;
      height: 36px;
      line-height: 36px;
      background: rgba(0, 0, 0, 0.5) !important;
      border: none;
      .ant-tabs-nav{
        height: 36px;
        line-height: 36px;
        >div{
          .ant-tabs-tab{
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: none;
            margin: 0 !important;
            border:none;
            border-radius: 0;
            color: #fff;
            font-size: 12px;
            &:nth-child(2){
              border-left: 1px solid #999;
              border-right: 1px solid #999;
              border-radius: 0;
            }
          }
          .ant-tabs-tab-active{
            border-radius: 0;
            background:#021a27; 
            color: rgb(99, 230, 223) !important;
          }
        }
      }
    }
    .ant-tabs-content{
    }
  }
}
//发起流程
.workplacemanageaddedit{
  box-shadow: 0 0 10px #999;
  display: none;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  //审核
  .preview{
    display: flex;
    width: 100%;
    height: 100% !important;
    .workplaceaddeditl{
      width: 80%;
      .form {
        height: 100% !important;
        overflow: auto;
        border-right:1px solid rgb(223, 212, 212);
        .ant-form-item{
          float: left;
          width: 100%;
          padding-left:4%;
          display: flex;
          font-size: 13px;
          margin: 0 !important;
          .ant-form-item-label{
            width: 28%;
            padding-left: 0%;
            label{
              color: #fff;
            }
          }
          .ant-form-item-control-wrapper{
            width:72%;
            .ant-form-item-control{
              width: 100%;
              .ant-form-item-children{
                width: 100% !important;
                display: flex !important;
              }
            }
          }
        }
      }
    }
    .workplaceaddeditr{
      width: 20%;
      height: 100% !important;
      .workplaceaudit{
        height: 40px;
        display: flex;
        padding-left:10px;
        align-items: center;
        button{
          margin-left:5px;
          background: none;
          color: #fff;
          height: 30px;
          font-size: 12px;
          &:hover,  &:nth-child(1){
            background:#00b7ee;
            border: #00b7ee solid 1px;
          }
        }
      }
      .ant-form {
        #AuditUsers{
          .ant-select-selection--multiple .ant-select-selection__choice{
            border: none !important;
          }
        }
      }
    }
  }
  .startcon{
    height: 100% !important;
    display: flex;
    //只有表
    .startformonly{
      width: 100% !important;
      display: flex;
      justify-content: center;
      .form {
        width: 50% !important;
      }
    }
    .startform ,.startformonly{
      height: 100% !important;
      width: 28%;
      overflow: auto;
      .form {
        height: 100% !important;
        padding:0 5% 0 0;
        overflow: auto;
        .ant-form-item{
          float: left;
          width:100%;
          padding-left:4%;
          display: flex;
          font-size: 13px;
          margin: 0 !important;
          .ant-form-item-label{
            width: 30%;
            padding-left: 4%;
            label{
              color: #fff;
            }
          }
          .ant-form-item-control-wrapper{
            width:68%;
            .ant-form-item-control{
              width: 100%;
              .ant-form-item-children{
                width: 100% !important;
                display: flex !important;
              }
            }
          }
        }
      }
    }
    .action{
      display: block;
      height: 30px;
      padding-left: 5px;
      button{
        background: #00b7ee;
        color: #fff;
        font-size: 12px;
        height: 28px;
      }
    }
    .listscroll{
      .ant-table-content{
        border:  none !important;
      }
    }
    .startcon1{
      width: 33%;
    }
    .startcon2{
      width: 37%;
    }
  }
  //发起
  .start{
    width: 100%;
    .startact{
      height: 40px;
      display: flex;
      padding: 0 1%;
      justify-content: flex-end;
      button{
        margin-left:5px;
        background: none;
        color: #fff;
        height: 30px;
        font-size: 12px;
        &:hover,  &:nth-child(1){
          background:#00b7ee;
          border: #00b7ee solid 1px;
        }
      }
    }
  }
  .h2title{
    height: 40px;
    color: #fff;
    font-size: 13px;
    line-height: 40px;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .ant-form{
    .ant-form-item-children-icon{
      display: none !important;
    }
    .ant-select-arrow ,.ant-calendar-picker-icon{
      right: 10px !important;
    }
    color: #fff;
    .ant-form-item{
      display: flex;
      font-size: 13px;
      margin: 0 !important;
      .ant-form-item-label{
        width: 33%;
        padding-left: 0%;
        label{
          color: #fff;
        }
      }
      .ant-form-item-control-wrapper{
        width: 65%;
        .ant-form-item-control{
          width: 100%;
          .ant-form-item-children{
            width: 100% !important;
            display: flex !important;
          }
        }
        .ant-calendar-picker{
          width: 100%;
          >div{
            width: 100%;
          }
          .anticon{
            color: #fff;
          }
        }
        .ant-select{
          width: 100%;
          line-height: 30px;
          min-height: 30px;
          .anticon{
            color: #fff;
          }
          .ant-select-selection{
            width: 100%;
            background: none;
            color: #fff;
            line-height: 30px;
            .ant-select-selection__rendered{
              ul{
                li{
                  margin: 0 !important;
                  height: 25px;
                  line-height: 30px;
                }
              }
            }
          }
          .ant-select-selection--multiple{
            .ant-select-selection__choice{
              border: none !important;
            }
            .ant-select-selection__rendered{
              padding-top:2px;
              ul {
                li{
                  background: none;
                  border: none !important;
                  margin-left:2px !important;
                  .ant-select-selection__choice__content{
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .ant-input{
          background: none;
          line-height: 30px;
          min-height: 30px;
          color: #fff;
        }
        .ant-input-number{
          background: none;
          line-height: 30px;
          min-height: 30px;
          color: #fff;
        }
      }
    }
  }
}


//审核记录 流程节点
.nodeflowrecord{
  display: none;
  position: absolute;
  width: 800px;
  height: 400px;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  h2{
    padding: 0 10px;
    cursor: move;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    justify-content: space-between;
    .closepage{
      cursor: pointer;
      width: 50px;
      height: 30px;
      background: rgb(107, 207, 247);
      color: cornsilk;
      float: right;
      line-height: 30px;
      border-radius: 5px;
      font-size: 12px;
      &:hover{
        background: rgb(74, 188, 233);
      }
    }
  }
  .listpage{
    height: 350px;
  }
}

.workplaceaddedit{
  .ant-drawer-content-wrapper{
    width: 100% !important;
    .ant-drawer-content{
      background:linear-gradient(to bottom, #3578bf 0%, #bcc5cc 100%) !important;
     .ant-drawer-wrapper-body{
        overflow: hidden;
        .ant-drawer-close{
          height: 40px;
          line-height: 40px;
        }
        .anticon-close{
          color: #fff;
        }
      }
      box-shadow: -3px 0px 9px 0px 
        rgba(0, 0, 0, 0.5);
      .ant-drawer-header{
        background: none;
        .ant-drawer-title{
          color: #fff;
          font-size: 14px;
        }
        .ant-drawer-close{
          color: #fff;
        }
      }
      .ant-drawer-body{
        margin: 0 !important;
        padding:0px !important;
        height: 100% !important;
        
      }
    }
  }
}