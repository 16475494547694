.threepage{
    background: rgba(0, 0, 0, 0.3) !important; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-table-tbody>tr>td, .ant-table-thead>tr>th{
      color: #fff !important; 
  }
}
.threepage  .datatitlename{
    height: 16px;
    margin-bottom: 9px;
    padding-left: 10px;
    line-height: 18px;
    width: 100%;
    font-weight: 900;
    border-left: 3px solid #32c5d2;
    color: #fff;
    font-size: 14px;
}
.threepage .threepagetop {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 200px;
    padding: 15px 20px 0 20px;
    .listscroll{
      .ant-table-thead{
        tr{
          th{
            border: none !important;
          }
        }
      }
    }
 }
.threepage .threepagebot{
    position: relative;
    flex: 1;
    padding: 15px 20px 0 20px;
    .linetypesele{
      width: 140px;
      height: 24px;
      position: absolute;
      top: 15px;
      right: 60px;
      .ant-select{
        width: 100%;
        height: 24px;
        // padding: 8px 0;
        .ant-select-selection{
          width: 100%;
          border-radius: 5px;
          height: 24px;
          background: none !important;
          color: #fff;
          padding-left: 24px;
          .ant-select-arrow{
            color: #fff !important;
          }
          .ant-select-selection__rendered{
            width: 100%;
            height: 24px;
            border-radius: 5px;
            .ant-select-selection-selected-value{
              width: 100%;
              height: 24px !important;
              line-height: 24px !important;
            }
          }
        }
      }
    }
 } 
 .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 0;
    margin: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;
}