.digitalassembly{
  display: flex;
  .digitalassemblyleft{
    width: 16%;
    background: rgba(0,0,0,0.5);
    .actionall{
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      >span{
        display: block;
        color: #fff;
        font-size: 13px;
        &:nth-child(2){
          flex: 1;
          margin-left: 15px;
        }
      }
      .ant-select{
        width: 90%;
        height:28px !important;
        .anticon{
          color: #fff;
        }
        .ant-select-selection{
          width: 100%;
          height:28px !important;
          background: none;
          color: #fff;
          .ant-select-selection__rendered{
            height: 28px !important;
            line-height: 26px !important;
          }
        }
      }
    }
    .tree{
      overflow: auto;
      padding: 0 10px;
    }
  }
  .digitalassemblyright{
    width: 83.9%;
    .actionall{
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      button{
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        background: none;
        color: #fff;
      }
      .actionleft {
        button{
          margin-right: 5px;
        }
      }
      .actionleft ,.actionright{
        button{
          &:nth-child(1){
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
          &:hover{
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
        }
      }
    }
    .listpage{
      padding: 2px;
    }
  }
}
//文档上传
.docmentupload{
  display: block !important;
  z-index: 100;
  width: 500px;
  height: 200px;
  background: #3f658a;
  border:2px solid #5b646b;
  h2{
    cursor:move;
    background: #213342;
    height: 32px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close{
      display: block;
      cursor: pointer;
      border: #fff solid 1px;
      width: 16px;
      text-align: center;
      line-height: 12px;
      height: 16px;
      border-radius: 50%;
    }
  }
  .tree{
    height: 380px;
    background: #466a8f;
    padding:15px;
    overflow: auto;
  }
  .uploaddocument{
    height: 40px;
    padding-left:40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    #file{
      color: #fff;
      height: 30px !important;
      line-height: 28px;
      font-size: 12px;
    }
    .ant-upload-list-item-info{
      color: #fff;
      &:hover{
        background: rgb(135, 238, 224);
      }
    }
    .anticon{
      color: #fff;
    }

    .uploadpicture{
      margin-left: 20px;
      height: 30px ;
      background: #3f658a;
      color: #fff;
      &:hover{
        background: rgb(79, 171, 194);
        color: #fff;
      }
    }
  }
}
//装配模型
.docmentupload1{
  display: block !important;
  z-index: 100;
  width: 600px;
  height: 500px;
  background: #3f658a;
  border:2px solid #5b646b;
  h2{
    cursor:move;
    background: #213342;
    height: 32px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close{
      display: block;
      cursor: pointer;
      border: #fff solid 1px;
      width: 16px;
      text-align: center;
      line-height: 12px;
      height: 16px;
      border-radius: 50%;
    }
  }
  .assemblysele1{
    height: 50px;
    line-height: 50px;
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #768ea7;
    background: none !important;
    
    .ant-select{
      width: 120px;
      margin-left: 20px;
      height:28px !important;
      .anticon{
        color: #fff;
      }
      .ant-select-selection{
        width: 100%;
        height:28px !important;
        background: none;
        color: #fff;
        .ant-select-selection__rendered{
          height: 28px !important;
          line-height: 26px !important;
        }
      }
    }
  }
  .tree{
    height: 400px;
    padding:10px 20px;
    overflow: auto;
  }
  .assemblymodelsave{
    position: absolute;
    top: 40px;
    right: 20px;
    height: 30px;
    font-size: 12px;
    background: #53b9ec;
    color: #fff;
    border: none;
  }
}
.digitalassemblymoduleload{
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6) !important;
  .digitalassemblymoduleloadcon{
    display: flex;
    justify-content: center;
    #digitalassemblymodule{
      width: 70% !important;
    }
    .formdan{
      width: 30%;
      height: 100%;
      .actionall{
        height: 40px;
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        button{
          background: none;
          font-size: 12px;
          color: #fff;
          height: 28px;
          &:nth-child(1){
            margin: 0 5px;
            background: cornflowerblue;
            border: cornflowerblue solid 1px;
          }
          &:hover{
            background: cornflowerblue;
            border: cornflowerblue solid 1px;
          }
        }
      }
      
      .ant-row .ant-col-12{
        width: 100%;
      }
      .ant-row .ant-form-item{
        display: flex;
        height: 40px;
        align-items: center;
        width: 100%;
        .ant-form-item-label{
          width: 40%;
          label{
            color: #fff;
          }
        }
        .ant-form-item-control-wrapper{
          width: 56%;
        }
        .ant-input{
          min-height:28px;
          background: none;
          color: #fff;
        }
      }
    }
  }
  h2{
    height: 40px;
    padding: 0 20px;
    cursor: move;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    line-height: 40px;
    font-size: 16px;
    button{
      float: right;
      margin-top: 10px;
      background: #00b7ee;
      color: #fff;
      height: 28px;
      font-size: 12px;
      &:hover{
        background: skyblue;
        color: #fff;
      }
    }
  }
}