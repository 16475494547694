
      
    //巡检
    .PatrolRouteFu{
        width: 100% !important;
        height: 100%;
        position: relative;
        background:url('../../assets/img/background2.jpg') no-repeat center !important;
        background-size: 100% 100% !important;
        //模拟巡检按钮
        .analoginspection{
          position: absolute;
          bottom:54px;
          left: 42%;
          button{
            margin: 0 5px;
            color: #fff !important;
            padding: 0 20px;
            font-size: 13px;
            background: #2f5d91;
            border: none;
            &:hover{
              background: #214d80;
            }
          }
        }
        .ant-table-placeholder{
          border: none !important;
          color: #Fff;
        }
        .treeshoufang{
          display: flex;
          position: absolute;
          top: 20px ;
          left: 20px;
          span{
            cursor: pointer;
            display: none;
            font-size: 14px;
            width:80px;
            text-align: center;
            line-height: 36px;
            height: 36px;
            background: rgba(0,0,0,0.5);
            border-radius: 5px;
            color: #fff;
            margin-right: 10px;
          }
        }
        .loadprogress{
          display: none;
          position: absolute;
          left: 50%;
          top: 40%;
          width: 40%;
          z-index: 900;
          .ant-progress-text{
            color: #fff;
          }
        } 
        .errorrecording{
          position: absolute;
          display: none;
          left: 73.8%;
          top: 2px;
          width: 26%;
          font-size: 14px;
          // border: solid 1px #00a0e9;
          .errorrecordingsearch{
            text-align: center;
            position: relative;
            background: rgba(0, 0, 0, 0.6);
            height: 34px;
            line-height: 34px;
            font-size: 16px !important;
            color: #fff;
            button{
              position: absolute;
              right: 10px;
              height: 30px;
              background: none;
              top: 2px;
              color: #fff;
              font-size: 12px;
              &:hover{
                background: #00a0e9;
              }
            }
          }
          // .errorrecordingsearch{
          //   height: 40px;
          //   background: rgba(0, 0, 0, 0.6);
          //   color: #fff;
          //   display: flex;
          //   align-items: center;
          //   font-size: 14px;
          //   justify-content: space-between;
          //   padding: 0 20px;
          //   .searchcondition{
          //     >span{
          //       margin-right:50px;
          //     }
          //   }
          //   .ant-select-selection{
          //     height: 28px;
          //     background:none;
          //     background: rgba(255, 255, 255, 0.1);
          //   }
          //   .ant-select-arrow{
          //     color: #fff;
          //   }
          //   .ant-select-selection-selected-value{
          //     color: #fff;
          //   }
          //   .ant-input{
          //     background: rgba(255, 255, 255, 0.1);
          //     color: #fff;
          //     height: 28px;
          //   }
          //   .ant-calendar-picker-icon{
          //     color: #fff;
          //   }
          //   .searchbutton{
          //     width: 16px;
          //     height: 16px;
          //     cursor: pointer;
          //     img{
          //       width: 16px;
          //       height: 16px;
          //     }
          //   }
          // }
        }
        .ant-tree li .ant-tree-node-content-wrapper:hover {
          color: #03d8db !important;
          background: none !important;
        }
        .PatrolRouteFuleftcenter{
          position: absolute;
          width: 440px;
          top: 0;
          left: 0;
          display: flex;
          z-index: 100;
          .react-resizable-handle{
            top: 0 !important;
            background-size:15px 15px !important;
            background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
          }
          .PatrolRouteFuleft{
            background: rgba(0,0,0,0.5);
            >h2{
              padding: 0 10px;
              position: relative;
              color:#fff;
              height: 40px;
              text-align: center;
              line-height: 40px;
              font-size: 15px;
              font-weight: 900;
              .zhuangpeishushousuo{
                position: absolute;
                right: 0px;
                top: 14px;
                cursor: pointer;
                width: 6px;
                height: 12px;
                display: block;
                background: url('../../assets/img/线箭头.png') no-repeat center;
                background-size: 100% 100%;
              }
            }
            .PatrolRouteFulefttree{
              padding: 0 10px 0 0;
              overflow: auto;
              .ant-tree{
                .ant-tree-switcher{
                  width: 12px !important;
                }
              }
              .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
                color: #00a0e9 !important;
                background: none;
              }
            }
          }
          .PatrolRouteFucenter{
            background: rgba(0,0,0,0.3);
            >h2{
              padding:0 10px 0 15px;
              color:#fff;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 15px;
              font-weight: 900;
              .right{
                display: flex;
                height: 50px;
                width: 20px;
                align-items: center;
                justify-content: space-between;
                .right2{
                  font-size: 26px;
                  height: 28px;
                  line-height: 24px;
                  font-weight: normal;
                  color: #fff;
                  cursor: pointer;
                  display: block;
                }
                .kksshousuo{
                  cursor: pointer;
                  margin-left: 5px;
                  width: 6px;
                  height: 12px;
                  display: block;
                  background: url('../../assets/img/线箭头.png') no-repeat center;
                  background-size: 100% 100%;
                }
              }
            }
            .PatrolRouteFucentertree{
              padding:0 10px 0 5px;
              overflow: auto;
              .ant-tree{
                .ant-tree-switcher{
                  width: 8px !important;
                }
              }
            }
            .cediansearch{
              width: 100%;
              height: 36px !important;
              .ant-input-search{
                height: 28px !important;
                .ant-input{
                  height: 28px !important;
                  background: none !important;
                  color: #fff;
                }
              }
              .ant-input-suffix{
                .ant-input-search-icon{
                  color: #fff !important;
                }
              }
            }
          }
        }
        .PatrolRouteFuright{
          z-index: 100;
          position: absolute;
          top: 0;
          right:0%;
          width: 28% !important;
          height: 100%;
          background: rgba(0,0,0,0.3);
          h2{
            height: 40px;
            color: #fff;
            line-height: 40px;
            font-size: 14px;
            padding-left: 10px;
            background: rgba(0, 0, 0, 0.3);
          }
          .PatrolRouteFurightcon{
            overflow: auto;
            padding-top: 10px;
          }
          .paramaterinfo{
            margin-top: 15px;
          }
          .equipinfo , .paramaterinfo{
            width: 100%;
            color: #fff;
            padding: 0 8px;
            table{
              width: 100%;
              tr{
                height: 26px;
                line-height: 26px;
                td{
                  font-size: 12px;
                  height: 26px;
                  line-height: 26px;
                  border: 1px solid #fff;
                  &:nth-child(2n+1){
                    width:21%;
                    padding-left: 5px;
                  }
                  &:nth-child(2n){
                    width: 79%;
                    padding-left: 5px;
                  }
                }
              }
            }
          }
          .paramaterinfo{

          }
          .collapseant{
            padding: 0 8px;
            margin-top: 15px;
            width: 100%;
            >ul{
              margin-top: 10px;
              >li{
                min-height: 28px;
                line-height: 26px;
                display: flex;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
                border-top: 1px solid #eee;
                border-bottom: 1px solid #fff;
                margin-bottom: 5px;
                >span{
                  font-size: 12px;
                  min-height: 28px;
                  color: #Fff;
                  padding-left: 5px;
                  &:nth-child(1){
                    width: 21%;
                  }
                  &:nth-child(2){
                    width: 19%;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  }
                  &:nth-child(3){
                    width: 60%;
                    padding: 0 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 24px;
                    .tubiao{
                      display: none !important;
                      height: 18px;
                      cursor: pointer;
                      width: 18px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border: #fff solid 1px;
                      border-radius: 40%;
                    }
                    .tuhaoactive{
                      background: #3cecfc;
                      border: none;
                    }
                  }
                }
              }
            }
            .imgtu{
              width: 100%;
              display: flex;
              justify-content: center;
              border: 1px solid #fff;
            }
          }
          .imgtu{
            height:160px;
            width: 280px;
            margin: 5px auto;
          }
          .historyrecord{
            position: absolute;
            bottom: 20px;
            left: 35%;
            button{
              color: #fff !important;
              padding: 0 20px;
              font-size: 13px;
              background: #69bac5;
              border: none;
            }
          }
        }
      }
      .routehistoryline{
        width: 500px;
        position: absolute;
        left: 30%;
        top: 10%;
        display: none;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.6);
        .routehistorylinetitle{
          font-size: 14px;
          cursor: move;
          height: 40px;
          color: #fff;
          line-height: 40px;
          padding:0 10px;
          background: rgba(0, 0, 0, 0.6);
          >span{
            float: right;
            font-size: 18px;
            cursor: pointer !important;
          }
        }
        .list{
          padding: 2px ;
          .ant-table-small{
            border: none;
          }
          .ant-table-wrapper{
            height: 100%;
            .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
                background: none;
                color: #fff !important;
                margin-top:8px;
                table{
                  border: #002b3b solid 1px !important;
                  .ant-table-row{
                      border: none !important;
                      &:nth-child(2n){
                          background: rgba(255, 255, 255, 0.2) !important;
                      }
                      td{
                        padding: 0 ;
                          border: none !important;
                          height: 26px !important;
                          text-align: left;
                          padding-left:10px;
                          line-height: 26px !important;
                          font-size: 12px;
                      }
                  }
                }
            }
            .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-header{
                background: none;
                height: 28px !important;
                overflow: hidden;
            }
            .ant-table-thead{
                >tr{
                  height: 28px !important;
                    th{
                       background: rgba(0, 0, 0, 0.35) !important;
                        text-align: center;
                        padding: 0px !important;
                        border: 1px solid rgb(219, 212, 212) !important;
                        height: 30px !important;
                        color: #fff !important;  
                        font-size: 13px;
                    }
                }
            }
        }
        }
      }
.moduleload{
  position: absolute;
  top: 0;
  left: 0;
}