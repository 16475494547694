.Inspectiontask{
  width: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  //标题
  .h2title{
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff;
    height: 34px;
    font-size: 12px;
    line-height: 34px;
    padding-left:16px;
    .action{
      float: right;
      button{
        height: 22px;
        background: #00b7ee;
        margin-right: 10px;
        font-size: 12px;
        color: #fff;
        border: none !important;
      }
    }
  }
  .action{
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 10px;
    font-size: 13px;
    .actionright{
      margin-left: 20px;
    }
    .ant-input-search{
      width: 180px;
      .anticon{
        color: #fff;
      }
      .ant-input{
        color: #fff;
        background: none;
        width: 180px;
        height: 30px;
      }
    }
    button{
      margin-left: 10px;
      height: 28px;
      background: none;
      >span{
        font-size: 10px !important;
      }
      color: #fff;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        border: #00b7ee solid 1px;
        background: #00b7ee;
      }
    }
  }
  .Inspectiontaskcon{
    .list{
      padding: 0 5px ;
      .ant-table-wrapper{
        height: 100%;
        .ant-table{
          border: none;
        }
        .ant-table-header{
          margin: 0 !important;
        }
        .ant-table-body{
          margin: 0 !important;
        }
        .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
            background: none;
            color: #fff !important;
            table{
              //选中
             .clickRowStyl{
               background: #556994 !important;
             }
              .ant-table-row{
                  border: none !important;
                  &:nth-child(2n){
                      background: rgba(255, 255, 255, 0.2) ;
                  }
                  td{
                    padding: 0 ;
                      border: none !important;
                      height: 26px !important;
                      text-align: left;
                      // padding-left:10px;
                      line-height: 26px !important;
                      font-size: 12px;
                      .tabledatapadding{
                        padding-left:20px;
                      }
                  }
                  .ant-table-selection-column{
                    display: flex;
                    justify-content: center;
                  }
              }
            }
        }
        .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-header{
            background: none;
            height: 28px !important;
            overflow: hidden;
        }
        .ant-table-thead{
            >tr{
              height: 28px !important;
                th{
                   background: rgba(0, 0, 0, 0.35) !important;
                    text-align: center;
                    padding: 0px !important;
                    border: 1px solid rgb(219, 212, 212) !important;
                    height: 30px !important;
                    color: #fff !important;  
                    font-size: 12px;
                }
            }
        }
    }
    }
  }
}
