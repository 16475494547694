.wisdomdiagnosistools{
  display: flex;
  background:linear-gradient(to bottom, #2d76a7 0%, rgb(153, 166, 175) 100%) !important;
  .h2title{
    height: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    padding-left: 20px;
    font-weight: 900;
  }
  .wisdomdiagnosistoolsleft{
    width: 15%;
    .h2title{
      background: rgba(0, 0, 0, 0.5);
    }
    .wisdomdiagnosistoolstree{
      background: rgba(0, 0, 0, 0.3);
      .treeaction{
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        button{
          font-size: 12px;
          background: none;
          color: #ffff;
          height: 20px;
          width: 50px;
          padding: 0 !important;
          margin-right: 10px;
          &:hover{
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          &:nth-child(1){
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          >span{
            line-height: 18px;
            display: block;
            height: 20px;
            width: 50px;
          }
        }
      }
      .tree{
        padding-left: 10px;
        .ant-tree{
          font-size: 12px !important; 
        color: #fff !important;
        .anticon{
          margin-top:6px !important;
        }
        >li{
          padding: 0 !important;
          .ant-tree-node-content-wrapper{
            padding: 0 !important;
            color: #fff !important;
          }
          .ant-tree-title{
            padding-right: 10px !important;
          }
          .ant-tree-switcher{
            width: 20px !important;
          }
          >.ant-tree-child-tree{
            >li{
              padding: 0 !important;
            }
          }
        }
        }
      }
      .tree .ant-tree li .ant-tree-node-content-wrapper{
        &:hover{
          background: none !important;
          color: #53b9ec;
        }
      }
      .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
        background: #53b9ec;
      }
    }
  }
  .wisdomdiagnosistoolsright{
    width: 84.9%;
    position: relative;
    .listaction{
      position: absolute;
      top: 135px;
      right: 10px;
      z-index: 800;
      button{
        font-size: 12px;
        background: none;
        color: #ffff;
        height: 20px;
        width: 50px;
        padding: 0 !important;
        margin-right: 10px;
        &:hover{
          background: #53b9ec;
          border: 1px solid #53b9ec;
        }
        &:nth-child(1){
          background: #53b9ec;
          border: 1px solid #53b9ec;
        }
        >span{
          line-height: 18px;
          display: block;
          height: 20px;
          width: 50px;
        }
      }
    }
    .wisdomdiagnosistoolsrighttop{
      margin-bottom: 10px;
      .h2title{
        background: rgba(0, 0, 0, 0.36);
      }
      .pcontent{
        background: rgba(0, 0, 0, 0.1);
        color: #fff;
        height: 80px;
        font-size: 12px;
        padding: 10px;
        text-indent: 4ex;
        line-height: 26px;
      }
    }
    .ant-tabs{
      .ant-tabs-bar{
        margin: 0 !important;
        height: 30px;
        line-height: 30px;
        background: rgba(0, 0, 0, 0.1);
        border: none;
        .ant-tabs-nav{
          height: 30px;
          line-height: 30px;
          >div{
            .ant-tabs-tab{
              height: 30px;
              padding: 0 24px;
              line-height: 30px;
              background: none;
              border:none;
              border-radius: 0;
              color: #fff;
              font-size: 12px;
              &:nth-child(2){
                border-left: 1px solid #ccc;
                border-right:  1px solid #ccc;
                border-radius: 0;
              }
            }
            .ant-tabs-tab-active{
              border-radius: 0;
              background:#07344d; 
              color: rgb(99, 230, 223) !important;
            }
          }
        }
      }
    }
  }
}