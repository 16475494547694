.logmanage{
  height: 100%;
  position: relative;
  
//针对列表搜索
.listsearch{
  height: 36px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  .listsearchr{
    button{
      height: 28px;
      font-size: 12px;
      margin-left: 10px;
      color: #fff;
      background: none;
      border: #fff solid 1px;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
    }
  }
  .listsearchl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .listsearchls{
      display: flex;
      width: 34%;
      align-items: center;
      .listsearchcondition{
        display: block;
        width: 80px;
        font-size: 14px;
        text-align: center;
      }
      .searchcondition{
        display: block;
        width: 80%;
      }
    }
    .anticon{
      color: #fff;
    }
    .ant-calendar-picker{
      width: 100% !important;
      height: 28px;
      .ant-calendar-picker-input{
        height: 28px;
        background: none;
        width: 100%;
        color: #fff;
      }
    }
    .ant-input-search{
      width: 100%;
      height: 28px;
      .ant-input{
        height: 28px;
        line-height: 28px;
        background: none;
        color: #fff !important;
      }
    }
    .ant-select{
      width: 100%;
      background: none;
      height: 28px;
      line-height: 28px;
      .ant-select-selection{
        height: 28px;
        line-height: 28px;
        width: 100%;
        background: none;
        color: #fff;
        .ant-select-selection__rendered{
          min-height: 28px;
          ul{
            li{
              margin: 0 !important;
              height: 25px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
  .listpage{
    // background: chartreuse;
  }
  .linetu{
    // background: chocolate;
    display: flex;
    padding: 0 10%;
  }
}


//日志详情
.logdetail{
  display: none;
  z-index: 100;
  width: 860px;
  height: 460px;
  background: #3f658a;
  border:2px solid #5b646b;
  h2{
    cursor:move;
    background: #213342;
    height: 36px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close{
      display: block;
      cursor: pointer;
      border: #fff solid 1px;
      width: 16px;
      text-align: center;
      line-height: 12px;
      height: 16px;
      border-radius: 50%;
    }
  }
  button{
    font-size: 12px;
  }
  .logdetaillist{
    margin-top: 4px;
    height: 400px ;
  }
}

  //日志
  .loglistcon,.verlistcon{
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0px;
    background: linear-gradient(to bottom, #3f689b 0%, #a6abaf 100%) !important;
    .actionall{
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 15px;
      button{
        height: 28px;
        line-height: 26px;
        font-size: 12px;
        background: none;
        color: #fff;
      }
      .actionleft {
          display: flex;
          color: #fff;
          .logtitle{
            font-size: 14px;
            margin-right: 20px;
          }
          .vertitle{
            font-size: 14px;
            margin-right: 20px;
          }
          font-size: 12px;
          align-items: center;
          .ant-calendar-picker{
            margin-left: 10px;
            height: 28px;
            .ant-calendar-picker-input {
              background: none;
              height: 28px;
              line-height: 28px;
              padding: 0px 10px;
              color: #fff;
              font-size: 12px;
              .ant-calendar-picker-clear{
                background: none !important;
              }
              .anticon , .ant-calendar-range-picker-separator{
                color: #fff;
              }
            }
          }
          img{
            margin-left: 15px;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
      }
      .actionright{
          button{
            margin-left: 5px;
          }
      }
      .actionleft ,.actionright{
        button{
          height: 28px;
          line-height: 26px;
          font-size: 12px !important;
          &:nth-child(1){
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
          &:hover{
            background: #53b9ec;
            border:#53b9ec solid 1px ;
          }
        }
      }
    }
  }