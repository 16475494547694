.processmanagement{
  display: flex;
  .tree{    
    height: 100%;
    padding:10px 0 0 10px;
  }
  .h2title{
    height: 40px;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    padding-left: 20px;
    background: rgba(0, 0, 0, 0.3);
  }
  .processmanagementaction{
    height: 40px;
    display: flex;
    justify-content: space-between;
    .processmanagementactionl{
      display: flex;
      align-items: center;
      padding-left: 10px;
      button{
        &:nth-child(1){
          background: #53b9ec;
          border: 1px solid #53b9ec;
        }
      }
    }
    button{
      font-size: 12px;
      background: none;
      color: #ffff;
      height: 26px;
      margin-right: 10px;
      &:hover{
        background: #53b9ec;
        border: 1px solid #53b9ec;
      }
      &:nth-child(1){
        background: #53b9ec;
        border: 1px solid #53b9ec;
      }
    }
  } 
  .processmanagementl{
    width: 18%;
    background: rgba(0, 0, 0, 0.5);
    .h2title{
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .processmanagementr{
    width: 82%;
    position: relative;
  }
}
.processmanagementaddedit{
  box-shadow: 0 0 10px #999;
  min-height: 580px !important;
  width: 1180px !important;
  display: none;
  background:linear-gradient(to bottom, #5b7da1 0%, #bcc5cc 100%) !important;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 20px 30px;
  overflow-y:auto;
  .h2title{
    width: 1125px;
    height: 40px;
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    padding-left: 16px;
    margin-bottom: 10px;
    // margin-left: 15px;
    background: rgba(0, 0, 0, 0.3);
  }
  .ant-form{
    color: #fff;
    
    .ant-radio-wrapper{
      color:  #fff !important;
    }
    label{
      font-size: 13px !important;
    }
    .formbutton{
      .ant-form-item-children{
        display: flex !important;
      }
      button{
        height: 30px;
        color: #fff;
        background: none;
        margin-right: 5px;
        font-size: 12px;
        &:nth-child(1){
          background: #53b9ec;
          border: 1px solid #53b9ec;
        }
        &:hover{
          background: #53b9ec;
          border: 1px solid #53b9ec;
        }
      }
    }
    .formbtn{
      margin-bottom: 20px !important;
      width: 100% !important;
    }
    .textipt{
      .ant-col-12{
        width: 100% !important;
        .ant-form-item{
          display: flex;
          font-size: 14px;
          margin: 0 !important;
          width: 100%;
          .ant-form-item-label{
            width: 8.2%;
            label{
              color: #fff;
            }
          }
          .ant-form-item-control-wrapper{
            width: 92.8%;
            .ant-form-item-control{
              width: 100%;
              .ant-form-item-children{
                width: 100% !important;
                display: flex !important;
              }
            }
            .ant-input{
              background: none;
              line-height: 30px;
              height: 100px;
              color: #fff;
            }
          }
        }
      }
    }
    .stepjiedian{
      margin-bottom: 10px;
      min-height: 40px;
      ul{
        padding: 0 1%;
        li{
          float: left;
          width: 100px;
          background: url('../../assets/imgs/progress.png') no-repeat 70px center;
          display: flex;
          margin-bottom: 5px;
          align-items: center;
          &:nth-child(1), &:last-child{
            >span{
              background: rgb(84, 202, 231);
            }
          }
          &:last-child{
            background: none !important;
          }
          >span{
            cursor: pointer;
            height:34px;
            width: 60px;
            padding: 0 2px;
            text-align: center;
            line-height: 34px;
            border-radius: 15px;
            display: block;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: #fff solid 1px;
            color: cornsilk;
          }
          .stepjiedianselected{
            background: rgb(84, 202, 231) ;
          }
          .stepjiediannoselected{
            background: none;
          }
        }
      }



      .stepjiedianselected{
        .ant-steps-item-content{
          background: #00b7ee !important;
          border-radius: 4px;
        }
      }
      .stepjiediannoselected{
        .ant-steps-item-content{
          background: #ccc !important;
          border-radius: 10px;
        }
      }
      .ant-steps{
        padding:0 10px !important;
        .ant-steps-item{
          // width: 64px;
          cursor: pointer;
          margin:0 !important;
          .ant-steps-item-content{
            // width: 100% !important;
          }
          .ant-steps-item-title{
            color: #fff;
            width: 100%;
            padding: 0 2px !important;
            max-width: 64px !important;
            // overflow: auto;
            text-align: center;
            font-size: 12px;
          }
          .ant-steps-item-title::after{
            background: #b51717 !important;
          }
          &:nth-child(1), &:last-child{
            .ant-steps-item-content{
              background: #00b7ee;
              border-radius: 10px;
              margin:0 !important;
            }
          }
        }
        .ant-steps-item-icon{
          display: none;
        }
      }
    }
    .ant-row{
      padding-left: 5px;
      width: 100%;
      .ant-col-12{
        width: 33.3%;
      }
      .ant-form-item{
        display: flex;
        font-size: 14px;
        margin: 0 !important;
        width: 100%;
        .ant-form-item-label{
          width: 26%;
          label{
            color: #fff;
          }
        }
        .ant-form-item-control-wrapper{
          width: 74%;
          .ant-form-item-control{
            width: 100%;
            .ant-form-item-children{
              width: 100% !important;
              display: flex !important;
              >span{
                justify-content: space-between;
                display: flex !important;
                width: 100%;
                button{
                  font-size: 12px;
                  padding: 0 12px;
                  background: #00b7ee;
                  border: none;
                  color: #fff;
                  height: 30px;
                  margin-left: 5px;
                  &:hover{
                    background: #54aad4 !important;
                  }
                }
              }
            }
          }
          .ant-select{
            width: 100%;
            line-height: 30px;
            min-height: 30px;
            .anticon{
              color: #fff;
            }
            .ant-select-selection{
              width: 100%;
              background: none;
              color: #fff;
              .ant-select-selection__rendered{
                min-height: 30px;
                ul{
                  li{
                    margin: 0 !important;
                    height: 25px;
                    line-height: 30px;
                  }
                }
              }
            }
            .ant-select-selection--multiple{
              .ant-select-selection__choice{
                border: none !important;
              }
              .ant-select-selection__rendered{
                padding-top:2px;
                ul {
                  li{
                    background: none;
                    margin-left:2px !important;
                    .ant-select-selection__choice__content{
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          .ant-input{
            background: none;
            line-height: 30px;
            height: 30px;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.processmanagementdraw{
  height: 560px !important; 
  width: 1140px !important;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  .anticon-close{
    color: #fff;
  }
  .ant-drawer-content-wrapper{
    height: 560px !important; 
    width: 1140px !important;
    .ant-drawer-content{
      background:linear-gradient(to bottom, #3578bf 0%, #bcc5cc 100%) !important;
     .ant-drawer-wrapper-body{
        overflow: hidden;
      }
      box-shadow: -3px 0px 9px 0px 
        rgba(0, 0, 0, 0.5);
      .ant-drawer-header{
        background: none;
        .ant-drawer-title{
          color: #fff;
          font-size: 14px;
        }
        .ant-drawer-close{
          color: #fff;
        }
      }
      .ant-drawer-body{
        margin: 0 !important;
        padding:20px 10px !important;
      }
    }
  }
}
//编辑审核表单界面
.processmanagedrag{
  display: none;
  position: absolute;
  width: 500px;
  height: 320px;
  z-index: 10000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  // background: oldlace;
  h2{
    padding: 0 10px;
    cursor: move;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    justify-content: space-between;
    .closepage{
      cursor: pointer;
      width: 50px;
      height: 30px;
      background: rgb(107, 207, 247);
      color: cornsilk;
      float: right;
      line-height: 30px;
      border-radius: 5px;
      font-size: 12px;
      &:hover{
        background: rgb(74, 188, 233);
      }
    }
  }
  .content{
    height: 220px;
    width: 100%;
    padding: 10px;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    .ant-input{
      color: #000;
      background: none;
      color: #fff;
    }
  }
  .contenta{
    height: 220px;
    display: flex;
    padding-top: 20px;
    line-height: 36px;
    justify-content: center;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    input{
      height: 32px;
      width: 200px;
      margin-left:20px;
      line-height: 32px;
      background: none;
      color: #fff;
    }
  }
  .auditcontentsavecancel{
    height: 40px;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    button{
      height: 30px;
      margin:0 4px;
      font-size: 12px;
      background: none;
      color: #fff;
      &:nth-child(1),&:hover{
        background: rgb(74, 188, 233);
      }
    }
  }
}