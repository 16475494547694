.InspectionRecord{
  height: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  .InspectionRecordcon{
    display: flex;
    .tree{
      width:320px;
      background: rgba(0, 0, 0, 0.5);
      .searchcondition{
        padding: 10px;
        .ant-input-search {
          width: 100%;
          .ant-input{
            width: 100% !important;
            background: none;
            line-height: 28px;
            height: 28px;
            color: #fff;
          }
          .ant-input-suffix{
            width: 14px;
          }
          .anticon{
            color: #fff;
          }
        }
        .ant-calendar-picker{
          height: 28px;
          width: 100%;
          line-height: 28px;
          .ant-calendar-picker-input{
            background: none;
            width: 100%;
            color: #fff;
            height: 28px;
            line-height: 28px;
          }
          .anticon {
            color: #fff;
          }
        }
        display: flex;
        justify-content: space-around;
        span{
          display: block;
          width: 48%;
        }
      }
      .treedata{
        margin: 0 10px;
        overflow: auto;
      }
    }
    .InspectionRecordlist{
      border-right: 1px solid #ccc;
    }
    .PatrolRouteFuright{
      h2{
        width: 100%;
        height: 40px;
        color: #fff;
        line-height: 40px;
        font-size: 14px;
        padding-left: 10px;
        background: rgba(0, 0, 0, 0.3);
      }
      .PatrolRouteFurightcon{
        width: 100%;
        overflow: auto;
        padding: 3px 0;
      }
      .paramaterinfo{
        margin-top: 10px;
      }
      .equipinfo , .paramaterinfo{
        width: 100%;
        color: #fff;
        padding: 0 8px;
        table{
          width: 100%;
          tr{
            height: 26px;
            line-height: 26px;
            td{
              font-size: 12px;
              height: 26px;
              line-height: 26px;
              border: 1px solid #fff;
              &:nth-child(2n+1){
                width:20%;
                padding-left: 5px;
              }
              &:nth-child(2n){
                width: 80%;
                padding-left: 5px;
              }
            }
          }
        }
      }
      .paramaterinfo{

      }
      .collapseant{
        padding: 0 8px;
        margin-top: 10px;
        width: 100%;
        >ul{
          margin-top: 10px;
          >li{
            min-height: 28px;
            line-height: 26px;
            display: flex;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #fff;
            margin-bottom: 5px;
            >span{
              font-size: 12px;
              min-height: 28px;
              color: #Fff;
              padding-left: 5px;
              &:nth-child(1){
                width: 20%;
              }
              &:nth-child(2){
                width: 20%;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
              }
              &:nth-child(3){
                width: 60%;
                padding: 0 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;
                .tubiao{
                  display: none !important;
                  height: 18px;
                  cursor: pointer;
                  width: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: #fff solid 1px;
                  border-radius: 40%;
                }
                .tuhaoactive{
                  background: #3cecfc;
                  border: none;
                }
              }
            }
          }
        }
        .imgtu{
          width: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid #fff;
        }
      }
      .historyrecord{
        position: absolute;
        bottom: 20px;
        left: 35%;
        button{
          color: #fff !important;
          padding: 0 20px;
          font-size: 13px;
          background: #69bac5;
          border: none;
        }
      }
    }
  }
}
//弹框
.InspectionRecorddrag{
  z-index: 1000;
  width: 1200px;
  height: 620px;
  background: rgba(32, 50, 72,0.95);;
  .dragwindowcon{
    height: 576px;
    .htitle{
      height: 40px;
      font-size: 15px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .ant-input-search {
        width: 180px;
        .ant-input{
          background: none;
          line-height: 28px;
          height: 28px;
          color: #fff;
        }
        .anticon{
          color: #fff;
        }
      }
    }
    .PDADCS{
      height: 30px;
      font-size: 13px;
      color: #fff;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      span{
        height: 30px;
        line-height: 30px;
        &:last-child{
          padding: 0 5px;
          border-radius:5px;
          display: block;
          border: 1px solid #fff;
        }
      }
    }
    .dragwindowconl{
      width: 60%;
      border-right:1px solid #Fff;
      .list{
        margin: 0 15px;
        
        .clickRowStyl{
          background: #285486 !important;
        }
      }
    }
    .dragwindowconr{
      width: 40%;
      .dragwindowconrtableimg{
        height: 500px;
        overflow: auto;
        margin-top:4px;
      }
      table{
        margin:10px 20px 0 10px;
        width: 96%; 
        tr{
          td{
            &:nth-child(2n+1){
              width:20%;
              padding-left: 5px;
            }
            &:nth-child(2n){
              width: 80%;
              padding-left: 5px;
            }
          }
        }
        tr{
          height: 24px;
          line-height: 24px;
          td{
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            border: 1px solid #fff;
          }
        }
      }
      .equipinfo{
        width: 100%;
        color: #fff;
        padding: 0 8px;
        table{
          margin:4px 20px 0 10px;
        }
      }
      .pressureinfo{
        width: 100%;
        color: #fff;
        padding: 0 8px;
      }
      .collapseant{
        padding: 0 8px;
        margin:10px 20px 0 10px;
        width: 96%; 
        >ul{
          margin-top: 10px;
          >li{
            height: 26px;
            line-height: 24px;
            display: flex;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #fff;
            margin-bottom: 5px;
            >span{
              font-size: 12px;
              height: 28px;
              color: #Fff;
              padding-left: 5px;
              &:nth-child(1){
                width: 20%;
              }
              &:nth-child(2){
                width: 15%;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
              }
              &:nth-child(3){
                width: 65%;
                padding: 0 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;
                .tubiao{
                  display: none !important;
                  height: 18px;
                  cursor: pointer;
                  width: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: #fff solid 1px;
                  border-radius: 40%;
                }
                .tuhaoactive{
                  background: #3cecfc;
                  border: none;
                }
              }
            }
          }
        }
        .imgtu{
          width: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid #fff;
        }
      }
      .imgtu{
        margin: 5px 20px 0 20px;
        height: 116px;
        // border: 1px solid #fff;
      }
    }
  }
}