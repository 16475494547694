.menumanage{
    height: 100%;
    display: flex;
    flex-direction: column;
    .listsearch{
        height: 40px;
        background-color: #004986;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px !important;
        .listsearchl{
            .ant-input-search{
                height: 26px;
                width: 156px;
                .ant-input{
                    background: none;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    width: 156px;
                }
                .anticon {
                    color: #fff;
                }
            }
        }
        .listactionr{
            button{
                font-size: 12px;
                height: 26px;
                line-height: 24px;
                background-color: #1aa7f9 !important;
                border-radius: 5px;
                color: #fff;
                border: none;
            }
        }
    }
    .listscroll{
        margin: 10px 15px 20px 15px !important;
        padding: 0px !important;
        background: rgba(0, 0, 0, 0.1);
        .listoperationa{
            a{
                color: #60c8fd !important;
                margin-left: 5px;
            }
        }
        .ant-table-wrapper{
            height: 100%;
            .ant-spin-nested-loading{
              height: 100%;
              .ant-spin-container{
                height: 100%;
                .ant-table{
                  height: 100%;
                }
              }
            }
            .ant-table{
              border: none ;
                .ant-table-content{
                    .ant-table-scroll{
                        .ant-table-header{
                            background:none;
                            overflow: hidden;
                            height: 30px;
                            margin: 0 !important;
                            .ant-table-thead{
                              height: 30px;
                              tr{
                                border: 1px solid #ccc;
                                  th{
                                    padding: 0;
                                    margin: 0;
                                      height: 30px;
                                      line-height: 30px;
                                      color: #fff !important;  
                                      font-size: 13px;
                                      text-align: center;
                                      font-weight: 900;
                                      border-left: 1px solid #ccc;
                                      background: rgba(0, 0, 0, 0.3) !important;
                                  }
                              }
                            }
                        }
                        .ant-table-body{
                            background: none;
                            color: #fff !important;
                            margin-bottom: 0px !important;
                            table{
                              height: 100%;
                              .ant-table-tbody{
                                height: 100%;
                                .ant-table-row-expand-icon{
                                  background: none !important;
                                }
                                .clickRowStyl{
                                  background: #449bab !important;
                                } 
                                tr{
                                  &:hover{
                                    background: #449bab !important;
                                    cursor: pointer;
                                  }
                                }
                                .ant-table-row{
                                    border: none;
                                    background: none;
                                    &:nth-child(2n+1){
                                        background: rgba(255, 255, 255, 0.3);
                                    }
                                    td{
                                      padding: 0;
                                      margin: 0;
                                      height:28px;
                                      line-height: 28px;
                                      background: none;
                                        border: none !important;
                                        font-size: 12px;
                                        &:nth-child(1){
                                          text-align: left !important;
                                          padding-left: 20px;
                                        }
                                    }
                                }
                              }
                            }
                        }
                    }
                }
            }
          }
    }
}
// .ant-modal-mask{
//   background: none;
// }
// .ant-modal {
//   position: absolute !important;
//   padding: 0px !important;
//   top: 0px !important;
//   left: 0px !important;
//   bottom: 0px !important;
//   right: 0px !important;
//   margin: auto !important;

//   .ant-modal-content {
//     border-radius: 0px;
//     background: #203248; 
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     .modalactionbutton{
//       position: absolute;
//       top: 10px;
//       right: 15px;
//       height: 26px;
//       button{
//         height: 26px;
//         padding: 0 10px !important;
//         margin-left: 6px;
//         font-size: 12px;
//         color: #fff;
//         background: none;
//         &:hover, &:nth-child(1){
//           background: #1890ff;
//           color: #Fff;
//           border: 1px solid #1890ff;
//         }
//       }
//     }
//     .ant-modal-close {
//       right: 10px;
//       display: none;
//       .ant-modal-close-x {
//         height: 36px;
//         width: 36px;
//         line-height: 36px;
//       }
//     }


//     .ant-modal-header {
//       height: 44px;
//       padding: 0 20px;
//       border: none;
//       background:rgba(0, 0, 0, 0.8);
//       margin: 0px;

//       .ant-modal-title {
//         height: 100%;
//         color: #fff;
//         font-size: 14px;
//         line-height: 44px;
//         font-weight: bolder;
//       }
//     }

//     .ant-modal-body {
//       padding: 20px !important;
//       flex: 1;
//       .modalboxcon {
//         overflow-y: auto;
//         height: 100%;
//         overflow-x: hidden;
//         .modalboxcontitle {
//           display: flex;
//           height: 40px !important;
//           align-items: center;
//           font-size: 16px;
//           font-weight: 900;
//           margin: 0 0 10px 0 !important;
//           padding: 0px 0 0 20px !important;

//           .title {
//             padding-left: 10px;
//             height: 18px;
//             line-height: 18px;
//             border-left: 2px solid #0066ea;

//             .codenum {
//               color: #1aa7f9;
//               padding-left: 10px;
//             }
//           }
//         }
//         .ant-form-explain{
//           // display: none !important;
//         }
//         .formneccessary {
//           // background: url('../../../assets/require.png') no-repeat 8px 4px;
//         }
//         .ant-form-item-required::before{
//           float: left;
//         }
//         .datadictionaryform{
//           margin:  0 auto;
//           width: 490px;
//           height: 100%;
//           >.ant-row{
//             height: 50px;
//             margin: 0px;
//             display: flex;
//             width: 490px;
//             .ant-col-24{
//               width: 240px;
//               &:nth-child(1){
//                 margin-right: 10px;
//               }
//               .ant-form-item{
//                 width: 240px;
//                 display: flex;
//                 .ant-form-item-label{
//                   width: 80px;
//                   label{
//                     color: #fff;
//                     font-size: 12px;
//                   }
//                 }
//                 .ant-form-item-control-wrapper{
//                   flex: 1;
//                   .ant-input{
//                     width: 140px;
//                     background: none;
//                     color: #fff;
//                     height: 24px;
//                     line-height: 24px;
//                     font-size: 12px;
//                   }
//                   .ant-radio-group{
//                     display: flex;
//                     .ant-radio-wrapper{
//                       .ant-radio{
//                       }
//                       span{
//                         color: #fff;
//                         font-size: 12px;
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .ant-modal-footer {
//       height: 54px;
//       text-align: center;

//       button {
//         margin: 0 12px;
//         font-size: 12px;
//       }
//     }
//   }
// }

//drawer弹出框
.Drawercommon{
  
  .ant-drawer-content-wrapper{
    width: 420px !important;
    .ant-drawer-content{
      background:rgba(32, 50, 72, 0.95) !important;
      box-shadow: -3px 0px 9px 0px 
        rgba(0, 0, 0, 0.5);
      .ant-drawer-header{
        background: none;
        height: 55px;
        .ant-drawer-title{
          color: #fff;
          font-size: 14px;
        }
        .ant-drawer-close{
          color: #fff;
        }
      }
      .ant-drawer-body{
        padding: 0px !important;
        .Drawercommonaddedit{
          padding: 20px 10px 0 20px;
          width: 100%;
          position: relative;
          //可视化基本信息 
          .menuimg{
            width:130px;
            height: 130px;
            .image{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .avatar-uploader{
                width: 100%;
                height: 100%;
                .ant-upload{
                  width: 100%;
                  height: 100%;
                  background: none;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }
          .formbutton{
            position: absolute;
            bottom: 10px !important;
            left: 45%;
          }
          .ant-radio-wrapper{
            padding: 0px !important;
          }
        }
      }
    }
  }
}