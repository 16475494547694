.fourpage{
    .datatitlename{
        height: 16px;
        margin: 20px 0 0 25px;
        padding-left: 10px;
        line-height: 16px;
        width: 100%;
        font-weight: 900;
        border-left: 3px solid #32c5d2;
        color: #fff;
        font-size: 14px;
    }
    .fourpagetop{
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .totaldata{
            margin-right: 20px;
            height: 100%;
            background: rgba(0,0,0,0.3);
            .totaldatacon{
                display: flex;
                justify-content: center;
                align-items: center;
                .ant-progress{
                    .ant-progress-inner{
                        width: 130px ;
                        height: 130px ;
                    }
                    .ant-progress-text{
                        color: #fff;
                    }
                }
                .totaldatacontext{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: 40px;
                    .eletricitysended{
                        width: 122px;
                        text-align: center;
                        height: 24px;
                        font-family: MicrosoftYaHei-Bold;
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        letter-spacing: 0px;
                        color: #ffffff;
                    }
                    .showele{
                        font-size: 14px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top:10px; 
                        color: #fff;
                        h3{
                            line-height: 26px;
                            font-size: 15px;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
        .dayelectricity{
            height: 100%;
            background: rgba(0,0,0,0.3);
            #dayelectricitydraw{
                >div {
                    canvas{
                        // left: 120px !important;
                    }
                }
            }
        }
    }
    .fourpagebot{
        padding-top: 5px;
        background: rgba(0,0,0,0.3);
        position: relative;
        .linetypesele{
            width: 140px;
            height: 24px;
            position: absolute;
            top: 15px;
            right: 40px;
            .ant-select{
              width: 100%;
              height: 24px;
              // padding: 8px 0;
              .ant-select-selection{
                width: 100%;
                border-radius: 5px;
                height: 24px;
                background: none !important;
                color: #fff;
                padding-left: 24px;
                .ant-select-arrow{
                  color: #fff !important;
                }
                .ant-select-selection__rendered{
                  width: 100%;
                  height: 24px;
                  border-radius: 5px;
                  .ant-select-selection-selected-value{
                    width: 100%;
                    height: 24px !important;
                    line-height: 24px !important;
                  }
                }
              }
            }
        }
        #elecompare{
        }
    }
}