.docmanage{
  height: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  display: flex;
  .viewfile{
    position: absolute;
    z-index: 100;
    width: 99%;
    background: rgba(0, 0, 0, 0.8);
    top: 0.5%;
    left: 0.5%;
    height: 99%;
    .pg-viewer-wrapper{
      width: 100% !important;
      height: 100% !important;
      .pg-viewer{
        width: 100% !important;
        height: 100% !important;
        #pg-photo-container{
          width: 100% !important;
          height: 100% !important;
          img{
            min-width: 300px;
            min-height: 300px;
          }
        }
      }
    }
    .closeviewfile{
      position: absolute;
      cursor: pointer;
      z-index: 101;
      width: 50px;
      height: 28px;
      right: 1%;
      top: 1%;
      button{
        background: #00b7ee;
        color: #fff;
        font-size: 12px;
        border: none;
      }
    }
  }
  .h2title{
    width: 100%;
    height: 34px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    line-height: 34px;
    padding-left: 15px;
    padding-right: 10px;
    color: #fff;
  }
  .addtreebtn{
    float:right;
    font-size:10px;
    display: block;
    width: 28px;
    color: #00b7ee;
    cursor: pointer;
  }
  .actionall{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    border-bottom: 1px solid #fff;
    .actionleft{
      display: flex;
      color: #fff;
      align-items: center;
      .listsearchcondition{
        width: 80px;
        display: block;
        font-size: 13px;
      }
      .anticon{
        color: #fff;
      }
      .ant-input{
        height: 28px;
        line-height: 28px;
        background: none;
        color: #fff !important;
      }
    }
    .actionright{
      // margin-left: 20px;
      button{
        margin-left: 10px;
        height: 26px;
        font-size: 12px;
        color: #fff;
        background: none;
        border: #fff solid 1px;
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:nth-child(1){
          border: #00b7ee solid 1px;
          background: #00b7ee;
        }
      }
    }
  }
  .docmanagetree{
    width: 20%;
    height: 100%;
    .ant-tree{
      height: 100%;
      overflow: auto;
      font-size: 12px !important; 
      color: #fff !important;
      padding:10px 0 0 10px;
      .anticon{
        margin-top:6px !important;
      }
      .ant-tree-node-content-wrapper{
        &:hover{
          .ant-tree-title{
            color: #021a27 !important;
          }
        }
      }
      >li{
        padding: 0 !important;
        .ant-tree-node-content-wrapper{
          padding: 0 !important;
          color: #fff !important;
        }
        .ant-tree-title{
          padding-right: 10px !important;
        }
        .ant-tree-switcher{
          width: 20px !important;
        }
        >.ant-tree-child-tree{
          >li{
            padding: 0 !important;
            >.ant-tree-child-tree{
              >li{
                padding: 0 !important;
                >.ant-tree-child-tree{
                  >li{
                    padding: 0 !important;
                    >.ant-tree-child-tree{
                      >li{
                        padding: 0 !important;
                        >.ant-tree-child-tree{
                          >li{
                            padding: 0 !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .docmanagelist{
    width: 80%;
    height: 100%;
    border-left: 1px solid #fff;
  }
}

//右键菜单
.rightcontextMenu{
  height:98px;
  padding: 2px;
  width: 90px;
  position: absolute;
  background: #fff;
  z-index: 2;
  color: #000;
  border: #556994 solid 2px;
  .contextMenu-option{
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding-left: 5px;
    border-bottom: 1px solid #285486;
    &:last-child{
      border: none;
    }
    &:hover{
      background: #285486;
      color: #fff;
      cursor: pointer;
    }
  }
}
.rightcontextMenuleaf{
  height: 34px !important;
  padding: 0px !important;
  .contextMenu-option{
    line-height: 30px;
    height: 30px;
  }
}
.Drawercommon{
  .docuploadtooltipcon{
    display: none;
  }
  .ant-drawer-content-wrapper{
    width: 400px !important;
  }
  .uploaddocument{
    height: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    button{
      width: 80px !important;
      background: none;
      color: #fff;
      height: 30px;
      padding: 0px !important;
    }
    .docuploadtooltip{
      position: absolute;
      top: 0px;
      left: 62%;
    }
    .docuploadtooltipcon{
      display: none;
      position: absolute;
      top: 0px;
      left: 70%;
    }
    .selectpicture{
      height: 90%;
      .ant-upload{
        display: flex;
      }
      .ant-upload-list{
        margin-top: 20px;
        height:90%;
        overflow: auto;
        .anticon{
          color: #fff;
        }
        .ant-upload-list-item-name{
          color: #fff;
        }
        .ant-upload-list-item{
          &:hover{
            .anticon{
              color: #000;
            }
            .ant-upload-list-item-name{
              color: #000;
            }
          }
        }
      }
    }
  }
}
.ant-btn[disabled]{
  background: #ccc !important;
  border: 1px #ccc solid !important;
  &:hover{
    background: #ccc !important;
    border: 1px solid #ccc !important;
    color: #fff;
  }
}
// 文档版本压缩按钮
.verzipbutton{
  height: 20px;
  padding: 0 12px;
  line-height: 18px;
  // background: none;
  // border: 1px solid #fff;
  background: #00a0e9;
  border: 1px solid #00a0e9;
  color: #fff !important;
  font-size: 12px;
  &:hover{
    background: #00a0e9;
    border: 1px solid #00a0e9;
  }
}
.docmanagecon{
  display: flex;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  .react-resizable-handle{
    top: 0 !important;
    background-size:15px 15px !important;
    background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
  }
  .listactiondisable{
    button{
      background: #ccc !important;
      border: 1px solid #ccc !important;
    }
  }
  .listaction{
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    .listactionl{
      display: flex;
    }
    .listactionr{
      display: flex;
    }
    .searchbtn{
      padding: 0 30px;
    }
    button{
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      font-size: 12px;
      margin: 0 4px;
      color: #fff;
      background: none;
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:nth-child(1){
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
    }
  }
  .docmanagetitle{
    height: 30px;
    line-height: 30px;
    color: #fff;
    padding-left: 10px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    span{
      font-size: 12px;
      color: #f00;
    }
  }
  .docmanageleft{
    height: 100%;
    overflow: hidden;
    min-width: 80px;
    border-right: 1px solid #bcccd1;
    display: flex;
    flex-direction: column;
    .docmanagemodule{
      padding: 0 2px;
      flex: 1;
      >div{
        margin: 1px 0;
        border: 1px solid #bcccd1;
      }
      .doctitle{
        height: 36px;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid #589fb1;
        color: #fff;
        font-size: 12px;
        padding-left: 10px;
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .doctitle-active{
        background: #00b7ee !important;
      }
      .doctree{
        display: none;
        overflow: auto;
      }
      .doctree-active{
        display: block !important;
      }
    }
  }
  .docmanageright{
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    .docmanagetitle{
      width: 100%;
    }
    //文档检索
    .docsearch{
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      z-index: 10;    
      background: linear-gradient(to bottom, #3f689b 0%, #a6abaf 100%) !important;
      h2{
        height: 30px;
        color: #fff;
        font-size: 14px;
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        line-height: 30px;
        // text-align: center;
        padding-left: 14px;
        img{
          position: absolute;
          right: 20px;
          top: 8px;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
      .docsearchcondition{
        padding-left: 10px;
        padding-top: 10px;
        display: flex;
        table{
          tr{
            height: 36px !important;
            td{
              height: 36px !important;
              line-height: 36px;
              color: #fff;
              font-size: 14px;
              img{
                width: 14px;
                height: 14px;
                margin-left: 5px;
                cursor: pointer;
              }
              &:nth-child(1), &:nth-child(3){
                width: 100px;
                padding-left: 20px;
              }
              .ant-select{
                width: 200px;
                height: 26px;
                .ant-select-selection{
                  background: none;
                  height: 26px;
                  .ant-select-selection__rendered{
                    color: #fff;
                    height: 26px;
                    font-size: 12px;
                    line-height: 26px;
                  }
                  .anticon{
                    color: #fff;
                  }
                }
              }
              .ant-input{
                height: 26px;
                font-size: 12px;
                line-height: 26px;
                color: #fff;
                width: 200px;
                background: none;
              }
              .ant-calendar-picker{
                height: 26px;
                width: 260px;
                .ant-calendar-picker-input{
                  height: 26px;
                  font-size: 12px;
                  line-height: 26px;
                  width: 100%;
                  padding: 0px;
                  .ant-calendar-range-picker-separator{
                    color: #fff;
                  }
                  input{
                    text-align: left !important;
                    padding-left: 10px;
                  }
                  .anticon{
                    color: #fff;
                  }
                }
              }
              button{
                margin-left: 30px;
                background: #00b7ee;
                color: #fff;
                font-size: 12px;
                height: 26px;
                line-height: 26px;
                border: none;
                width: 80px;
                &:nth-child(2){
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .searchdoctree {
          height: 250px;
          margin-left: 20px;
          display: none;
          overflow: auto;
          padding: 0 20px 0 0;
        }
      }
      .docsearchlist{
        margin-top: 10px;
      }
    }
    
    //文档预览
    .toviewfile{
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      z-index: 15;
      background: rgba(0, 0, 0, 0.5);
      overflow: auto;
      .pg-viewer-wrapper{
        height: 100%;
        width: 100%;
        .pg-viewer{
          >div{
            width: 100% !important;
            padding-right: 4%;
            height: 100% !important;
          }
          .document-container{
            width: 98% !important;
            margin: 0% 1%;
            height: 100%;
            background: none !important;
            color: #fff;
            overflow: auto;
          }
          .photo-viewer-container{
            img{
              max-width: 98%;
              max-height: 98%;
              margin: 1%;
              min-width: 80%;
              min-height: 80%;
              width: auto;
              height: auto;
            }
          }
          .pdf-viewer-container{
            width: 98% !important;
            margin: 0% 1%;
            .pdf-canvas{
              display: flex;
              justify-content: center;
            }
          }
          .spreadsheet-viewer{
            .react-grid-Container{
              width: 100% !important;
            }
          }
        }
        .unsupported-message{
          background: none !important;
          p{
            color: #fff;
          }
        }
      }
      .tocloseviewfile{
        position: fixed;
        right: 20px;
        top: 70px;
        display: block;
        img{
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      #swiplun{
        cursor: move;
      }
    }

    
    .listcon{
      display: flex;
      position: absolute;
      width: 100%;
      top: 70px;
      left: 0px;
      .react-resizable-handle{
        top: 0 !important;
        background-size:15px 15px !important;
        background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
      }
      .listdata{
        height: 100%;
        display: flex;
        border-right: 1px solid #bcccd1;
        .listpage{
          width: 100%;
        }
      }
      .listinfo{
        flex: 1;
        min-width: 280px;
        display: none;
        height: 100%;
        margin-left: 2px;
        position: relative;
        .tabscloseattr{
          z-index: 100;
          position: absolute;
          right: 10px;
          top: 10px;
          width:15px;
          cursor: pointer;
          height: 15px;
          img{
            width:15px;
            height: 15px;
          }
        }
      }
    }
    .docmanagetabs{
      width: 100%;
      .ant-tabs-bar{
        height: 33px !important;
        margin: 0;
        height: 100%;
        padding:0;
        border-bottom: 1px solid #589fb1;
        .ant-tabs-extra-content{
          display: none;
        }
        .ant-tabs-tab{
          width: 84px !important;
          border-left: 1px solid #fff;
        }
        .ant-tabs-nav-container{
          height: 100%;
        }
        .ant-tabs-tab-prev,.ant-tabs-tab-next{
          height:32px;
          background:#203b5a !important;
          color: #fff;
          .ant-tabs-tab-prev-icon,.ant-tabs-tab-next-icon{
            top: 52%;
          }
          .anticon{
            font-size: 16px;
          }
        }
        .ant-tabs-nav-wrap{
          .ant-tabs-nav-scroll{
            .ant-tabs-tab-active{
              background:#00a0e9 !important;
            }
            .ant-tabs-tab{
              text-align: center;
              margin: 0;
              padding: 0 14px;
              height: 32px;
              line-height: 32px;
              background-color:#2b5179 ;
              border-radius: 2px;
              color: #fff;
              .anticon-close{
                color: #fff;
                margin-top: 2px;
              }
              &:hover{
                background:#00a0e9 !important;
              }
            }
          }
        }
      }
      .ant-tabs-content{
        table{
          width: 98%;
          tr{
            td{
              color: #fff;
              font-size: 12px;
              height: 34px;
              line-height: 34px;
              padding: 0 5px;
              &:nth-child(1){
                width: 80px;
                padding-left: 10px;
              }
              .ant-input{
                background: none;
                min-width: 100px;
                color: #fff;
              }
            }
          }
        }
        .textcontent{
          display: flex;
          height: 100%;
          flex-direction: column;
          .addtext{
            height: 166px;
            width: 99%;
            >h2{
              height: 36px;
              line-height: 36px;
              display: flex;
              justify-content: space-between;
              color: #fff;
              padding: 0 5px;
              button{
                height: 26px;
                line-height: 26px;
                margin: 0 2px;
              }
            }
            textarea{
              background: none;
              color: #fff;
            }
          }
          .textlist{
            overflow: auto;
            width: 99%;
            flex: 1;
            border: #bcccd1 solid 1px;
            padding: 5px 5px 10px 5px;
            li{
              padding: 10px 5px 20px 5px;
              &:nth-child(2n+1){
                background: #589fb1;
              }
              .textlisttitle{
                border-bottom: 1px solid #bcccd1;
                >span{
                  display: block; 
                }
                .author{
                  padding: 0 2px;
                }
                .publishtime{
                  padding: 0 2px;
                  font-size: 12px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 2px;
                  span{
                    img{
                      width: 16px;
                      height: 16px;
                      margin-left: 5px;
                      cursor: pointer;
                    }
                  }
                }
              }
              span{
                color: #fff;
              }
              p{
                color: #fff;
                text-indent: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
.ant-drawer{
  .ant-drawer-content{
    .ant-drawer-body{
      .ant-progress{
        .ant-progress-text{
          color: #fff;
        }
      }
    }
  }
}
.favoritemodal{
  width: 500px !important;
  height: 520px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin:auto !important;
  .ant-modal-content{
    width: 500px !important;
    height: 500px !important;
    .doctree{
      height: 100%;
      overflow: auto;
    }
    .anticon {
      color: #fff;
    }
    background: rgba(97, 93, 93, 0.4);
    .ant-modal-header{
      background: rgba(65, 62, 62, 0.6);
      .ant-modal-title{
        font-size: 14px;
        color: #fff;
      }
    }
    .ant-modal-body{
      height: 390px !important;
      .doctree {
        // height: 390px !important;
        overflow: auto;
        ul{
          li{
            .anticon {
              color: #fff;
            }
            .ant-tree-node-selected{
              background: #00b7ee !important;
              .ant-tree-title{
                color: #000 !important;
              }
            }
            .ant-tree-node-content-wrapper{
              &:hover{
                .ant-tree-title{
                  color: #000 !important;
                }
              }
            }
            .ant-tree-title{
              font-size: 12px !important;
              color: #fff;
            }
          }
        }
      }
    }
    .ant-modal-footer{
      button{
        font-size: 12px;
      }
    }
  }
}