.WisdomTwoticket ,.historytickets{
  width: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  // .ant-input-disabled{
  //   background: none !important;
  //   border: none;
  // }
  // .ant-select-disabled .ant-select-selection{
  //   background: none !important;
  //   border: none;
  // }
  // .ant-select-disabled .anticon {
  //   display: none;
  // }
  // #PermittedWorkTime .anticon{
  //   display: none;
  // }
  .action{
    .disabledbutton{
      background: #ccc !important;
      border: none !important;
      cursor: default;
    }
  }
  .ant-form{
    .ant-row{
      min-height: 40px !important;
      margin: 0 !important;
    }
    .textarea{
      height: 60px !important;
    }
    .textarea4{
      height:105px !important;
    }
    >.ant-row{
      display: flex;
      width: 900px;
      label{
        color: #fff;
      }
      .ant-col-12{
        width: 900px;
        .ant-form-item-control-wrapper{
          width: 780px !important;
        }
      }
      .ant-col-36{
        width: 300px;
        .ant-form-item-control-wrapper{
          width: 200px !important;
        }
      }
      .ant-col-24{
        width: 480px;
        .ant-form-item-control-wrapper{
          width: 390px !important;
        }
      }
      .ant-col-36,.ant-col-12,.ant-col-24{
        .ant-form-item{
          display: flex;
          min-height: 40px;
          width: 100%;
          .ant-form-item-label{
            width: 120px !important;
            padding: 0 !important;
          }
        }
        .ant-form-item-control-wrapper{
          
          .ant-form-item-children{
            >span{
              display: flex;
              color: rgb(216, 207, 207);
              align-items: center;
              font-size: 11px;
              .formspec{
                display: block;
                width: 100px;
              }
            }
          }
          .ant-time-picker {
            width: 100%;
            .ant-time-picker-input{
              background: none ;
              color: #fff;
            }
            .ant-time-picker-clock-icon{
              color: #fff !important;
            }
          }
          #PermittedWorkTime , #EndTime,#FinalTime{
            .ant-calendar-picker-input{
              padding-left: 10px !important;
            }
          }
          .ant-calendar-picker{
            height: 30px !important;
            width: 100% !important;
            >div{
              width: 100% !important;
            }
            .ant-calendar-picker-input{
              height: 30px;
              display: flex;
              background: none;
              padding: 0px 2px 0 0;
              font-size: 14px;
              .ant-calendar-range-picker-input{
                background: none;
                color: #fff;
                width: 160px;
              }
              .ant-calendar-range-picker-separator{
                color: #fff;
              }
            }
            .ant-calendar-picker-icon{
              color: #fff;
            }
          }
          .ant-select{
            width: 100%;
            font-size: 14px;
            .anticon{
              color: #fff;
            }
            .ant-select-search__field{
              width: 0;
              height: 0;
            }
            .ant-select-selection--multiple{
              width: 100%;
              min-height:28px !important;
              line-height: 28px;
            }
            .ant-select-selection--multiple .ant-select-selection__choice{
              color: #fff;
              background:none !important;
              border:none;
              display: flex;
              align-items: center;
            }
            .ant-select-selection{
              width: 100%;
              background: none;
              color: #fff;
              .ant-select-selection__rendered{
                min-height: 28px;
                ul{
                  li{
                    margin: 0 !important;
                    height: 25px;
                    line-height: 28px;
                  }
                }
              }
            }
          }
          input {
            height: 30px;
            width: 100%;
            background: none;
            color: #fff !important;
            font-size: 14px;
          }
          textarea{
            width: 100%;
            font-size: 14px;
            background: none;
            color: #fff !important;
          }
        }
      }
      .ant-col-12{
        .ant-form-item{
          .ant-form-item-label{
            width: 108px !important;
          }
        }
      }
      .ant-col-24{
        .ant-form-item{
          .ant-form-item-label{
            width: 118px !important;
          }
        }
      }
    }
  }
  //标题
  .h2title{
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff;
    height: 34px;
    font-size: 12px;
    line-height: 34px;
    padding-left:16px;
    .action{
      button{
        height: 24px;
        background: #00b7ee;
        margin-right: 10px;
        font-size: 12px;
        color: #fff;
        border: none !important;
      }
    }
  }
  .action{
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    padding:0 10px;
    font-size: 13px;
    .conditions{
      width: 460px;
      display: flex;
      justify-content: space-around;
      >span{
        display: block;
        &:nth-child(1),&:nth-child(3){
          width: 30%;
        }
        &:nth-child(2){
          width: 20%;
        }
        &:nth-child(4){
          width: 14%;
        }
        .anticon{
          color: #fff;
        }
        .ant-select{
          width: 100%;
          height: 28px;
          line-height: 28px;
          .ant-select-selection{
            background: none;
            height: 28px;
            line-height: 28px;
            color: #fff;
          }
        }
        .ant-input{
          width: 100%;
          height: 28px;
          line-height: 28px;
          color: #fff;
          background: none;
        }
      }
    }
    button{
      margin-left: 10px;
      height: 28px;
      background: none;
      color: #fff;
      >span{
        font-size: 10px !important;
      }
      &:nth-child(1){
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
      &:hover{
        background: #00b7ee;
        border: #00b7ee solid 1px;
      }
    }
    .conditionsearch{
      display: flex;
      align-items: center;
      .moreconditions{
        width:12px;
        display: block;
        cursor: pointer;
        margin: 2px 5px 0 10px !important;
      }
      button{
        margin-left: 10px;
        height: 28px;
        background: none;
        color: #fff;
        >span{
          font-size: 10px !important;
        }
        &:nth-of-type(1){
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
        &:hover{
          background: #00b7ee;
          border: #00b7ee solid 1px;
        }
      }
    }
  }
  .listoperation{
    display: flex;
    height: 26px;
    justify-content: center;
    span{
      display: block;
      width: 16px;
      height: 16px;
      margin: 0px 5px !important;
      img{
        width: 14px;
        height: 14px;
        position: relative !important;
      }
    }
  }
}
.showhide{
  width: 450px;
  display: none;
  position: absolute;
  top:82px;
  z-index: 100;
  left: 15px;
  height: 250px;
  background: #2e4e74;
  .searchconditionm{
    .searchconditionmcon{
      table{
        tr{
          td{
            border: 1px solid #fff;
            height: 24px !important;
            padding: 0 !important;
            .ant-select{
              width: 100% !important;
              .ant-select-selection{
                border: none;
              }
            }
            .ant-input{
              width: 100% !important;
              border: none;
            }
            &:nth-child(4){
              text-align: center;
              button{
                height: 22px;
                background: #00b7ee;
                color: #fff;
                font-size: 12px;
                padding: 0 8px;
              }
            }
          }
        }
      }
    }
  }
}

//历史票
.historytickets{
  display: none;
}

//编辑工作票
.editworkticket{
  position: relative;
  display: none;
  height: 100%;
  width: 100%;
  position: relative;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  .selekksimg{
    cursor: pointer;
    position: absolute;
    right: -3px;
  }
  //选择设备
  .devicename{
    width: 774px;
    height: 95px;
    border: 1px solid rgb(250, 242, 242);
    border-radius: 5px;
    cursor: pointer;
    overflow: auto;
    ul{
      padding: 0 4px;
      li{
        float: left;
        height: 34px;
        line-height: 32px;
        padding: 0 4px;
        color: #fff;
        span{
          &:nth-child(2){
            color: #cdd;
            margin-left: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }


  // .selectdelstyle{
  //   .ant-select-selection{
  //     border: none;
  //     background: none;
  //     .ant-select-arrow{
  //       display: none;
  //     }
  //     .ant-select-selection__rendered{
  //       line-height: 34px;
  //       .ant-select-selection-selected-value{
  //         color: #fff; 
  //       }
  //     }
  //   }
  // }
  // .datepickerdelstyle{
  //   .ant-calendar-picker-input{
  //     border: none;
  //   }
  //   .anticon-calendar{
  //     display: none !important;
  //   }
  //   .ant-calendar-picker-clear{
  //     display: none !important;
  //   }
  //   &:hover{
  //     .anticon-calendar{
  //       display: none !important;
  //     }
  //     .ant-calendar-picker-clear{
  //       display: none !important;
  //     }
  //   }
  // }
  // .inputdelstyle{

  // }
  // 滚动列表
  .listscroll{
    height: 340px;
    .ant-table-wrapper{
      height: 100%;
      .ant-spin-nested-loading{
        height: 100%;
        .ant-spin-container{
          height: 100%;
          .ant-table{
            height: 100%;
          }
        }
      }
      .ant-table{
        border: none ;
          .ant-table-content{
              .ant-table-scroll{
                  .ant-table-header{
                    margin-bottom: 0px !important;
                      .ant-table-thead{
                        tr{
                            th{
                                background: rgba(0, 0, 0, 0.1) !important;
                                border: 1px solid #fff;
                            }
                        }
                      }
                  }
                  .ant-table-body{
                    border-top: 1px solid #fff;
                    margin-top: 0px !important;
                  }
              }
          }
      }
    }
    .ant-table-row .ant-table-selection-column>span{
      display: flex !important;
      height: 26px !important;
      justify-content: center;
      align-items: center;
    }
  }
  //工具栏
  .fixedtools{
    position: fixed;
    bottom: 15px;
    height: 32px;
    // left: 40%;
    display: flex;
    justify-content:center;
    z-index: 101;
    width: 100%;
    // background: rgba(0, 0, 0, 0.5);
    >.fixedtool{
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: 70px;
      height: 32px;
      cursor: pointer;
      justify-content: center;
      &:hover{
        background: cornflowerblue;
      }
      span{
        margin: 0 4px;
      }
    }
    >.fixedtoolnone{
      &:hover{
        background: none;
      }
    }
  }
  .action{
    padding: 0 !important;
  }
  .divselectsstyle{
    border: none !important;
  }
  .h3titleselectstyle{
    box-shadow: 0px 3px 2px 0px 
		rgba(9, 1, 3, 0.4);
  }
  >div{
    border-bottom:1px solid rgb(219, 216, 216);
    >div{
      padding: 10px 20px;
    }
    &:last-child{
      border: none;
    }
  }
  .h3title{
    height: 34px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 0 20px;
    align-items: center;
    font-size: 14px;
    // box-shadow: 0px 0px 1px 1px #fff;
    >span{
      display: block;
      &:nth-child(2){
        display: flex;
        align-items: center;
        .workticketaction{
          display: flex;
          height: 22px;
          margin-right: 5px;
          align-items: center;
          justify-content: flex-end;
          button{
            height: 22px;
            font-size: 12px;
            margin: 0 5px;
            color: #fff;
            background: none;
            &:nth-child(1), &:hover{
              background:cornflowerblue;
              border: 1px solid cornflowerblue;
            }
          }
        }
        >span{
          cursor: pointer;
        }
      }
    }
  }
  //
  .readsystemtime{
    display: block;
    color: cornsilk;
    height: 40px;
    line-height: 30px;
    font-size: 12px;
  }
  //热力机械工作票
  .workticketseditcon ,.workticketauditcon ,.workticketchangecon{
    padding: 10px 40px;
  }
  //总结
  .summary{
    .workendingcon{
      .workendingconfooter{
        padding-left: 10px;
        table{
          tr{
            height: 40px;
            line-height: 40px;
            td{
              font-size: 14px;
              color: #fff;
              .anticon{
                color: #fff;
              }
              .ant-calendar-picker{
                display: block;
                height: 30px !important;
                width: 220px !important;
                >div{
                  width: 220px !important;
                  display: flex;
                  justify-content: space-between;
                }
              }
              input{
                width: 220px !important;
                height: 28px;
                background: none;
                color: #fff;
              }
              &:nth-child(1){
                width: 90px;
              }
              &:nth-child(2){
                width: 230px;
                font-size: 12px;
              }
              &:nth-child(3){
                width: 300px;
              }
              .suretime{
                display:flex;
                height: 40px;
                align-items: center;
                >span{
                  &:nth-child(1){
                    display: block;
                    width: 80px !important;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  //终结
  .workending{
  }
  //安全措施
  .runningmessures , .runningpermessures{
    .footer{
      width:100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      table{
        width: 500px;
        td{
          &:nth-child(2n+1){
            width: 100px;
          }
          &:nth-child(2n){
            width: 150px;
          }
          height: 28px;
          text-align: center;
          line-height: 28px;
          color: #fff;
          font-size: 12px;
          border: 1px #fff solid;
        }
      }
    }
  }
}
//下一个处理人弹框
.nextdealier{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 200px;
  background-color: #314966;
  z-index: 101;
  .h2title{
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    padding: 0 20px !important;
    .dragwindowclose{
      font-size: 16px;
      cursor: pointer;
    }
  }
  .nextdealiercon{
    height: 60px !important;
    .ant-form{
      margin: 20px 0 0 50px;
      .ant-row{
        .ant-form-item{
          .ant-form-item-label{
            width: 160px !important;
          }
          .ant-form-item-control-wrapper{
            width: 250px !important;
            .ant-calendar-picker-input{
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .seletosave{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      font-size: 12px;
      margin: 0 10px;
      color: #fff;
      background: none;
      &:nth-child(1), &:hover{
        background:#2f5d91;
      }
    }
  }
}
//试运

//弹框
.WisdomTwoticketdrag{
  position: absolute;
  // display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 800px;
  height: 460px;
  background-color: #314966;
  z-index: 101;
  .h2title{
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    padding: 0 20px !important;
    cursor: auto !important;
    .dragwindowclose{
      font-size: 16px;
      cursor: pointer;
    }
  }
  .listscroll{
    margin: 2px 2px 0 2px;
  }
  //流程详情
  .twoticketdetail{
    width: 100%;
    height: 460px;
    .listscroll{
      margin: 10px 20px 0 20px;
    }
  }
  //新建工作票
  .createworkticket{
    .workticketsele{
      margin-top: 50px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      button{
        background:#2f5d91;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  //从模板库选择
  .seletwotickettype{
    .toseletype{
      margin: 60px 100px 0 140px;
      height: 220px;
      table{
        tr{
          height: 50px;
          line-height: 50px;
          td{
            color: #fff;
            font-size: 14px;
            .anticon{
              color: #fff;
            }
            .ant-select{
              width: 100%;
              .ant-select-selection{
                background: none;
                color: #fff;
              }
            }
            &:nth-child(1){
              width: 140px;
            }
            &:nth-child(2){
              width: 240px;
            }
          }
        }
      }
    }
  }
  .tryrun{
    .tryruncon{
      height: 250px;
      margin:20px 0 40px  80px;
      .ant-form{
        width:500px !important;
        .ant-row{
          .ant-form-item{
            .ant-form-item-label{
              width: 160px !important;
            }
            .ant-form-item-control-wrapper{
              width: 250px !important;
              .ant-calendar-picker-input{
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  //选择设备
  .toseleequip{
    width: 100%;
    .toseleequipcon{
      display: flex;
      height: 420px;
      width: 100%;
      .h2title{
        background: none !important;
        height: 28px !important;
        line-height: 28px !important;
        margin: 0;
        font-weight: 900;
        border-bottom: 1px solid #fff;
        margin-bottom: 6px;
      }
      .toseleequipconl{
        width: 40%;
        border-right:1px snow solid ;
        padding: 0 5px 0 10px;
        .toseleequipconlsearch{
          display: flex;
          height: 30px;
          align-items: center;
          .ant-input-search{
            .anticon{
              color: #fff;
            }
            .ant-input{
              height: 28px;
              line-height: 28px;
              background: none;
              color: #fff;
            }
          }
          button{
            background: #00b7ee;
            color: #fff;
            margin-left: 10px;
            border: none;
            height: 28px;
            font-size: 12px;
          }
        }
        .kkstree{
          height: 340px;
          overflow: auto;
          margin: 5px 0 0 10px;
        }
      }
      .toseleequipconr{
        width: 60%;
        padding: 0 5px;
        .action{
          height: 30px;
          padding: 0 !important;
          margin: 0 !important;
        }
        .listscroll{
          margin-top: 5px;
        }
      }
    }
  }
  .toselenextdutier{
    .toseletype{
      margin: 60px 100px 0 140px;
      height: 220px;
      table{
        tr{
          height: 50px;
          line-height: 50px;
          td{
            color: #fff;
            font-size: 14px;
            .anticon{
              color: #fff;
            }
            .ant-select{
              width: 100%;
              .ant-select-selection{
                background: none;
                color: #fff;
              }
            }
            &:nth-child(1){
              width: 140px;
            }
            &:nth-child(2){
              width: 240px;
            }
          }
        }
      }
    }
    .seletosave{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        font-size: 12px;
        margin: 0 10px;
        color: #fff;
        background: none;
        &:nth-child(1), &:hover{
          background:#2f5d91;
        }
      }
    }
  }
  .seletosave{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      font-size: 12px;
      margin: 0 10px;
      color: #fff;
      background: none;
      &:nth-child(1), &:hover{
        background:#2f5d91;
      }
    }
  }
  //安全措施
  .runningmessure{
    color: #fff;
    font-size: 14px;
    .runningmessurecon{
      width: 80%;
      margin: 50px 10% 120px 10%;
      display: flex;
      .ant-input{
        background: none;
        width: 80%;
        color: #fff;
        font-size: 12px;
      }
    }
  }
  
}
.ant-dropdown{
  .workticketdropdown{
    left: 96px !important;
    .ant-checkbox-group{
      display: flex;
      flex-direction: column;
      label{
        margin: 0 4px !important;
      }
    }
  }
}