.fivepage{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px !important;
  .fivepagecon{
    width: 100%;
    height: 100%;
    padding: 20px ;
    background: rgba(0, 0, 0, 0.3);
    position: relative;
    .datatitlename{
      height: 16px;
      padding-left: 10px;
      line-height: 16px;
      width: 100%;
      font-weight: 900;
      border-left: 3px solid #32c5d2;
      color: #fff;
      font-size: 14px;
    }
    .linetypesele{
      width: 140px;
      height: 24px;
      position: absolute;
      top: 50px;
      right: 120px;
      .ant-select{
        width: 100%;
        height: 24px;
        // padding: 8px 0;
        .ant-select-selection{
          width: 100%;
          border-radius: 5px;
          height: 24px;
          background: none !important;
          color: #fff;
          padding-left: 30px;
          .ant-select-arrow{
            color: #fff !important;
          }
          .ant-select-selection__rendered{
            width: 100%;
            height: 24px;
            border-radius: 5px;
            .ant-select-selection-selected-value{
              width: 100%;
              height: 24px !important;
              line-height: 24px !important;
            }
          }
        }
      }
    }
    #capacity{
      margin-top: 50px !important;
      width: 100%;
    }
  }
}