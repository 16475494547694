
    //智慧诊断
    .conditioninspection{
        width: 100% !important;
        height: 100%;
        position: relative;
        background:url('../../assets/img/background2.jpg') no-repeat center !important;
        background-size: 100% 100% !important;
        //缩放
        .treeshoufang{
          display: flex;
          position: absolute;
          top: 20px ;
          left: 20px;
          span{
            cursor: pointer;
            display: none;
            font-size: 14px;
            width:80px;
            text-align: center;
            line-height: 36px;
            height: 36px;
            background: rgba(0,0,0,0.5);
            border-radius: 5px;
            color: #fff;
            margin-right: 10px;
          }
        }
        
        .loadprogress{
          display: none;
          position: absolute;
          left: 50%;
          top: 40%;
          width: 40%;
          z-index: 900;
          .ant-progress-text{
            color: #fff;
          }
        } 
        .wisdomdiagnosisleftcenter{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 100;
          display: flex;
          width: 420px;
          .react-resizable-handle{
            top: 0 !important;
            background-size:15px 15px !important;
            background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
          }
          .wisdomdiagnosisleft{
            padding: 0 20px 20px 20px;
            background: rgba(0,0,0,0.5);
            >h2{
              color:#fff;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 15px;
              font-weight: 900;
              .zhuangpeishushousuo{
                cursor: pointer;
                width: 6px;
                height: 12px;
                display: block;
                background: url('../../assets/img/线箭头.png') no-repeat center;
                background-size: 100% 100%;
              }
            }
            .wisdomdiagnosislefttree{
              overflow: auto;
              .ant-tree-treenode-switcher-close{
                padding-left: 10px !important;
                background: url('../../assets/image/个人工作台.png') no-repeat 10px center !important;
                background-size: 10px 10px !important;
              }
            }
          }
          .wisdomdiagnosiscenter{
            padding:0 20px;
            background: rgba(0,0,0,0.3);
            >h2{
              color:#fff;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 15px;
              font-weight: 900;
              .right{
                display: flex;
                height: 50px;
                width: 32px;
                align-items: center;
                justify-content: space-between;
                .right2{
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                  display: block;
                  img{
                    width: 18px;
                    height: 18px;
                  }
                }
                .kksshousuo{
                  cursor: pointer;
                  margin-left: 5px;
                  width: 6px;
                  height: 12px;
                  display: block;
                  background: url('../../assets/img/线箭头.png') no-repeat center;
                  background-size: 100% 100%;
                }
              }
            }
            .cediansearch{
              width: 100%;
              height: 36px !important;
              .ant-input-search{
                height: 28px !important;
                .ant-input{
                  height: 28px !important;
                  background: none !important;
                  color: #fff;
                }
              }
              .ant-input-suffix{
                .ant-input-search-icon{
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
      .cepointechartstuline{
        display: none;
        width: 800px;
        position: absolute;
        left:32%;
        top: 10%;
        height: 420px;
        cursor:move;
        z-index: 5000;
        h2{
          height: 40px;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #fff;
          padding: 0 5px 0 20px;
          background: #002b3b;
          span{
            height: 40px;
            font-size: 16px;
            text-align: center;
            display: block;
            width: 40px;
            line-height: 40px;
            cursor: pointer;
          }
        }
        #echartstulinecedian {
          background: rgba(0, 0, 0, 0.7);
          height: 380px !important;
          padding-top: 40px;
        }
        #echartstulinecedianother{
          display: none;
          color: #Fff;
          text-align: center;
          background: rgba(0, 0, 0, 0.7);
          height: 380px !important;
          padding-top: 40px;
          line-height: 60px;

        }
        .linebtn{
          top: 58px;
          right: 20px;
          position: absolute;
          z-index: 90000;
          button{
            font-size: 12px;
            color: #fff;
            margin-left: 14px;
            background: none;
            cursor: pointer;
          }
        }
      }
      .labelingDiv{
        .labelTitle{
          color: #Fff !important;
        }
        >div{
          color: #Fff !important;
        }
        
      }