.rolemanage{
    display: flex;
    padding:0 15px;
    .listsearch{
        height: 50px;
        // background-color: #004986;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        .listsearchl{
            color: #fff;
            font-size: 14px;
            .ant-input-search{
                height: 26px;
                width: 156px;
                .ant-input{
                    background: none;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    width: 156px;
                }
                .anticon {
                    color: #fff;
                }
            }
        }
        .listactionr{
            button{
                font-size: 12px;
                height: 26px;
                line-height: 24px;
                background-color: #1aa7f9 !important;
                border-radius: 5px;
                color: #fff;
                border: none;
            }
        }
    }
    
    .listpage{
      
        .ant-pagination {
          padding: 0 20px;
          position: absolute;
          height: 35px;
          bottom: 0px;
          margin: 0px !important;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: rgba(0,0,0,0.6);
          .ant-pagination-simple-pager{
            border: none !important;
          }
          .ant-pagination-item-active {
            background: none;
            a{
              color: #1890ff !important;
            }
          }

          .ant-pagination-total-text{
            margin-right: 10px !important;
          } 
          .ant-pagination-options{
            margin-left: 10px !important;
          }
          li {
            &:nth-child(1), &:last-child{
              border: none !important;
            }
            .ant-pagination-item-ellipsis {
              color: #Fff;
            }

            color: #FFF;

            a {
              color: #FFF;
            }
          }

          input {
            background: none;
            color: #Fff;
          }
        }
        margin: 0px !important;
        padding: 0px !important;
        background: rgba(0, 0, 0, 0.1);
        .listoperationa{
            a{
              color: #60c8fd !important;
                margin-left: 5px;
            }
        }
        .ant-table-wrapper{
            height: 100%;
            .ant-spin-nested-loading{
              height: 100%;
              .ant-spin-container{
                height: 100%;
                .ant-table{
                  height: 100%;
                }
              }
            }
            .ant-table{
              border: none ;
                .ant-table-content{
                    .ant-table-body{
                        margin: 0px;
                        .ant-table-thead{
                          height: 30px;
                          tr{
                            border: 1px solid #ccc;
                              th{
                                padding: 0;
                                margin: 0;
                                  height: 30px;
                                  line-height: 30px;
                                  color: #fff !important;  
                                  font-size: 13px;
                                  text-align: center;
                                  font-weight: 900;
                                  border-left: 1px solid #ccc;
                                  background: rgba(0, 0, 0, 0.3) !important;
                              }
                          }
                        }
                        .ant-table-tbody{
                            background: none;
                            color: #fff !important;
                            margin-bottom: 0px !important;
                            table{
                              height: 100%;
                              .ant-table-tbody{
                                height: 100%;
                                .ant-table-row-expand-icon{
                                  background: none !important;
                                }
                                .clickRowStyl{
                                  background: #449bab !important;
                                } 
                                tr{
                                  &:hover{
                                    background: #449bab !important;
                                    cursor: pointer;
                                  }
                                }
                                .ant-table-row{
                                    border: none;
                                    background: none;
                                    &:nth-child(2n+1){
                                        background: rgba(255, 255, 255, 0.3);
                                    }
                                    td{
                                      padding: 0;
                                      margin: 0;
                                      height:28px;
                                      line-height: 28px;
                                      background: none;
                                        border: none !important;
                                        font-size: 12px;
                                        &:nth-child(1){
                                          text-align: left !important;
                                          padding-left: 20px;
                                        }
                                    }
                                }
                              }
                            }
                        }
                    }
                }
            }
          }
    }
    .rolemanagel{
        width:40%;
    }
    .rolemanagec{
        width:30%;
        margin: 0 10px;
    }
    .rolemanager{
        width:30%;
    }
}
