.regularwork{
  display: flex;
  background:linear-gradient(to bottom, #194475 0%, #838A8F 100%) !important;
  .ant-tabs {
    width: 100%;
    height: 100%;
    .ant-tabs-bar{
      .ant-tabs-nav-wrap{
        width:160px !important;
        background: rgba(0, 0, 0, 0.3) !important;
      }
      .ant-tabs-tab{
        width: 124px;
        padding: 0 !important;
        height: 26px;
        margin:20px 18px 0 18px !important;
        border: 1px solid #ccc;
        color: #fff;
        font-size: 12px;
        line-height: 25px;
        text-align: center;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.1) !important;
      }
      .ant-tabs-tab-active{
        background: rgb(68, 152, 231) !important;
      }
    }
    .ant-tabs-content{
      padding: 0 !important;
      .regularworkrightsearch{
        height: 50px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ant-input-search{
          height: 30px !important;
          width: 270px !important;
          .ant-input{
            color: #fff;
            height: 30px !important;
            background: rgba(255, 255, 255, 0.1) !important;
          }
          .ant-input-search-icon{
            color: #fff;
          }
        }
        .ant-btn{
          background: #00b7ee;
          width: 56px;
          height: 28px;
          padding: 0px !important;
          border: #00b7ee solid 1px;
          >span{
            display: block;
            width: 56px;
            height: 28px;
            font-size: 12px;
            line-height: 24px;
            color: #fff;
          }
        }
        .regularworkright2action{
          display:flex;
          height: 32px;
          align-items: center;
          button{
            width: 56px;
            height: 24px;
            font-size: 12px;
            margin-left: 10px;
            border-radius: 5px;
            background: none;
            color: #feffff;
            border: solid 1px #fff;
            &:first-child{
              background-color: #00b7ee;
              border: solid 1px #00b7ee;
            }
            &:hover{
              background-color: #00b7ee;
            }
          }
        }
      }
      .regularworkright1{
        width: 100% !important;
        padding:0 10px 10px 10px;
      }
      .regularworkright2{
        width: 100% !important;
        padding: 10px;
        .tableheader{
          height: 30px;
          width: 100%;
          tr{
            th{
              text-align: center;
              color: #fff;
              border: 1px solid #fff;
              line-height: 29px;
              background: rgba(0, 0, 0, 0.3) !important;
              &:nth-child(1){
                width: 8%
              }
              &:nth-child(2){
                width: 30%
              }
              &:nth-child(3){
                width: 26%
              }
              &:nth-child(4){
                width: 15%
              }
              &:nth-child(5){
                width: 18%
              }
            }
          }
        }
        .listscroll{
          .ant-table-body{
            margin-top:0px !important;
          }
        }
        .ant-collapse{
          margin-top: 4px;
          background: none;
          border: none;
          .ant-collapse-item-active{
            .ant-collapse-header{
              color: #fff;
              border: 1px solid #ccc !important;
              .anticon{
                margin: 0 !important;
              }
            }
          }
          .ant-collapse-item{
            border: none;
            margin: 2px 0;
            .ant-collapse-header{
              // border: 1px solid rgb(192, 190, 190);
              color: #fff;
              height:30px;
              line-height: 30px;
              font-size: 12px;
              padding:0px 40px;
              border: 1px solid #888;
              .anticon{
                margin: 0 !important;
              }
            }
            .ant-collapse-content{
              border: none !important;
              background: none;
              .ant-collapse-content-box{
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}
//定期工作类型添加
.worktypeadd{
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  margin: auto !important;
  width: 400px !important;
  height: 200px;
  .ant-modal-content{
    background: #2f5d91;
    .anticon{
      color: #fff;
    }
    .ant-modal-header{
      background: none !important;
      .ant-modal-title{
        color:  #fff;
        font-size: 14px;
      }
    }
    .ant-modal-footer{
      button{
        font-size: 12px;
        &:nth-child(1){
          background: none !important;
          color:  #ffffff;
        }
      }
    }
  }
  .worktypeaddp{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff ;
    font-size: 12px;
    .addworktypeadd{
      width:220px;
      font-size: 12px;
      background: none;
      color:  #fff;
    }
  }
}



.regularwork{
  .regularworkrightsearch{
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-input-search{
      height: 30px !important;
      width: 270px !important;
      .ant-input{
        color: #fff;
        height: 30px !important;
        background: rgba(255, 255, 255, 0.1) !important;
      }
      .ant-input-search-icon{
        color: #fff;
      }
    }
    .ant-btn{
      background: #00b7ee;
      width: 56px;
      height: 28px;
      padding: 0px !important;
      border: #00b7ee solid 1px;
      >span{
        display: block;
        width: 56px;
        height: 28px;
        font-size: 12px;
        line-height: 24px;
        color: #fff;
      }
    }
    .regularworkconfigaction{
      display:flex;
      height: 32px;
      align-items: center;
      button{
        width: 56px;
        height: 24px;
        font-size: 12px;
        margin-left: 10px;
        border-radius: 5px;
        background: none;
        color: #feffff;
        border: solid 1px #fff;
        &:first-child{
          background-color: #00b7ee;
          border: solid 1px #00b7ee;
        }
        &:hover{
          background-color: #00b7ee;
        }
      }
    }
  }
  //定期工作列表
  .regularworklist{
    width: 100%;
    .listpage{
      padding: 0px !important;
    }
  }
  //定期工作配置
  .regularworkconfig{
    width: 100% !important;
    padding: 0 10px;
    .regularworkrightsearch{
      padding: 0 !important;
    }
    .tableheader{
      height: 30px;
      width: 100%;
      tr{
        th{
          text-align: center;
          color: #fff;
          border: 1px solid #fff;
          line-height: 29px;
          font-size: 14px;
          background: rgba(0, 0, 0, 0.3) !important;
          &:nth-child(1){
            width: 8%
          }
          &:nth-child(2){
            width: 30%
          }
          &:nth-child(3){
            width: 26%
          }
          &:nth-child(4){
            width: 15%
          }
          &:nth-child(5){
            width: 18%
          }
        }
      }
    }
    .listscroll{
      .ant-table-body{
        margin-top:0px !important;
      }
    }
    .ant-collapse{
      margin-top: 4px;
      background: none;
      border: none;
      .ant-collapse-item-active{
        .ant-collapse-header{
          color: #fff;
          border: 1px solid #ccc !important;
          .anticon{
            margin: 0 !important;
          }
        }
      }
      .ant-collapse-item{
        border: none;
        margin: 2px 0;
        .ant-collapse-header{
          // border: 1px solid rgb(192, 190, 190);
          color: #fff;
          height:30px;
          line-height: 30px;
          font-size: 12px;
          padding:0px 40px;
          border: 1px solid #888;
          .anticon{
            margin: 0 !important;
          }
        }
        .ant-collapse-content{
          border: none !important;
          background: none;
          .ant-collapse-content-box{
            padding: 0px;
          }
        }
      }
    }
  }
}