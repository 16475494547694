.wisdomdiagnosismodelconfig{
  position: relative;
  display: flex;
  background:linear-gradient(to bottom, #2d76a7 0%, rgb(153, 166, 175) 100%) !important;
  //简图添加上传
  .tuupload{
    display: none;
    z-index: 90000;
    background: #1d5a79;
    border: solid 1px #2d76a7;
    cursor: move;
    position: absolute !important;
    width: 500px;
    left: 35%;
    top: 35%;
    height: 300px;
    padding: 30px 10px;
    .jiantunameipt{
      height: 28px;
    }
    .closetuupload{
      position: absolute;
      cursor: pointer !important;
      right: 12px;
      top: 12px;
      color: #fff;
    }
    .FileName{
      height: 40px;
      align-items: center;
      display: flex;
      color:#fff;
      font-size: 14px;
      justify-content: center;
      input{
        background: none;
        width: 180px !important;
        margin-left: 20px;
        color: #fff;
      }
    }
    button{
      font-size: 12px;
      background: none;
      color: #ffff;
      height: 28px;
      width: 80px;
      padding: 0 !important;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      >span{
        text-align: center;
        line-height: 18px;
        display: block;
        height: 20px;
      }
    }
    .upfilelist{
      height: 180px;
      width: 100%;
      .selectpicture{
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-upload-list{
          height: 130px;
          display: flex;
          flex-direction: column;
          overflow: auto;
          align-items: center;
          .ant-upload-list-item{
            margin: 0 !important;
            &:hover{
              .ant-upload-list-item-info{
                 background: none !important;
              }
             
            }
            .anticon{
              color:#fff;
            }
          }
          .ant-upload-list-item-info{
            color: #fff;
          }
        }
        button{
          width: 80px !important;
          background: #53b9ec !important;
          span{
            display: block;
            width: 80px !important;
            margin: 0;
            text-align: center;
          }
        }
      }
    }
    .action{
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .h2title{
    height: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    padding-left: 20px;
    font-weight: 900;
  }
  .wisdomdiagnosismodelconfigleft{
    width: 15%;
    .h2title{
      background: rgba(0, 0, 0, 0.5);
    }
    .wisdomdiagnosismodelconfigtree{
      background: rgba(0, 0, 0, 0.3);
      .treeaction{
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        button{
          font-size: 12px;
          background: none;
          color: #ffff;
          height: 20px;
          width: 50px;
          padding: 0 !important;
          margin-right: 10px;
          &:hover{
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          &:nth-child(1){
            background: #53b9ec;
            border: 1px solid #53b9ec;
          }
          >span{
            line-height: 18px;
            display: block;
            height: 20px;
            width: 50px;
          }
        }
      }
      .list{
        padding:10px !important;
      }
    }
  }
  
  .gongkuang{
    display: flex;
    justify-content: space-around;
    width: 100%;
    h2{
      margin-top: 2px;
      height: 30px;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.5) !important;
      color: #fff;
      font-size: 12px;
      padding: 0 10px;
      justify-content: space-between;
      span{
          button{
          font-size: 12px;
          background: none;
          color: #ffff;
          height: 20px;
          width: 50px;
          padding: 0 !important;
          margin-right: 10px;
          &:hover{
              background: #53b9ec;
              border: 1px solid #53b9ec;
          }
          &:nth-child(1){
              background: #53b9ec;
              border: 1px solid #53b9ec;
          }
          &:last-child{
            margin: 0 !important;
          }
          >span{
              line-height: 18px;
              display: block;
              height: 20px;
              width: 50px;
          }
          }
      }
    }
    .listleft{
      width: 40.5%;
    }
    .listright{
      width: 59%;
    }
  }
  .wisdomdiagnosismodelconfigright{
    width: 85%;
    position: relative;
    display: flex;
    .listpage{
      .ant-pagination{
        li{
          &:nth-child(1){
            margin-right: 10px !important;
            border: none !important;
            color: #fff;
          }
        }
      }
    }
    //公用
    .wisdomdiagnosismodelconfigrightcon{
      position: relative;

    }
    .h2title{
      background: rgba(0, 0, 0, 0.3);
    }
    .ant-tabs{
      width: 100%;
      .ant-tabs-bar{
        width: 100%;
        margin: 0 !important;
        height: 30px;
        line-height: 30px;
        background: rgba(0, 0, 0, 0.3) !important;
        border: none;
        .ant-tabs-nav{
          height: 30px;
          line-height: 30px;
          >div{
            .ant-tabs-tab{
              height: 30px;
              padding: 0 24px;
              line-height: 30px;
              background: none;
              border:none;
              border-radius: 0;
              color: #fff;
              font-size: 12px;
              &:nth-child(2){
                border-left: 1px solid #ccc;
                border-right:  1px solid #ccc;
                border-radius: 0;
              }
              &:nth-child(3){
                border-right:  1px solid #ccc;
                border-radius: 0;
              }
              &:nth-child(4){
                border-right:  1px solid #ccc;
                border-radius: 0;
              }
            }
            .ant-tabs-tab-active{
              border-radius: 0;
              background:#07344d; 
              color: rgb(99, 230, 223) !important;
            }
          }
        }
      }
      .ant-tabs-content{
        .yuanlitu{
          width: 100%;
          position: relative;
          background: rgba(0, 0, 0, 0.3);
          .yuanliimg{
            overflow: auto !important;
            #swiptu{
              cursor: move;
            }
          }           
          .action{       
            button{
              font-size: 12px;
              background: none;
              color: #ffff;
              height: 28px;
              width: 50px;
              padding:0 10 !important;
              margin-right: 10px;
              &:hover{
                background: #53b9ec;
                border: none;
              }
              &:nth-child(1){
                background: #53b9ec;
                border: none !important;
              }
              >span{
                line-height: 18px;
                display: block;
                height: 20px;
                width: 50px;
              }
            }
          }
        }
        .modelconfigrightcon1{
          position: relative;
          padding:2px;
          .action{
            position: fixed;
            left: 50%;
            bottom: 20px !important;
            display: flex;
            align-items: center;
            .selectpicture{
              display: flex;
              .ant-upload-list{
                display: flex;
                align-items: center;
                .ant-upload-list-item{
                  margin: 0 !important;
                  &:hover{
                    .ant-upload-list-item-info{
                       background: none !important;
                    }
                   
                  }
                  .anticon{
                    color:#fff;
                  }
                }
                .ant-upload-list-item-info{
                  color: #fff;
                }
              }
              button{
                width: 80px !important;
                background: #53b9ec;
                border: none;
                display: flex !important;
                >span{
                  width: 80px !important;
                  text-align: center;
                }
              }
            }
            .uploadpicture{
              width: 80px !important;
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                width: 30px !important;
                margin-left: 10px !important;
              }
            }
            button{
              font-size: 12px;
              background: none;
              color: #fff;
              height: 28px;
              padding:0 10 !important;
              padding: 0 !important;
              margin-right: 10px;
              &:hover{
                background: #53b9ec;
                border: 1px solid #53b9ec;
              }
              &:nth-child(1){
                background: #53b9ec;
                border: 1px solid #53b9ec;
              }
              >span{
                line-height: 18px;
                display: block;
                height: 20px;
                width: 50px;
              }
            }
          }
        }
        .modelconfigrightcon3{
          display: flex;
          .left{
            width: 20%;
            .kksshousuo{
              display: block;
              height: 16px;
              width: 8px !important;
              // padding-right: 60px !important;
              cursor: pointer;
              background: url('../../assets/img/线箭头.png') no-repeat right center;
              background-size: 100% 100%;
            }
            >h2{
              height: 30px;
              display: flex;
              align-items: center;
              background: rgba(0, 0, 0, 0.5) !important;
              color: #fff;
              font-size: 12px;
              padding: 0 5px;
              justify-content: space-between;
              span{
                button{
                  font-size: 12px;
                  background: none;
                  color: #ffff;
                  height: 20px;
                  width: 50px;
                  padding: 0 !important;
                  margin-right: 2px;
                  &:hover{
                    background: #53b9ec;
                    border: 1px solid #53b9ec;
                  }
                  &:nth-child(1){
                    background: #53b9ec;
                    border: 1px solid #53b9ec;
                  }
                  >span{
                    line-height: 18px;
                    display: block;
                    height: 20px;
                    width: 50px;
                  }
                }

              }
            }
            .leftlist{
              background: rgba(0, 0, 0, 0.2);
              padding: 2px;
            }
          }
          .right{
            width: 80%;
            position: relative;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            padding: 10px;
            .rightcon{
              width: 100%;
              height: 100%;
              overflow: auto !important;
              background: rgba(0, 0, 0, 0.3);
              #swiplun{
                cursor: move;
              }
            }
          }
        }
      }
    }
  }
}
.Drawercommon{
  .Drawercommonaddedit{
    .selectpicture{
      .ant-upload{
        button{
          background: #53b9ec !important;
          color:  #fff;
        }
      }
      .ant-upload-list{
        .ant-upload-list-item{
          &:hover{
            .ant-upload-list-item-info{
              background: #53b9ec !important;
            }
          }
          .anticon{
            color:  #fff;
          }
          .ant-upload-list-item-info{
            &:hover{
              background: #53b9ec !important;
            }
            .ant-upload-list-item-name{
              color:  #fff;
            }
          }
        }
      }
    }
  }
}