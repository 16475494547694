//智慧诊断
.wisdomdiagnosis{
  width: 100% !important;
  height: 100%;
  position: relative;
  background:url('../../assets/img/background2.jpg') no-repeat center !important;
  background-size: 100% 100% !important;
  .ant-table-placeholder{
    border: none !important;
    color: #Fff;
  }
  .ant-table-small{
    border: none !important;
  }
  .treeshoufang{
    display: flex;
    position: absolute;
    top: 20px ;
    left: 20px;
    span{
      cursor: pointer;
      display: none;
      font-size: 14px;
      width:80px;
      text-align: center;
      line-height: 36px;
      height: 36px;
      background: rgba(0,0,0,0.5);
      border-radius: 5px;
      color: #fff;
      margin-right: 10px;
      z-index: 1000000;
    }
  }
  .wisdomdiagnosisleftcenter{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2000;
    width: 420px;
    .react-resizable-handle{
      top: 0 !important;
      background: url('../../assets/imgs/lrarrow.PNG') no-repeat center center;
      background-size:15px 15px !important;
    }
    .wisdomdiagnosisleft{
      padding: 0 20px 20px 20px;
      background: rgba(0,0,0,0.5);
      .zhuangpeishu{
        color:#fff;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 900;
        .right{
          display: flex;
          align-items: center;
          img{
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
        .zhuangpeishushousuo{
          cursor: pointer;
          width: 6px;
          margin-left: 8px;
          height: 12px;
          display: block;
          background: url('../../assets/img/线箭头.png') no-repeat center;
          background-size: 100% 100%;
        }
      }
      .wisdomdiagnosislefttree{
        overflow: auto;
        .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
          .ant-tree-title{
            color: #fff !important;
            // background: #00a0e9;
          }
        }
        .ant-tree-treenode-switcher-close{
          padding-left:5px !important;
          background: url('../../assets/image/个人工作台.png') no-repeat 10px center !important;
          background-size: 10px 10px !important;
        }
      }
    }
    .wisdomdiagnosiscenter{
      padding:0 20px;
      background: rgba(0,0,0,0.3);
      >h2{
        color:#fff;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 900;
        .right{
          display: flex;
          height: 50px;
          width: 40px;
          align-items: center;
          justify-content: flex-end;
          .right1, .right2{
            width: 18px;
            height: 18px;
            cursor: pointer;
            display: block;
            img{
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
          }
          .kksshousuo{
            cursor: pointer;
            margin-left: 10px;
            width: 6px;
            height: 12px;
            display: block;
            background: url('../../assets/img/线箭头.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
      .cediansearch{
        width: 100%;
        height: 36px !important;
        .ant-input-search{
          height: 28px !important;
          .ant-input{
            height: 28px !important;
            background: none !important;
            color: #fff;
          }
        }
        .ant-input-suffix{
          .ant-input-search-icon{
            color: #fff !important;
          }
        }
      }
    }
  }

  .wisdomdiagnosiscon{
    position: relative;
    .ant-tabs-bottom-bar{
      width: 40% !important;
      position: absolute;
      bottom: 0px;
      margin: 0 !important;
      height: 80px;
      left: 33%;
      border: none !important;
      .ant-tabs-nav-container{
        height: 80px;
        .ant-tabs-nav-wrap{
          height: 80px;
          display: flex;
          justify-content: center;
          .ant-tabs-nav{
            // width: 100%;
            background: rgba(255,255,255,0.3);
            box-shadow: 6px 4px 3px 0px 
              rgba(0, 0, 0, 0.32);
            border-radius: 5px 5px 0px 0px;
            height: 80px;
            >div{
              height: 100%;
              display: flex;
              justify-content: center;
              .ant-tabs-tab{
                padding: 10px 20px !important;
                margin: 0 !important;
                img{
                  width: 50px !important;
                  height: 50px !important;
                }
              }
            }
            .ant-tabs-ink-bar{
              display: none !important;
            }
          }
        }
      }
      .wisdomdiagnosiscontent{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        align-items: center;
        color: #555;
      }
      .ant-tabs-nav .ant-tabs-tab-active .wisdomdiagnosiscontent{
        // color: #6ea0e2;
        font-weight: 900;
      }
    }
    .ant-tabs-bottom-content{
      .errorrecording{
        margin-left: 31.5%;
        width: 68%;
        font-size: 14px;
        // border: solid 1px #00a0e9;
        .errorrecordingsearch{
          height: 40px;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          font-size: 14px;
          justify-content: space-between;
          padding: 0 20px;
          .searchcondition{
            >span{
              margin-right:50px;
            }
          }
          .ant-select-selection{
            height: 28px;
            background:none;
            background: rgba(255, 255, 255, 0.1);
          }
          .ant-select-arrow{
            color: #fff;
          }
          .ant-select-selection-selected-value{
            color: #fff;
          }
          .ant-input{
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
            height: 28px;
          }
          .ant-calendar-picker-icon{
            color: #fff;
          }
          .searchbutton{
            width: 16px;
            height: 16px;
            cursor: pointer;
            img{
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .Principledata{
        position: relative;
        margin-left: 31.5%;
        width: 68%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        background: #075578;
        .datacasetable{
          width: 80%;
          margin: 20px 0;
          border: #fff solid 1px;
          tr{
            td{
              border: #fff solid 1px;
              width:20%;
              text-align: center;
              height: 30px !important;
              line-height: 30px !important;
              font-size: 12px;
              color: #fff;
            }
          }
        }
        .diagramshowframe{
        }
      }
      .moduleload{
        height: 100%;
        width: 100%;
        .DataKanban{
          display: none;
          position: absolute;
          z-index: 15;
          right:0.2%;
          top: 2px;
          width: 42%;
          // border: solid 1px #00a0e9;
          .DataKanbansearch{
            font-size: 14px;
            height: 40px;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            button{
              background: none;
              color: #fff;
              height: 30px;
              margin-left: 10px;
              &:hover{
                background: #0075a9;
              }
            }
            .ant-select-selection{
              height: 28px;
              background:none;
            }
            .ant-select-arrow{
              color: #fff;
            }
            .ant-select-selection-selected-value{
              color: #fff;
            }
            .ant-input{
              background: rgba(255, 255, 255, 0.1);
              color: #fff;
              height: 28px;
            }
            .ant-input-search-icon{
              color: #fff;
            }
            .ant-calendar-picker-icon{
              color: #fff;
            }
          }
          .list{
            margin: 0 !important;
            padding:2px;
            .ant-table-header{
              margin: 0 !important;
            }
            .ant-table-body{
              margin: 0 !important;
            }
          }
        }
        .equipmentevaluation{
          display: none;
          position: absolute;
          z-index: 15;
          right:0.2%;
          top: 2px;
          width: 42%;
          // border: solid 1px #00a0e9;
          .equipmentevaluationdata{
            width: 100%;
            height: 180px;
            li{
              color: #fff;
              margin-bottom: 20px;
              >h2{
                line-height: 42px;
                text-align: center;
                background: rgba(0, 0, 0, 0.6);
                height: 42px;
                font-size: 14px;
                font-weight: 900;
                color: #fff;
              }
              >div{
                padding: 20px 20px 40px 20px;
                font-size: 12px;
                background: rgba(0, 0, 0, 0.3);
                span{
                  color: #03d8db;
                  font-size: 13px;
                  line-height: 28px;
                  margin-right: 10px;
                }
              }
            }
          }
          .ant-carousel{
            .slick-list{
              .evaluationall{
                h3{
                  height: 100% !important;
                  color: #fff;
                  >span{
                    display: block;
                    line-height: 40px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.6);
                    height: 40px;
                    font-size: 14px;
                    font-weight: 900;
                    color: #fff;
                  }
                  div{
                    p{
                      height: 100% !important;
                      overflow: auto;
                      padding: 20px 20px 40px 20px;
                      font-size: 12px;
                      background: rgba(0, 0, 0, 0.3);
                      span{
                        color: #03d8db;
                        font-size: 13px;
                        line-height: 28px;
                        margin-right: 10px;
                      }
                    }
                  }
                }
              }
            }
            .slick-dots{
              bottom: 24px !important;
              li{
                height: 10px;
                button{
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}