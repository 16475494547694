*{
  margin: 0;
  padding: 0;
}
.login{
  width: 100%;
  height: 100%;
  background: url('./images/dengluditu.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.login .footer{
  position: absolute;
  bottom: 20px;
  color: #fff;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.login .footer a{
  color: #fff;
  margin-left: 6px;
}
.login .footer a:hover{
  color: #40a9ff;
  text-decoration: underline;
}


.login .softname{
  color: #fff;
  width: 400px;
  position: absolute;
  font-size: 28px;
  top: 20%;
  text-align: center;
  height: 50px;
  right:150px;
}
.login .loginbox{
  position: absolute;
  top:200px;
  right:150px;
  width: 400px;
  height: 310px;
}
.login .loginbox > div{
  width: 400px;
  background: rgba(182, 190, 194, 0.35);
  display: flex;
  height: 40px;
  margin-bottom:5px;
}
.login .loginbox div >span{
  display: block;
  width: 60px;
  height: 40px;
  background: url('./images/bgline.gif')  no-repeat right center;
  background-size:2px 26px;
}
.login .loginbox div .ant-select .ant-select-selection{
  height: 40px;
  line-height: 40px;
}
.login .loginbox div input{
  /* background: rgba(182, 190, 194, 0.3); */
  background: none;
  outline:none;
  padding-left:10px ;
  width: 370px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border: none;
  font-size: 16px;
}
.login .loginbox div .sele{
  width: 40px;
  height: 100%;
  margin: 0;
  position: absolute;
  right: 20px;
  background: rgba(182, 190, 194, 0.1) url('./images/dianjisheng.png')  no-repeat right center;
  background-size: 16px 8px ;
  cursor: pointer;
}
input::-webkit-input-placeholder{
  color:#fff;
}
.login .loginbox button{
  cursor: pointer;
  width: 400px;
  height: 40px;
  background: #2a2f45;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}
.login .loginbox .jituan {
  position: relative;
}
.login .loginbox .jituan> span{
  background: url('./images/gongxi.png')   no-repeat center center /20px 20px, 
               url('./images/bgline.gif')   no-repeat right center /2px 26px;
}
.login .loginbox  .comp {
  position: relative;
}
.login .loginbox .comp> span{
  background: url('./images/dizhi.png')   no-repeat center center /18px 20px, 
               url('./images/bgline.gif')   no-repeat right center /2px 26px;
}
.login .loginbox .userid> span{
  background: url('./images/touxiang.png')   no-repeat center center /18px 20px, 
               url('./images/bgline.gif')   no-repeat right center /2px 26px;
}
.login .loginbox .username> span{
  background: url('./images/suo.png')   no-repeat center center /18px 20px, 
               url('./images/bgline.gif')   no-repeat right center /2px 26px;
}
.login .loginbox .select{
  display: none;
  top:40px;
  right:0px;
  position: absolute;
  z-index: 10;
  background: #fafcfb;
  width: 400px;
  max-height: 300px;
  overflow-y: auto;
}
.login .loginbox .select li{
  cursor: pointer;
  list-style: none;
  height: 36px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 36px;
  background: #303753;
  border-bottom: 1px solid rgb(236, 225, 225);
}
#normal_login_userName,#normal_login_password{
  display: flex;
}
.ant-form-item{
  color: #fff;
}
.ant-input-affix-wrapper .ant-input:not(:first-child){
  background: rgba(182, 190, 194, 0.1)
}

.login .loginbox .ant-select .ant-select-selection .ant-select-selection__rendered{
  font-size: 14px !important;
}
.login .loginbox .ant-input , .ant-input-number-input{
  font-size: 14px !important;
}
.login .loginbox input{
  font-size: 14px !important;
}