
.ant-dropdown{
    .workticketdropdown{
      left: 96px !important;
      .ant-checkbox-group{
        display: flex;
        flex-direction: column;
        label{
          margin: 0 4px !important;
        }
      }
    }
}
.ant-select-selection__clear{
  background: none !important;
}
.shippingplan{
    display: flex;
    position: relative;
    .shippingplanleft{
      width: 200px;
      .actionall{
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        padding-left: 16px;
        color:  #fff;
      }
      .tree{
        overflow: auto;
        padding: 0px 2px 10px 8px;
      }
    }
    .shippingplanright{
      border-left: 1px solid rgb(202, 197, 197);
      flex: 1;
      position: relative;
      .moresearchconds{
        position: absolute;
        display: none;
        z-index: 10;
        width: 434px;
        top:40px;
        left: 8px;
        background:rgba(32, 67, 95, 0.8);
        max-height: 180px;
        overflow: auto;
        .moresearchcond{
          padding-left: 2px;
          height: 36px;
          display: flex;
          align-items: center;
        }
        .logicalselect{
          width: 80px !important;
        }
        .ant-select{
            width: 120px;
            height: 28px;
            line-height: 28px;
            margin-right: 6px;
            .anticon{
              color: #fff;
            }
            .ant-select-selection{
              width: 100%;
              font-size: 12px;
              background: none;
              color: #fff;
              height: 28px;
              line-height: 28px;
              .ant-select-selection__rendered{
                height: 28px;
                line-height: 28px;
                ul{
                  li{
                    margin: 0 !important;
                  }
                }
              }
            }
          }
          .ant-input{
            width: 150px;
            height: 28px;
            line-height: 28px;
            margin-right: 6px;
            background: none;
            color: #fff;
          }
          .addrow, .deleterow{
            img{
              width: 22px;
              height: 22px;
              margin-left:2px;
              cursor: pointer;
            }
          }
      }
      .actionall{
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        button{
          height: 28px;
          line-height: 26px;
          font-size: 12px;
          background: none;
          color: #fff;
        }
        .actionleft {
            display: flex;
            align-items: center;
            .ant-select{
                width: 120px;
                height: 28px;
                line-height: 28px;
                margin-right: 6px;
                .anticon{
                  color: #fff;
                }
                .ant-select-selection{
                  width: 100%;
                  font-size: 12px;
                  background: none;
                  color: #fff;
                  height: 28px;
                  line-height: 28px;
                  .ant-select-selection__rendered{
                    height: 28px;
                    line-height: 28px;
                    ul{
                      li{
                        margin: 0 !important;
                      }
                    }
                  }
                }
              }
              .logicalselect{
                width: 80px !important;
              }
              .ant-input{
                width: 150px;
                height: 28px;
                line-height: 28px;
                margin-right: 6px;
                background: none;
                color: #fff;
              }
              img{
                width: 20px;
                height: 20px;
                margin: 0 10px 0 5px;
                cursor: pointer;
              }
              .ant-input-search{
                width: 150px;
                height: 28px;
                line-height: 28px;
                margin-right: 6px;
                .anticon{
                    color: #fff;
                }
                  .ant-input{
                      background: none;
                      font-size: 12px;
                      color: #fff;
                      height: 28px;
                      line-height: 28px;
                      width: 150px;
                  }
              }
          button{
            margin-right: 5px;
          }
        }
        .actionright{
            button{
              margin-left: 5px;
            }
        }
        .actionleft ,.actionright{
          button{
            &:nth-child(1){
              background: #53b9ec;
              border:#53b9ec solid 1px ;
            }
            &:hover{
              background: #53b9ec;
              border:#53b9ec solid 1px ;
            }
          }
        }
      }
      .listpage{
        padding: 2px 0px;
        .ant-checkbox-wrapper{
            padding: 0px !important;
            margin: 0px !important;
        }
        .ant-table-wrapper{
          .ant-table{
            .ant-table-content{
              .ant-table-body{
                .ant-table-thead > tr, .ant-table-tbody > tr{
                  height: 26px !important;
                  td{
                    height: 26px !important;
                  }
                }
              }
            }
          }
        }
      }
      .listmanagetable{

        .iframe{
          width: 100% !important;
          height: 100% !important;
        }


        .ant-table-wrapper{
          .ant-spin-container{
            .ant-table{
              .ant-table-content{
                .ant-table-body{
                  .ant-table-thead{
                  tr{
                    height: 26px !important;
                    th{
                      .anticon-filter{
                        width: 20px !important;
                        height: 26px !important;
                      }
                      &:nth-child(1){
                        width: 60px !important;
                        div{
                          width: 60px !important;

                        }
                      }
                    }
                  }
                  }
                  .ant-table-tbody{
                    
                  tr{
                    height: 26px !important;
                    td{
                      height: 26px !important;
                      &:nth-child(1){
                        width: 60px !important;
                        >span{
                          width: 60px !important;
                        }
                      }
                    }
                    .ant-table-column-has-actions{
                      text-align: left !important;
                      padding-left: 10px !important;
                      .ant-table-row-expand-icon{
                        background: none !important;
                      }
                      .ant-table-row-spaced{
                        width: 0px !important;
                      }
                    }
                  }
                  }
                } 
              }
            }
          }
        }
      }
    }
}
//清单上传
.exportmodule{
  display: none;
  z-index: 100;
  width: 380px;
  height: 250px;
	background-color: #203248;
	box-shadow: 0px 6px 20px 0px 
		rgba(0, 0, 0, 0.4);
	border-radius: 5px;
  h2{
    cursor:move;
    background: rgba(0, 0, 0, 0.5);
    height: 38px;
    padding: 0 10px 0 20px;
    color: #fff;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close{
      display: block;
      cursor: pointer;
      img{
        width:26px;
        height: 26px;
      }
    }
  }
  button{
    font-size: 12px;
  }
  .exportdocument{
    height: 200px;
    padding: 40px 20px 0px 20px; 
    .anticon{
      color: #fff;
    }
    span{
      color: #fff;
      font-size: 14px;
    }
    >div{
      margin-top: 10px;
      .ant-select{
        width: 338px;
        height: 30px;
        line-height: 30px;
        .anticon{
          color: #fff;
        }
        .ant-select-selection{
          height: 30px;
          width: 100%;
          line-height: 30px;
          background: none;
          color: #fff;
          .ant-select-selection__rendered{
            height: 30px;
            line-height: 30px;
            margin: 0 5px !important;
            font-size: 12px;
            .ant-select-selection-selected-value{
              padding-right:16px; 
            }
          }
          .ant-select-arrow{
            right: 5px;
          }
        }
      }
    }
    .operatebtn{
      margin-top: 40px;
      display: flex;
      justify-content: center;
      button{
        margin: 0 40px;
        background: none;
        color:  #fff;
        width: 100px !important;
        height: 36px !important;
        line-height: 36px;
        &:nth-child(1){
          background: #2f5d91;
        }
      }
    }
  }
  .uploaddocbtn{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      margin: 0 10px;
    }
  }
}

//装配模型
.docmentupload1{
  display: block !important;
  z-index: 100;
  width: 600px;
  height: 500px;
  background: #3f658a;
  border:2px solid #5b646b;
  h2{
    cursor:move;
    background: #213342;
    height: 32px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .close{
      display: block;
      cursor: pointer;
      border: #fff solid 1px;
      width: 16px;
      text-align: center;
      line-height: 12px;
      height: 16px;
      border-radius: 50%;
    }
  }
  .assemblysele1{
    height: 50px;
    line-height: 50px;
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #768ea7;
    background: none !important;
    
    .ant-select{
      width: 120px;
      margin-left: 20px;
      height:28px !important;
      .anticon{
        color: #fff;
      }
      .ant-select-selection{
        width: 100%;
        height:28px !important;
        background: none;
        color: #fff;
        .ant-select-selection__rendered{
          height: 28px !important;
          line-height: 26px !important;
        }
      }
    }
  }
  .tree{
    height: 400px;
    padding:10px 20px;
    overflow: auto;
  }
  .assemblymodelsave{
    position: absolute;
    top: 40px;
    right: 20px;
    height: 30px;
    font-size: 12px;
    background: #53b9ec;
    color: #fff;
    border: none;
  }
}
.shippingplanmoduleload{
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6) !important;
  .shippingplanmoduleloadcon{
    #shippingplanmodule{
      width: 100% !important;
    }
  }
  h2{
    height: 40px;
    padding: 0 20px;
    cursor: move;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    button{
      float: right;
      margin-top: 8px;
      background: #00b7ee;
      color: #fff;
      height: 26px;
      padding: 0 12px !important;
      font-size: 12px;
      &:hover{
        background: skyblue;
        color: #fff;
      }
    }
  }
}

.boxmanagemoduleload{
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6) !important;
  .boxmanagemoduleloadcon{
    #boxmanagemodule{
      width: 100% !important;
    }
  }
  h2{
    height: 40px;
    padding: 0 20px;
    cursor: move;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    button{
      float: right;
      margin-top: 8px;
      background: #00b7ee;
      color: #fff;
      height: 26px;
      padding: 0 12px !important;
      font-size: 12px;
      &:hover{
        background: skyblue;
        color: #fff;
      }
    }
  }
}
//日志
.verlistcon{
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0px;
  background: linear-gradient(to bottom, #3f689b 0%, #a6abaf 100%) !important;
  .actionall{
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 15px;
    button{
      height: 28px;
      line-height: 26px;
      font-size: 12px;
      background: none;
      color: #fff;
    }
    .actionleft {
        display: flex;
        color: #fff;
        .logtitle{
          font-size: 14px;
          margin-right: 20px;
        }
        .vertitle{
          font-size: 14px;
          margin-right: 20px;
        }
        font-size: 12px;
        align-items: center;
        .ant-calendar-picker{
          margin-left: 10px;
          height: 28px;
          .ant-calendar-picker-input {
            background: none;
            height: 28px;
            line-height: 28px;
            padding: 0px 10px;
            color: #fff;
            font-size: 12px;
            .ant-calendar-picker-clear{
              background: none !important;
            }
            .anticon , .ant-calendar-range-picker-separator{
              color: #fff;
            }
          }
        }
        img{
          margin-left: 15px;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
    }
    .actionright{
        button{
          margin-left: 5px;
        }
    }
    .actionleft ,.actionright{
      button{
        height: 28px;
        line-height: 26px;
        font-size: 12px !important;
        &:nth-child(1){
          background: #53b9ec;
          border:#53b9ec solid 1px ;
        }
        &:hover{
          background: #53b9ec;
          border:#53b9ec solid 1px ;
        }
      }
    }
  }
}
.listmoduleupload{
  display: flex;
  flex-direction: column;
  align-items: center;
  >p{
    margin: 20px 10px;
    color: #fff;
    display: flex;
    font-size: 12px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .importexcel{
    width: 82px;
    height: 30px;
    position: relative;
    display: flex;
    cursor: pointer !important;
    background: none;
    border: 1px solid #3f658a;
    border-radius: 5px;
    &:hover{
      background: #53b9ec;
      color:  #fff;
    }
    >span{
      position: absolute;
      top: 5px;
      left: 16px;    
      color: #fff;
      font-size: 12px;
    }
    input{
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  >button{
    margin: 30px 0;
    background: none;
    border: 1px solid #3f658a;
    color:  #fff;
    font-size: 12px;
    &:hover{
      background: #53b9ec;
      color:  #fff;
    }
  }
  >span{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
    .ant-upload-list{
      height: 200px !important;
      overflow: auto;
    }
  }
  .ant-upload-list-item{
    &:hover{
      background: rgb(135, 238, 224);
      .ant-upload-list-item-name{
        color: #000;
      }
      .anticon{
        color: #000;
      }
    }
    .ant-upload-list-item-info{
      color: #fff;
      &:hover{
        background: rgb(135, 238, 224);
      }
      .ant-upload-list-item-name{
        font-size: 12px;
        line-height: 20px;
        margin-right: 10px;
      }
    }
  }
  .anticon{
    color: #fff;
  }

  button{
    width: 80px;
    height: 28px !important;
    line-height: 28px;
  }
}