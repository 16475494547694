
.ant-calendar-range .ant-calendar-input, .ant-calendar-range .ant-calendar-time-picker-input{
  color: #Fff !important;
}
.ant-calendar-time-picker-inner{
  background: #285486;
}
.ant-calendar-time-picker-input  {
  color: #Fff !important;
}
.ant-calendar .ant-calendar-date{
  color: #00a0e9;
}
.ant-calendar-time-picker .ant-calendar-time-picker-select ul li{
  background: #285486 !important;
}
.smallindicators{
  width: 100%;
  background: linear-gradient(to bottom, rgb(63, 104, 155) 0%,rgb(166, 171, 175) 100%) !important;
  .ant-tabs{
    width: 100%;
    .ant-tabs-bar{
      width: 100%;
      margin: 0 !important;
      height: 36px;
      line-height: 36px;
      background: rgba(0, 0, 0, 0.5) !important;
      border: none;
      .ant-tabs-nav{
        height: 36px;
        line-height: 36px;
        >div{
          .ant-tabs-tab{
            padding: 0 16px;
            height: 36px;
            line-height: 36px;
            background: none;
            border:none;
            border-radius: 0;
            color: #fff;
            font-size: 12px;
            &:nth-child(2){
              border-left: 1px solid #999;
              border-right:  1px solid #999;
              border-radius: 0;
            }
          }
          .ant-tabs-tab-active{
            border-radius: 0;
            background:#021a27; 
            color: rgb(99, 230, 223) !important;
          }
        }
      }
    }
    .ant-tabs-content{
      color: #fff;
      .h3title{
        background: rgba(0, 0, 0, 0.3) !important;
        color: #fff;
        height: 34px;
        font-size: 12px;
        line-height: 34px;
        padding-left:16px;
        .action{
          float: right;
          height: 34px;
          display: flex;
          align-items: center;
          button{
            height: 22px;
            background: #00b7ee;
            margin-right: 10px;
            font-size: 12px;
            color: #fff;
            border: #00b7ee solid 1px;
          }
        }
      }
      //运行数据
      .runningdata{
        display: flex;
        width: 100%;
        .runningdataleft{
          width: 320px;
          border-right: 1px solid #fff;
          .ant-fullcalendar-cell{
            position: relative;
            .ant-fullcalendar-content{
              bottom: 0px;
            }
          }
          .calenderrili{
            background: rgba(0, 0, 0, 0.3);
            margin: 10px;
            .ant-select-selection{
              background: none !important;
              color: #fff;
            }
            .ant-select-arrow{
              color: #fff;
            }
            .ant-radio-button-wrapper{
              background: none !important;
              color: #fff;
            }
            .ant-radio-button-wrapper{
              background: none !important;
              color: #fff;
            }
            .ant-fullcalendar-column-header-inner{
              color: #fff;
            }
            .ant-fullcalendar-value{
              color: #fff;
              &:hover{
                color: #000;
              }
            }
          }
          .runningsele{
            font-size: 12px;
            padding-left: 10px;
          }
          .runaction{
            width: 100%;
            padding:5px 30px 0 30px;
            display: flex;
            justify-content: space-around;
            button{
              color: #Fff;
              background: none;
              height: 28px;
              width: 110px;
              font-size: 12px;
            }
          }
        }
        .runningdataright{
          width: 100%;
          .runningdatarightlist{
            display: flex;
            .classgroupsele{
              display: flex;
              height: 30px;
              padding-left: 14px;
              align-items: center;
              >span{
                font-size: 12px;
                margin-right: 10px; 
              }
              button{
                color: #fff;
                background: none;
                height: 22px;
                font-size: 12px;
                margin-left: 10px;
              }
            }
            .datasave{
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              button{
                height: 28px;
                background: #00b7ee;
                font-size: 12px;
                color: #fff;
                border: #00b7ee solid 1px;
              }
            }
            .runningdatarightlistl{
              width: 60%;
              border-right: 1px solid #fff;
            }
            .runningdatarightlistr{
              width: 40%;
            }
          }
        }
      }
      //异常设置
      .abnormalconfig{
        display: flex;
        .abnormalconfigleft{
          width: 40%;
          border-right: 1px solid #ccc;
          .searchlistcondition{
            color: #fff;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            height: 40px;
            align-items: center;
            .anticon{
              color: #fff;
            }
            .searchlistconditions{
              width: 33%;
              display: flex;
              align-items: center;
              .conditions{
                width: 32% !important;
                text-align: center;
                display: block;
              }
              .ant-calendar-picker{
                width: 68% !important;
                .ant-calendar-picker-input{
                  width: 100% !important;
                }
              }
              input{
                width: 66% !important;
                height: 26px;
                line-height: 26px !important;
                width: 100px;
                background: none !important;
                color: #fff;
                font-size: 12px;
              }
            }
            .left{
              display: flex;
              width: 90%;
            }
            .right{
              width: 10%;
              display: flex;
              justify-content: flex-end;
              padding-right: 5px;
              button{
                font-size: 12px;
                height: 26px;
                width: 50px;
                color: #Fff;
                background: #00b7ee;
                padding: 0;
                margin: 0;
                >span{
                  display: block;
                  width: 50px;
                }
              }
            }
          }
        }
        .abnormalconfigright{
          width: 60%;
          .ant-form{
            color: #fff;
            .SelectDate{
              .ant-calendar-input {
                color: #Fff !important;
              }
              .ant-calendar-range-picker-separator{
                color: #Fff;
              }
              .ant-form-item{
                .ant-form-item-label{
                  padding-left:3.5% !important;
                  width: 13% !important;
                }
                .ant-form-item-control-wrapper{
                  width: 50% !important;
                }
              }
            }
            .ant-row{
              margin: 0 !important;
              display: flex;
              .ant-col-12{
                width: 50%;
              }
              .ant-form-item{
                min-height: 40px;
                display: flex;
                align-items: center;
                font-size: 14px;
                margin: 0 !important;
                padding: 0 !important;
                width: 100%;
                .ant-form-item-label{
                  width: 25%;
                  padding-left:5%;
                  padding-bottom: 0px !important;
                  label{
                    color: #fff;
                  }
                }
                .ant-form-item-control-wrapper{
                  width: 60%;
                  .ant-form-item-control{
                    width: 100%;
                    .ant-form-item-children{
                      width: 100% !important;
                      display: block;
                      .ant-time-picker {
                        width: 100%;
                      }
                      .ant-calendar-picker{
                        width: 100% !important;
                        >div{
                          width: 100% !important;
                        }
                      }
                    }
                  }
                  //时间选择
                  .ant-time-picker {
                    .ant-time-picker-input{
                      background: none ;
                      color: #fff;
                    }
                    .ant-time-picker-clock-icon{
                      color: #fff !important;
                    }
                  }
                  .ant-calendar-picker{
                    width: 100%;
                    >div{
                      width: 100% !important;
                    }
                    .ant-calendar-picker-icon{
                      color: #fff;
                    }
                  }
                  .ant-select{
                    width: 100%;
                    .anticon{
                      color: #fff;
                    }
                    .ant-select-selection--multiple{
                      width: 100%;
                      min-height:28px !important;
                      line-height: 28px;
                    }
                    .ant-select-selection--multiple .ant-select-selection__choice{
                      color: #fff;
                      background:none !important;
                      border:none;
                    }
                    .ant-select-selection{
                      width: 100%;
                      background: none;
                      color: #fff;
                      .ant-select-selection__rendered{
                        min-height: 28px;
                        ul{
                          li{
                            margin: 0 !important;
                            height: 25px;
                            line-height: 28px;
                          }
                        }
                      }
                    }
                  }
                  .ant-input{
                    height: 28px;
                    width: 100%;
                    background: none;
                    color: #fff !important;
                  }
                  .ant-radio-wrapper{
                    color: #fff;
                  }
                }
              }
            }
            .textipt{
              height: 100px !important;
              .ant-input{
                width: 100%;
                height: 100px !important;
                background: none;
                color: #fff !important;
              }
              .ant-col-24{
                .ant-form-item{
                  width: 100%;
                  display: flex;
                  align-items: center;
                  .ant-form-item-label{
                    width: 12.3%;
                  }
                  .ant-form-item-control-wrapper{
                    width: 80.4% !important;
                  }
                }
              }
            }
            .formbutton{
              width: 100%;
              display: flex;
              justify-content: flex-end;
              padding-right: 9.4%;
              .ant-form-item{
                width: 50px;
              }
              .ant-btn{
                height: 28px !important;
                background: #00b7ee;
                font-size: 12px;
              }
            }
          }
          .cediantu{
            padding-left: 3%;
            margin-top:20px;
            .cediansele{
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              .viewcedian{
                width: 9%;
                display: block;
              }
            }
            .ant-select{
              width: 30%;
              height: 28px;
              margin: 0 10px 0 10px !important;
              .anticon{
                color: #fff;
              }
              .ant-select-selection{
                width: 100%;
                line-height: 28px;
                height: 28px;
                background: none;
                color: #fff;
                .ant-select-selection__rendered{
                  line-height: 28px;
                  height: 28px;
                }
              }
            }
            button{
              height: 28px;
              font-size: 12px;
              background: #00b7ee;
              color: #fff;
              border: none;
            }
          }
        }
      }
      //考核结果
      .examanswer{
        display: flex;
        .examansweraction{
          padding-left: 16px;
          height: 30px;
          display: flex;
          align-items: center;
          button{
            margin-right:10px;
            height: 22px;
            line-height: 20px;
            font-size: 12px;
            color: #Fff;
            background: none;
            display: flex;
            align-items: center;
            &:nth-child(1){
              background: #00b7ee;
            }
            &:hover{
              background: #00b7ee;
            }
          }
        }
        .examanswerleft{
          width: 30%;
          border-right: 1px solid #ccc;
        }
        .examanswerright{
          width: 70%;
        }
      }
    }
  }

}
input::-webkit-input-placeholder { /* WebKit browsers */
  color: #ccc !important;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #ccc !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #ccc !important;
}
.twostatus{
  display: flex;
  justify-content: center;
  height: 18px;
  .ant-badge{
    margin: 0 2px;
    display: flex;
    .ant-badge-status-text{
      display: none;
    }
  }
}
.runningdataleft{
  .ant-badge{
    display:  flex;
    flex-direction:  column;
    justify-content: center;
    align-items: center;
    .ant-badge-status-text{
      height: 14px;
      line-height: 14px;
      color: rgb(187, 162, 162);
      margin: 0 !important;
    }
  }
}

.ant-table .ant-table-placeholder{
  border: none !important;
  color: #fff;
}
.examdetail{
  width: 860px;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 570px !important;
  position: absolute;
  cursor: move;
  z-index:5000;
    h2{
      height: 34px;
      line-height: 34px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #fff;
      padding: 0 5px 0 20px; 
      background: rgba(0, 0, 0,0.9);
      span{
        height: 34px;
        font-size: 16px;
        text-align: center;
        display: block;
        width: 40px;
        line-height: 34px;
        cursor: pointer;
      }
    }
    .examsearchcondition{
      width: 100%;
      height: 36px;
      font-weight: 900;
      font-size: 14px;
      padding:0 10px ;
      color: #fff !important;
      background:rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      >span{
        width:32%;
        display: flex;
        align-items: center;
        &:nth-child(1){
          width:36% !important;
        }
      }
      button{
        height: 28px;
        font-size: 12px;
        border-radius: 5px;
        color: #fff;
        background: none;
        margin-left: 5px;
        &:nth-of-type(1){
          background-color: #00a0e9;
        }
        &:hover{
          background-color: #00a0e9;
        }
      }
      .ant-select{
        width: 120px;
        height: 28px;
        .ant-select-selection{
          height: 28px;
          line-height: 28px;
          color: #fff !important;
          background: none;
          .ant-select-selection__rendered{
            height: 28px;
            line-height: 28px;

          }
          .anticon{
            color: #fff;
          }
        }
      }
    }  
    .calcucatedata{
    width: 100%;
    height: 500px;
    background:rgba(0, 0, 0, 0.6);
    >span{
      display: block;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 40px;
      margin-top: 20px;
      button{
        width: 80px;
        height: 32px;
        background-color: #00a0e9;
        border-radius: 5px;
        color: #fff;
      }
    }
  }
}
//日历
.ant-calendar-date{
  &:hover{
    color: #000 !important;
  }
}
.ant-calendar-disabled-cell .ant-calendar-date{
  color: #000 !important;
}

.cepointechartstulinesmallindicators{
  display: none;
  width: 800px;
  position: absolute;
  margin:auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 0 20px 0px !important;
  height: 420px;
  z-index: 5000;
  h2{
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;
    padding: 0 5px 0 20px;
    background: rgba(0, 0, 0, 0.8);
    span{
      height: 40px;
      font-size: 16px;
      text-align: center;
      display: block;
      width: 40px;
      line-height: 40px;
      cursor: pointer;
    }
  }
  #echartstulinecedian {
    background: rgba(0, 0, 0, 0.6);
    height: 380px !important;
  }
  .linebtn{
    top: 58px;
    right: 20px;
    position: absolute;
    z-index: 90000;
    button{
      font-size: 12px;
      color: #fff;
      margin-left: 14px;
      background: none;
      cursor: pointer;
    }
  }
}


.perioddeatilsele{
  margin-bottom: 10px !important;
  margin-left: 30%;
  .perioddeatilseleleft{
    margin-bottom: 20px;
  }
  .perioddeatilseleright{
    .ant-calendar-picker{
      width: 100% !important;
      >div{
        width: 100% !important;
      }
    }
    table{
      width: 90%;
      tr{
        height:35px;
        td{
          &:first-child{
             width: 30%;
          }
          &:last-child{
            width:70%;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
.personconfig{
  padding-top:20px;
}

//必填项符号位置
.ant-form-item-required::before{
  // margin-right: 10px;
  line-height: 1.5;
  // float: right;
}