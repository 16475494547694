.baobiao{
  display: flex;
  background: linear-gradient(to bottom, rgb(39,84,134) 0%,#838A8F 100%) !important;
  .calender{
    width: 312px;
    height: 100%;
    padding:6px;
    border-right: 1px solid #fff; 
    color: #fff !important;
    #Baobiaoframe , #Baobiaoframe1{
      width: 300px;
      height: 260px;
      padding:0 10px 20px 10px !important;
    }
    
    .ant-fullcalendar-cell{
      position: relative;
      .ant-fullcalendar-content{
        bottom: 5px !important;
        left: 5px !important;
      }
    }
    .exportbaobiao{
      height: 36px;
      background: none !important;
      display: flex;
      justify-content: flex-end;
      button{
        width: 100px;
        height: 36px;
        background: #90aaca;
        color: #fff;
        font-size: 14px;
        border: none;
        &:hover{
          background: #43aed8;
        }
      }
    }
    .calenderrili{
      background: rgba(0, 0, 0, 0.3);
      margin-top: 10px;
      width: 300px;
      border: '1px solid #d9d9d9';
      border-radius: 4px;
      .ant-select-selection{
        background: none !important;
        color: #fff;
      }
      .ant-select-arrow{
        color: #fff;
      }
      .ant-radio-button-wrapper{
        background: none !important;
        color: #fff;
      }
      .ant-radio-button-wrapper{
        background: none !important;
        color: #fff;
      }
      .ant-fullcalendar-column-header-inner{
        color: #fff;
      }
      .ant-fullcalendar-value{
        color: #fff;
        &:hover{
          color: #000;
        }
      }
    }
  }
  .baobiaocon{
    display: flex;
    flex: 1;
    padding: 0 5px;
    height: 100%;
    color: #fff;
    position: relative;
    h2{
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
    }
    table{
      tr{
        th{
          overflow: hidden !important;
          vertical-align: middle;
          text-overflow: ellipsis;
          word-break: break-all;
          white-space: nowrap;
        }
        td{
          overflow:hidden !important;//溢出隐藏
          white-space:nowrap !important;//文字不换行
          text-overflow:ellipsis !important;//显示省略号
          vertical-align: middle;
          padding: 0px;
          &:hover{
            overflow: auto;
            text-overflow: clip;
          }
        }
      }
    }
    .baobiaocon1{
      width: 28%;
      height: auto;
      padding-right: 2px;
      h2{
        border-right: 1px solid #fff; 
      }
      table{
        width: 100%;
        border: 1px solid #fff;
        tr{
          th{
            font-size: 14px;
            // line-height: 1.5;
            border: 1px solid #fff !important;
            text-align: center;
            &:nth-child(1){
              width: 50%;
            }
            &:nth-child(2){
              width: 25%;
            }
            &:nth-child(3){
              width: 25%;
            }
          }
        }
        tr{
          border: 1px solid #fff;
          &:nth-child(1){
            background: rgba(255,255,255,0.2) !important;
          }
          cursor: pointer;
          &:hover{
            background: #7694b6  !important;
          }
          td{
            font-size: 12px;
            text-align: center;
            // line-height: 1.5;
            &:nth-child(1){
              width: 50%;
            }
            &:nth-child(2){
              width: 25%;
            }
            &:nth-child(3){
              width: 25%;
            }
            &:nth-child(1){
              text-align: left;
              background: rgba(0,0,0,0.3);
              padding-left: 10px;
              border:1px solid #fff;
            }
          }
        }
      }
    }
    .baobiaocon2{
      width: 28%;
      height: auto;
      padding-right: 2px;
      h2{
        border-right: 1px solid #fff; 
      }
      .scrolltable{
        overflow: auto !important;
        tr{
          cursor: pointer;
          &:hover{
            background: #7694b6  !important;
          }
          border: 1px solid #fff;
          td{
            font-size: 12px;
            // line-height: 1.5;
            text-align: center;
            &:nth-child(1){
              width: 50%;
            }
            &:nth-child(2){
              width: 25%;
            }
            &:nth-child(3){
              width: 25%;
            }
            &:nth-child(1){
              text-align: left;
              background: rgba(0,0,0,0.3);
              padding-left: 10px;
              border:1px solid #fff;
            }
          }
        }
      }
      table{
        width: 100%;
        border: 1px solid #fff;
        tr{
          th{
            font-size: 14px;
            // line-height: 1.5;
            border: 1px solid #fff !important;
            text-align: center;
            background: rgba(255,255,255,0.2) !important;
            &:nth-child(1){
              width: 50%;
            }
            &:nth-child(2){
              width: 25%;
            }
            &:nth-child(3){
              width: 25%;
            }
          }
        }
      }
    }
    .baobiaocon3{
      width: 44%;
      padding-right: 4px;
      padding-left:2px;
      .scrolltable{
        overflow: auto;
        tr{
          cursor: pointer;
          &:hover{
            background: #7694b6  !important;
          }
          border: 1px solid #fff;
          td{
            font-size: 12px;
            // line-height: 1.5;
            text-align: center;
            &:nth-child(1){
              width: 28%;
            }
            &:nth-child(2){
              width: 18%;
            }
            &:nth-child(3){
              width: 18%;
            }
            &:nth-child(4){
              width: 18%;
            }
            &:nth-child(5){
              width: 18%;
            }
            &:nth-child(1){
              text-align: left;
              background: rgba(0,0,0,0.3);
              padding-left: 10px;
              border:1px solid #fff;
            }
          }
        }

      }
      table{
        width: 100%;
        border: 1px solid #fff;
        overflow: scroll;
        tr{
          th{
            overflow: hidden !important;
            font-size: 14px;
            // line-height: 1.5;
            border: 1px solid #fff !important;
            text-align: center;
            background: rgba(255,255,255,0.2) !important;
            &:nth-child(1){
              width: 28%;
            }
            &:nth-child(2){
              width: 18%;
            }
            &:nth-child(3){
              width: 18%;
            }
            &:nth-child(4){
              width: 18%;
            }
            &:nth-child(5){
              width: 18%;
            }
          }
        }
      }
    }
  }
}
.inputbaobiao{
  .ant-drawer-close{
    color: #fff;
  }
  .ant-drawer-content-wrapper{
    width: 500px !important;
    .ant-drawer-content{
      background: none !important;
      .ant-drawer-wrapper-body{
        padding: 0px !important;
        background:rgba(32, 50, 72, 0.95) !important;
        box-shadow: -3px 0px 9px 0px 
        rgba(0, 0, 0, 0.5);
        .ant-drawer-body{
          width: 100% !important;
          padding: 10px !important;
          position: relative;
          height: 100% !important;
          .ant-form{
            width: 100% !important;
            input::input-placeholder{
              font-size: 12px !important;
            }
            overflow-y: auto;
            overflow-x: hidden;
            >.ant-row{
              padding:0 1%;
              height: 30px;
              display: flex !important;
              justify-content: center !important;
              .ant-col-12{
                width: 49%;
                padding: 0 !important;
                .ant-form-item{
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  padding-bottom: 0px !important;
                  margin: 0px !important;
                  .ant-form-item-label{
                    width: 130px !important;
                    height: 26px;
                    // padding: 0 !important;
                    line-height: 26px !important;
                    padding-left: 2px;
                    border: #fff solid 1px;
                  }
                  .ant-form-item-control-wrapper{
                    width: 130px;
                    height: 26px;
                    .ant-form-item-control{
                      height: 26px;
                      .ant-input{
                        height: 26px;
                        line-height: 26px !important;
                        width: 130px;
                        background: none !important;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 0;
                      }
                    }
                  }
                }
              }
            }
          }
          .h5title{
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            color: #fff;
          }
          .zonghe{
            width: 480px !important;
            height: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .qijiyunxing{
            height:210px;
            width: 480px !important;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
          .guolu{
            width: 480px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .ant-form-item-label label{
            color: #fff !important;
            font-size: 12px;
          }
          >div{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background: none !important;
            button{
              height: 30px !important;
              width: 60px !important;
              font-size: 14px !important;
              background: #2f5d91 !important;
            }
          }
        }
      }
    }
  }
}
input::-webkit-input-placeholder { /* WebKit browsers */
  color: #ccc !important;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #ccc !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #ccc !important;
}